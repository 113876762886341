// ------------------------- base

html {
  font-size: 62.5%;
  width: 100%;
  height: 100%;
  -webkit-text-size-adjust: none;
  -webkit-print-color-adjust: exact;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
  min-width: $widthBase;
  @include fs(14);
  font-family: $fontFamily;
  font-weight: 500;
  line-height: $lhBase;
  color: $clrBase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $clrOak;
  @media #{$querySp} {
    @include fs(12);
    line-height: $lhBaseSp;
    min-width: inherit;
  }
}

img {
  border-style: none;
  display: block;
  height: auto;
  vertical-align: bottom;
  @media #{$querySp} {
    max-width: 100%;
  }
}

a:link {
  color: $clrLink;
  text-decoration: none;
}

a:visited {
  color: $clrLink;
}

a:hover {
  color: $clrLinkHover;
}

a:active {
  color: $clrLinkHover;
}
