.series {
  padding-top: 60px;
  @media #{$querySp} {
    padding-top: 30px;
  }
  h2.c-ttl_list {
    display: block;
    margin-bottom: 20px;
    width: 100%;
    @include fs(36);
    text-align: left;
    font-weight: bold;
    line-height: 1;
    font-family: $fontEn;
    @media #{$querySp} {
      @include fs(18);
      margin-bottom: 10px;
    }
  }
  h3.c-ttl_list {
    display: block;
    @include fs(24);
    margin-bottom: 20px;
    width: 100%;
    text-align: left;
    font-weight: bold;
    font-family: $fontEn;
    line-height: 1;
    @media #{$querySp} {
      @include fs(12);
      margin-bottom: 10px;
    }
  }
  .c-items_list li {
    &:nth-child(4n) {
      margin-right: 2%;
      @media #{$querySp} {
        margin-right: 2.5%;
      }
    }
    &.fourth {
      margin-right: 0;
      @media #{$querySp} {
        margin-right: 2.5%;
      }
    }
    @media #{$querySp} {
      &:nth-child(2n) {
        margin-right: 2.5%;
      }
      &.second {
        margin-right: 0;
      }
    }
  }
  .c-items_img {
    display: flex;
    justify-content: center;
    align-items: center;
    .c-items_img_item {
      position: relative;
    }
  }
}
.c-ttl {
  &_base {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    text-align: center;
    @media #{$querySp} {
      margin-bottom: 20px;
    }

    span {
      display: block;
    }

    &_en {
      margin-right: 30px;
      font-family: $fontEn;
      @include fs(32);
      @media #{$querySp} {
        margin-right: 10px;
        @include fs(16);
      }

      strong {
        font-weight: 700;
      }
    }

    &_ja {
      @include fs(24);
      @media #{$querySp} {
        margin-top: 3px;
        @include fs(12);
      }
    }
  }

  &_list {
    margin-bottom: 40px;
    text-align: center;
    @media #{$queryPc} {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media #{$querySp} {
      margin-bottom: 20px;
    }

    span {
      display: block;
      font-family: $fontEn;
    }

    &_sub {
      @include fs(14);
      @media #{$queryPc} {
        margin-top: 3px;
        margin-right: 30px;
      }
      @media #{$querySp} {
        @include fs(10);
      }
    }

    &_main {
      font-weight: 700;
      @include fs(24);
      @media #{$querySp} {
        @include fs(16);
      }

      strong {
        font-weight: 700;
      }
    }
  }

  &_en {
    margin-bottom: 40px;
    font-family: $fontEn;
    font-weight: 700;
    @include fs(24);
    text-align: center;
    @media #{$querySp} {
      margin-bottom: 20px;
      @include fs(12);
    }
  }

  &_center {
    margin-bottom: 35px;
    font-family: $fontEn;
    font-weight: 700;
    @include fs(36);
    text-align: center;
    @media #{$querySp} {
      margin-bottom: 15px;
      @include fs(18);
    }
  }
}
.section-text {
  margin: 0 auto 100px auto;
  width: 800px;
  @include fs(14);
  line-height: (28 /14);
  @media #{$querySp} {
    width: 335px;
    margin-bottom: 30px;
  }
}
