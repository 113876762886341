.c-items {
  padding-bottom: 20px;
  &:last-child {
    padding-bottom: 0;
  }

  &_list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    li {
      position: relative;
      width: 23.5%;
      margin-bottom: 60px;
      margin-right: 2%;
      @media #{$queryPc} {
        &:nth-child(4n) {
          margin-right: 0;
        }
      }
      @media #{$querySp} {
        width: 48.5%;
        margin-bottom: 30px;
        margin-right: 2.5%;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }

      &.is-items {
        .c-items_img {
          opacity: 0.6;
        }
      }

      a {
        display: block;
        display: flex;
        flex-direction: column;
        height: 100%;

        &:hover {
          .c-items_img,
          .c-items_detail_cont {
            opacity: 0.7;
          }

          .c-btn_arw {
            span {
              background-color: $clrOrg;
            }
          }
        }
      }

      .c-btn_arw {
        span {
          &:hover {
            background-color: $clrBase;
          }
        }
      }
    }
  }

  &_ranking {
    position: relative;
    z-index: 0;

    &_no {
      position: absolute;
      z-index: 1;
      top: 10px;
      left: 10px;
      line-height: 1;
      font-family: $fontEn;
      @include fs(10);
      letter-spacing: 0.02em;

      span {
        margin-left: 5px;
        font-weight: 700;
        @include fs(24);
        letter-spacing: normal;
        @media #{$querySp} {
          @include fs(20);
        }
      }
    }
  }

  &_img {
    position: relative;
    z-index: 0;
    height: 240px;
    margin-bottom: 10px;
    background-color: $clrWht;
    border: 1px solid rgba($clrBase, 0.15);
    box-sizing: border-box;
    @media #{$querySp} {
      height: 160px;
      margin-bottom: 10px;
    }

    &_item,
    &_frame,
    &_lens {
      position: absolute;

      img {
        width: 100%;
      }
    }

    &_item {
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
    }

    &_frame,
    &_lens {
      z-index: 0;
    }

    &_frame {
      top: 165px;
      left: 30px;
      width: 80px;
      @media #{$querySp} {
        top: 111px;
        left: 10%;
        width: 47px;
      }
    }

    &_lens {
      top: 170px;
      right: 20px;
      width: 98px;
      @media #{$querySp} {
        top: 113px;
        right: 5%;
        width: 54px;
      }
    }
  }

  &_detail {
    font-family: $fontEn;
    // height: 100%;
    display: flex;
    flex-direction: column;

    .c-btn_arw {
      margin-top: 25px;
      // margin-top: auto;
      @media #{$querySp} {
        margin-bottom: 20px;
      }
    }

    &_ttl,
    &_price {
      @media #{$querySp} {
        @include fs(10);
      }
    }

    &_name {
      line-height: 1.75;
      font-weight: 700;
      @include fs(16);
      @media #{$querySp} {
        @include fs(12);
      }
    }

    &_price {
      @media #{$querySp} {
        // margin-bottom: 20px;
      }
    }
  }
}
