html {
  opacity: 0;
  &.wf-active {
    animation: fadeIn 1.5s ease-in 0s 1 forwards;
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}

body {
  &.no-scroll {
    overflow-y: hidden;
    @media #{$querySp} {
      .l-header_head {
        z-index: 10001;
        &_logo,
        &_txt {
          visibility: hidden;
          pointer-events: none;
        }
      }
    }
  }
}

.l-main {
  margin-top: 160px;
  @media #{$querySp} {
    margin-top: 140px;
  }
}

.l-cont {
  &_w {
    width: 90%;
    margin: 0 auto;
    @media #{$queryPc} {
      max-width: 1160px;
    }
    @media #{$queryPcTab} {
      width: 95%;
      max-width: 95%;
    }
  }
  &_l {
    width: 90%;
    margin: 0 auto;
    @media #{$queryPc} {
      max-width: 1120px;
    }
    @media #{$queryPcTab} {
      width: 95%;
      max-width: 95%;
    }
  }
  &_m {
    width: 90%;
    margin: 0 auto;
    @media #{$queryPc} {
      max-width: 1040px;
    }
    @media #{$queryPcTab} {
      width: 95%;
      max-width: 95%;
    }
  }
  &_s {
    width: 90%;
    margin: 0 auto;
    @media #{$queryPc} {
      max-width: 800px;
    }
    @media #{$queryPcTab} {
      width: 95%;
      max-width: 95%;
    }
  }
}

.l-block {
  &_oak {
    padding: 80px 0 100px;
    @media #{$querySp} {
      padding: 80px 0 50px;
    }
  }
}

#{$queryPcIE} body {
  overflow-x: hidden;
}
