.l-footer {
  padding: 40px 0 30px;
  background-color: $clrWht;
  font-family: $fontEn;
  @media #{$querySp} {
    padding: 105px 0 20px;
  }

  &_head {
    position: relative;
    z-index: 0;
    margin-bottom: 44px;
    @media #{$querySp} {
      margin-bottom: 25px;
    }

    .l-cont_w {
      display: flex;
      @media #{$queryPc} {
        position: relative;
        z-index: 0;
        align-items: flex-end;
      }
      @media #{$querySp} {
        justify-content: space-between;
      }
    }

    &_logo {
      width: 160px;
      @media #{$queryPc} {
        margin-right: 40px;
      }
      @media #{$querySp} {
        width: 100px;
      }
    }

    &_sns {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      li {
        width: 20px;
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }

        img {
          width: 100%;
        }
      }
    }

    &_pageTop {
      position: absolute;
      z-index: 0;
      top: 0;
      width: 36px;
      cursor: pointer;
      transition: .4s;
      @media #{$queryPc} {
        right: 0;
      }
      @media #{$querySp} {
        top: -85px;
        left: 50%;
        width: 32px;
        transform: translateX(-50%);
      }

      img {
        width: 100%;
      }

      &:hover {
        opacity: .8;
      }
    }
  }

  &_nav {
    margin-bottom: 25px;
    @media #{$querySp} {
      margin-bottom: 35px;
    }

    &_cont {
      display: flex;
      @media #{$querySp} {
        flex-wrap: wrap;

        &:last-child {
          width: 100%;
        }
      }
    }

    &_wrap,
    &_wide {
      @media #{$queryPc} {
        width: 25%;
      }
    }

    &_wrap {
      @media #{$querySp} {
        width: 50%;
      }
    }

    &_wide {
      @media #{$querySp} {
        width: 100%;
        margin-top: 35px;
      }
    }

    &_list {
      li {
        margin-bottom: 5px;
        @include fs(12);
        @media #{$querySp} {
          margin-bottom: 3px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &_other {
      @media #{$queryPc} {
        width: 25%;
      }
      @media #{$querySp} {
        width: 100%;
        margin-top: 25px;
        padding-top: 25px;
        border-top: 1px solid $clrBase;
      }

      &_en,
      &_ja {
        @media #{$querySp} {
          display: inline-block;
          vertical-align: top;
          width: 49%;
        }

        li {
          @media #{$queryPc} {
            margin-bottom: 15px;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &_en {
        @media #{$queryPc} {
          margin-bottom: 15px;
        }
        li {
          @media #{$querySp} {
            margin-bottom: 5px;
          }
        }
      }

      &_ja {
        li {
          @media #{$querySp} {
            margin-bottom: 10px;
          }
        }
      }
    }

    &_ttl {
      margin-bottom: 15px;
      @media #{$querySp} {
        margin-bottom: 20px;
      }

      &_en {
        @include fs(18);
        @media #{$querySp} {
          @include fs(14);
          margin-bottom: -5px;
        }

        strong {
          font-weight: 700;
        }
      }

      &_ja {
        font-family: $fontFamily;
      }
    }
  }

  &_copyright {
    @include fs(10);
    text-align: center;
  }
}
