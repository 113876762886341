.home {
  .video-modal {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $clrOak;
    opacity: 0;
    visibility: hidden;
    z-index: 1001;
    transition: opacity 0.4s, visibility 0s 0.4s;
    &.active {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.4s, visibility 0s;
    }
    .inner {
      position: relative;
      margin: 0 auto;
      width: 100%;
      max-height: 80vh;
      max-width: 1920px;
      &::before {
        content: "";
        display: block;
        padding-top: 56.25%;
      }
      @media #{$querySp} {
        max-width: initial;
      }
      .video-close-btn {
        position: absolute;
        top: -24px;
        right: 30px;
        height: 48px;
        width: 48px;
        cursor: pointer;
        z-index: 1;
        transition: 0.4s;
        &:hover {
          opacity: 0.7;
        }
      }
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }
  }
  @media #{$querySp} {
    .video-modal {
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: $clrOak;
      opacity: 0;
      visibility: hidden;
      z-index: 1001;
      &.active {
        opacity: 1;
        visibility: visible;
      }
      .inner {
        position: relative;
        margin: 0 auto;
        width: 100%;
        max-width: 1920px;
        @media #{$querySp} {
          max-width: initial;
        }
        iframe {
          width: 100%;
        }
      }
    }
    .l-gnav {
      &_sub {
        &_shop {
          height: 240px;
          padding-top: 24px;
          &_txt {
            display: block;
          }
          &_ico {
            display: none;
          }
        }
      }
    }
    .l-header {
      &.is-scroll {
        .l-gnav {
          &_sub {
            &_shop {
              height: 64px;
              &_txt {
                display: none;
              }
              &_ico {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

.p-index {
  &_kv {
    cursor: pointer;
    // padding-bottom: 80px;
    // @media #{$querySp} {
    //   padding-bottom: 40px;
    // }
    &_copy {
      line-height: 1;
      padding-top: 20px;
      padding-bottom: 24px;
      font-family: $fontEn;
      font-weight: 700;
      @include fs(36);
      text-align: center;
      @media #{$querySp} {
        padding-bottom: 10px;
        @include fs(24);
      }
    }
    &_slider {
      margin-bottom: 0 !important;
      li {
        position: relative;
        z-index: 0;
        height: 740px;
        border: 1px solid $clrBorder;
        box-sizing: border-box;
        @media #{$querySp} {
          //   height: 210px;
          height: 122.93333333333334vw;
        }
        &::before {
          position: absolute;
          z-index: 0;
          top: 0;
          left: 0;
          width: 100%;
          height: 740px;
          background-position: center top;
          background-size: cover;
          background-repeat: no-repeat;
          opacity: 0.8;
          content: "";
          display: none;
          @media #{$querySp} {
            height: 480px;
          }
        }
        &:nth-child(1) {
          &::before {
            background-image: url(../img/index/bg_kv_01_pc.png);
            @media #{$querySp} {
              background-image: url(../img/index/bg_kv_01_sp.png);
            }
          }
        }
        &:nth-child(2) {
          &::before {
            background-image: url(https://placehold.jp/30/c7c7c7/ffffff/2560x1400.png?text=slider+02);
            @media #{$querySp} {
              background-image: url(https://placehold.jp/30/c7c7c7/ffffff/750x960.png?text=slider+02);
            }
          }
        }
        &:nth-child(3) {
          &::before {
            background-image: url(https://placehold.jp/30/c7c7c7/ffffff/2560x1400.png?text=slider+03);
            @media #{$querySp} {
              background-image: url(https://placehold.jp/30/c7c7c7/ffffff/750x960.png?text=slider+03);
            }
          }
        }
        video {
          position: absolute;
          top: 0;
          left: 0;
          //   height: 100%;
          // width: 100%;
          min-width: 100%;
          min-height: 100%;
        }
        .play-btn {
          position: absolute;
          top: 50%;
          left: 50%;
          height: 120px;
          width: 120px;
          transform: translate3d(-50%, -50%, 0);
          z-index: 1;
          transition: 0.4s;
          cursor: pointer;
          @media #{$querySp} {
            height: 90px;
            width: 90px;
          }
          &:hover {
            opacity: 0.7;
          }
          img {
            width: 100%;
          }
        }
        a,
        .block {
          display: block;
          height: 100%;
          .l-cont_l {
            position: relative;
            z-index: 0;
            height: 100%;
          }
        }
      }
      .slick-dots {
        z-index: 0;
        bottom: 30px;
        @media #{$querySp} {
          bottom: 15px;
        }
        li {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: none;
          margin: 0 10px 0 0;
          @media #{$querySp} {
            width: 10px;
            height: 10px;
            margin: 0 5px 0 0;
          }
          &:last-child {
            margin: 0;
          }
          &::before {
            display: none;
          }
          button {
            display: block;
            width: 12px;
            height: 12px;
            padding: 0;
            border-radius: 50%;
            background-color: $clrWht;
            opacity: 0.6;
            @media #{$querySp} {
              width: 10px;
              height: 10px;
            }
          }
          &.slick-active {
            button {
              opacity: 1;
            }
          }
        }
      }
    }
    &_ttl {
      position: absolute;
      z-index: 0;
      bottom: 80px;
      left: 0;
      width: 100%;
      @media #{$querySp} {
        bottom: 40px;
      }
      &_en {
        color: $clrWht;
        font-family: $fontEn;
        font-weight: 700;
        @include fs(96);
        @media #{$querySp} {
          @include fs(40);
        }
        &.blk {
          color: #000;
        }
      }
      &_inner {
        @media #{$queryPc} {
          display: flex;
        }
        &_ja {
          @media #{$querySp} {
            display: inline;
            .u-only_sp {
              display: inline;
            }
          }
          strong {
            display: inline-block;
            padding: 0 32px 0 13px;
            background-color: $clrWht;
            font-weight: 700;
            @include fs(18);
            @media #{$queryPc} {
              height: 36px;
            }
            @media #{$querySp} {
              height: 24px;
              margin-top: 5px;
              padding: 0 5px;
              @include fs(12);
              &:first-child {
                margin-top: 0;
              }
            }
          }
          &.bg_blk {
            strong {
              background-color: #000;
              color: #fff;
            }
          }
        }
        .c-arw_blk {
          width: 36px;
          height: 36px;
          @media #{$querySp} {
            display: inline-block;
            width: 24px;
            height: 24px;
            margin: 0 0 -8px -3px;
          }
          img {
            width: 100%;
          }
        }
      }
    }
  }
  &_catch {
    padding: 60px 0;
    background-color: black;
    color: white;
    text-align: center;
    h2 {
      margin-bottom: 60px;
      font-family: $fontEn;
      @include fs(36);
      font-weight: bold;
    }
    p {
      @include fs(16);
      line-height: (32 / 16);
    }
    @media #{$querySp} {
      padding: 30px 0;
      h2 {
        margin-bottom: 30px;
        @include fs(24);
      }
      p {
        margin: 0 auto;
        width: 335px;
        @include fs(12);
        line-height: (19 / 12);
        text-align: left;
      }
    }
  }
  &_feature {
    padding-top: 80px;
    padding-bottom: 114px;
    @media #{$querySp} {
      padding-top: 40px;
      padding-bottom: 100px;
      .l-cont_m {
        width: 100%;
      }
    }
    .slick-slider {
      &::after {
        position: absolute;
        z-index: 0;
        bottom: -59px;
        left: 50%;
        width: 160px;
        height: 2px;
        background-color: #000;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        content: "";
        @media #{$querySp} {
          bottom: -31px;
          width: 80px;
          height: 1px;
        }
      }
    }
    .slick-list {
      padding: 0 !important;
      @media #{$querySp} {
        padding: 0 50px !important;
      }
    }
    .slick-prev,
    .slick-next {
      top: auto;
      bottom: -75px;
      width: 20px;
      height: 18px;
      background-position: left top;
      background-size: contain;
      background-repeat: no-repeat;
      @media #{$querySp} {
        bottom: -40px;
        width: 11px;
        height: 10px;
      }
      &::before {
        display: none;
      }
    }
    .slick-prev {
      right: auto;
      left: 36%;
      background-image: url(../img/ico_arw_prev_blk.svg);
      @media #{$querySp} {
        left: 31%;
      }
    }
    .slick-next {
      right: 36%;
      left: auto;
      background-image: url(../img/ico_arw_next_blk.svg);
      @media #{$querySp} {
        right: 31%;
      }
    }
    &_cont {
      .bx-wrapper {
        z-index: 0;
        border: none;
        background: transparent;
        box-shadow: none;
        margin-bottom: 0;
        padding-bottom: 70px;
        @media #{$querySp} {
          padding-bottom: 40px;
          .bx-viewport {
            margin: 0 auto;
            // padding-left: 70px;
          }
        }
        .bx-controls {
          position: relative;
          z-index: 0;
          height: 18px;
          @media #{$querySp} {
            height: 10px;
          }
          &::after {
            position: absolute;
            z-index: 0;
            bottom: -59px;
            left: 50%;
            width: 160px;
            height: 2px;
            background-color: $clrBase;
            transform: translateX(-50%);
            content: "";
            @media #{$querySp} {
              bottom: -27px;
              width: 80px;
              height: 1px;
            }
          }
          &-direction {
            height: 18px;
            @media #{$querySp} {
              height: 10px;
            }
            a {
              display: block;
              z-index: 0;
              top: 0;
              width: 20px;
              height: 18px;
              margin-top: 67px;
              background-position: left top;
              background-size: contain;
              background-repeat: no-repeat;
              @media #{$querySp} {
                width: 11px;
                height: 10px;
                margin-top: 32px;
              }
              &:hover {
                opacity: 0.8;
              }
              &.bx-prev {
                left: 380px;
                background-image: url(../img/ico_arw_prev_blk.svg);
                @media #{$queryPcTab} {
                  left: 36%;
                }
                @media #{$querySp} {
                  left: 31%;
                }
              }
              &.bx-next {
                right: 380px;
                background-image: url(../img/ico_arw_next_blk.svg);
                @media #{$queryPcTab} {
                  right: 36%;
                }
                @media #{$querySp} {
                  right: 31%;
                }
              }
            }
          }
        }
      }
    }
    &_slider {
      li {
        // width: 307px;
        // width: 307px !important;
        margin: 0 20px;
        @media #{$querySp} {
          // margin-right: auto;
          margin: 0 20px;
          // width: 240px !important;
        }
        a {
          display: block;
          padding: 20px 0;
          border-top: 2px solid $clrBase;
          border-bottom: 2px solid $clrBase;
          transition: 0.4s;
          @media #{$querySp} {
            padding: 15px 0;
            border-top: 1px solid $clrBase;
            border-bottom: 1px solid $clrBase;
          }
          &:hover {
            img,
            .p-index_feature_inner {
              opacity: 0.7;
            }
          }
        }
      }
    }
    &_img {
      position: relative;
      z-index: 0;
      margin-bottom: 15px;
      img {
        width: 100%;
      }
      .c-arw_blk {
        position: absolute;
        z-index: 0;
        right: 0;
        bottom: 0;
      }
    }
    &_category {
      margin-bottom: 10px;
      font-family: $fontEn;
      @include fs(12);
      letter-spacing: 0.02em;
      @media #{$querySp} {
        margin-bottom: 6px;
        @include fs(10);
      }
    }
    &_ttl {
      margin-bottom: 10px;
      font-family: $fontEn;
      font-weight: 700;
      @include fs(16);
      @media #{$querySp} {
        margin-bottom: 15px;
        @include fs(12);
      }
    }
    &_txt {
      height: 56px;
      @media #{$querySp} {
        height: 48px;
      }
    }
  }
  &_scene {
    padding-bottom: 120px;
    @media #{$querySp} {
      padding-bottom: 80px;
    }
    &_list {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 25%;
        height: 420px;
        @media #{$querySp} {
          width: 50%;
          height: 210px;
        }
        &.list {
          &_01 {
            a {
              &::before {
                background-image: url(../img/index/blt_scene_01_pc.jpg);
                @media #{$querySp} {
                  background-image: url(../img/index/blt_scene_01_sp.jpg);
                }
              }
            }
          }
          &_02 {
            a {
              &::before {
                background-image: url(../img/index/blt_scene_02_pc.jpg);
                @media #{$querySp} {
                  background-image: url(../img/index/blt_scene_02_sp.jpg);
                }
              }
            }
          }
          &_03 {
            a {
              &::before {
                background-image: url(../img/index/blt_scene_03_pc.jpg);
                @media #{$querySp} {
                  background-image: url(../img/index/blt_scene_03_sp.jpg);
                }
              }
            }
          }
          &_04 {
            a {
              &::before {
                background-image: url(../img/index/blt_scene_04_pc.jpg);
                @media #{$querySp} {
                  background-image: url(../img/index/blt_scene_04_sp.jpg);
                }
              }
            }
          }
          &_05 {
            a {
              &::before {
                background-image: url(../img/index/blt_scene_05_pc.jpg);
                @media #{$querySp} {
                  background-image: url(../img/index/blt_scene_05_sp.jpg);
                }
              }
            }
          }
          &_06 {
            a {
              &::before {
                background-image: url(../img/index/blt_scene_06_pc.jpg);
                @media #{$querySp} {
                  background-image: url(../img/index/blt_scene_06_sp.jpg);
                }
              }
            }
          }
          &_07 {
            a {
              &::before {
                background-image: url(../img/index/blt_scene_07_pc.jpg);
                @media #{$querySp} {
                  background-image: url(../img/index/blt_scene_07_sp.jpg);
                }
              }
            }
          }
          &_08 {
            a {
              &::before {
                background-image: url(../img/index/blt_scene_08_pc.jpg);
                @media #{$querySp} {
                  background-image: url(../img/index/blt_scene_08_sp.jpg);
                }
              }
            }
          }
        }
        a {
          display: block;
          position: relative;
          z-index: 0;
          height: 100%;
          transition: 0.4s;
          &::before {
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            content: "";
          }
          &:hover {
            &::before,
            .p-index_scene_list_cont {
              opacity: 0.7;
            }
          }
          .c-arw_wh {
            position: absolute;
            z-index: 0;
            right: 0;
            bottom: 0;
          }
        }
      }
      &_cont {
        position: absolute;
        z-index: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        color: $clrWht;
        padding: 0 30px 25px 30px;
        font-family: $fontEn;
        font-weight: 700;
        @media #{$querySp} {
          padding: 0 5% 20px 5%;
        }
      }
      &_ttl {
        @include fs(24);
      }
      &_txt {
        line-height: 1.4;
        @include fs(10);
      }
    }
  }
  &_series {
    padding-bottom: 120px;
    @media #{$querySp} {
      padding-bottom: 80px;
      .l-cont_l {
        width: 100%;
      }
    }
    &_list {
      @media #{$queryPc} {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      &.oak {
        background-color: $clrOak;
      }
      &.moss {
        background-color: $clrMGreen;
        .p-index_series_list_caption {
          color: $clrWht;
        }
      }
      li {
        padding-bottom: 40px;
        @media #{$queryPc} {
          width: 49.9%;
        }
        @media #{$querySp} {
          padding-bottom: 25px;
        }
        &.oak {
          &_01 {
            .p-index_series_list_bg {
              &::before {
                background-image: url(../img/series/bg_series_01.png);
              }
            }
          }
          &_02 {
            .p-index_series_list_bg {
              &::before {
                background-image: url(../img/series/bg_series_02.png);
              }
            }
          }
          &_03 {
            .p-index_series_list_bg {
              &::before {
                background-image: url(../img/series/bg_series_03.png);
              }
            }
          }
          &_04 {
            .p-index_series_list_bg {
              &::before {
                background-image: url(../img/series/bg_series_04.png);
              }
            }
          }
          &_05 {
            .p-index_series_list_bg {
              &::before {
                background-image: url(../img/series/bg_series_05.png);
              }
            }
          }
          &_06 {
            .p-index_series_list_bg {
              &::before {
                background-image: url(../img/series/bg_series_06.png);
              }
            }
          }
        }
        &.moss {
          &_01 {
            .p-index_series_list {
              &_bg {
                &::before {
                  background-image: url(../img/series/logo_proshop.png);
                  background-position: 90% 32px;
                  background-size: 140px 176px;
                  @media #{$querySp} {
                    background-position: 90% 15px;
                    background-size: 70px auto;
                  }
                }
              }
              &_cont {
                color: $clrWht;
                @media #{$queryPc} {
                  padding: 0 0 35px 50px;
                }
              }
            }
          }
          &_02 {
            .p-index_series_list_bg {
              &::before {
                background-image: url(../img/series/bg_series_07.png);
              }
            }
          }
          &_03 {
            .p-index_series_list_bg {
              &::before {
                background-image: url(../img/series/bg_series_08.png);
              }
            }
          }
          &_04 {
            .p-index_series_list_bg {
              &::before {
                background-image: url(../img/series/bg_series_09.png);
              }
            }
          }
        }
      }
      a {
        &:hover {
          .p-index_series_list_bg::before,
          .p-index_series_list_cont {
            opacity: 0.7;
          }
          .c-arw_blk {
            img {
              display: block;
            }
          }
        }
      }
      &_bg {
        position: relative;
        z-index: 0;
        height: 240px;
        margin-bottom: 20px;
        @media #{$querySp} {
          height: 120px;
          margin-bottom: 10px;
        }
        &::before {
          position: absolute;
          z-index: 0;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-position: center top;
          background-size: cover;
          background-repeat: no-repeat;
          content: "";
        }
        .c-arw_blk {
          position: absolute;
          z-index: 0;
          right: 0;
          bottom: 0;
        }
      }
      &_cont {
        position: absolute;
        z-index: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 0 0 35px 30px;
        font-family: $fontEn;
        @media #{$querySp} {
          padding: 0 0 15px 5%;
        }
      }
      &_ttl {
        margin-bottom: 5px;
        font-weight: 700;
        @include fs(18);
        @media #{$querySp} {
          margin-bottom: 3px;
          @include fs(16);
        }
      }
      &_txt {
        line-height: 1.4;
        @include fs(10);
        @media #{$querySp} {
          line-height: 1.2;
        }
      }
      &_caption {
        padding-left: 30px;
        @media #{$querySp} {
          padding-left: 5%;
        }
      }
      &_link {
        display: flex;
        align-items: center;
        position: absolute;
        z-index: 0;
        bottom: 0;
        right: 0;
        width: 210px;
        padding: 9px 0 9px 15px;
        background-color: $clrMGreen;
        @media #{$querySp} {
          width: 45%;
          padding: 6px 0 7px 11px;
          img {
            width: 12px;
          }
        }
        span {
          display: block;
          line-height: 1;
          margin-right: 20px;
          color: $clrWht;
          font-family: $fontEn;
          font-weight: 700;
          @media #{$querySp} {
            @include fs(10);
          }
        }
      }
    }
  }
  &_items {
    padding-bottom: 65px;
    .all-products,
    .how-to-choose {
      margin: 0 auto;
      width: 640px;
      transition: 0.4s;
      &:hover {
        opacity: 0.7;
      }
    }
    .all-products {
      margin-bottom: 60px;
    }
    .how-to-choose {
      margin: rem(60) auto 0 auto;
      border: 2px solid #000;
      a {
        background-color: transparent;
        color: black;
        background-image: url(../img/ico_arw_next_blk.svg);
      }
    }
    @media #{$querySp} {
      padding-bottom: 50px;
      .all-products,
      .how-to-choose {
        width: 100%;
      }
      .all-products {
        margin-bottom: 30px;
      }
    }
  }
  &_styling {
    padding-bottom: 106px;
    @media #{$querySp} {
      padding-bottom: 80px;
    }
  }
  &_lifestyle {
    padding-bottom: 100px;
    @media #{$querySp} {
      padding-bottom: 80px;
    }
    &_list {
      li {
        margin-bottom: 100px;
        @media #{$queryPc} {
          display: flex;
          position: relative;
          z-index: 0;
          height: 480px;
          overflow: hidden;
          &:nth-child(odd) {
            .p-index_lifestyle_list {
              &_img {
                left: 0;
              }
              &_cont {
                margin-left: auto;
                padding-left: 40px;
                .c-btn_arw {
                  left: 40px;
                }
              }
            }
          }
          &:nth-child(even) {
            flex-direction: row-reverse;
            .p-index_lifestyle_list {
              &_img {
                right: 0;
              }
              &_cont {
                padding-right: 40px;
                .c-btn_arw {
                  left: 0;
                }
              }
            }
          }
        }
        @media #{$querySp} {
          margin-bottom: 40px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      &_img {
        display: block;
        width: 50%;
        @media #{$queryPc} {
          position: absolute;
          z-index: 0;
          top: 0;
        }
        @media #{$querySp} {
          width: 100%;
          margin-bottom: 15px;
        }
        img {
          width: 100%;
        }
      }
      &_cont {
        @media #{$queryPc} {
          position: relative;
          z-index: 0;
          width: 50%;
          height: 100%;
        }
        .c-btn_arw {
          width: 320px;
          @media #{$queryPc} {
            position: absolute;
            z-index: 0;
            bottom: 0;
          }
          @media #{$querySp} {
            width: 75%;
            margin: 0 auto;
          }
        }
      }
      &_head {
        display: block;
        margin-bottom: 10px;
        font-family: $fontEn;
        font-weight: 700;
        @media #{$querySp} {
          margin-bottom: 5px;
        }
      }
      &_name {
        margin-bottom: 30px;
        font-weight: 700;
        @include fs(24);
        @media #{$querySp} {
          margin-bottom: 20px;
          @include fs(12);
        }
      }
      &_ttl {
        margin-bottom: 20px;
        font-weight: 700;
        @include fs(18);
        @media #{$querySp} {
          line-height: 1.58;
          margin-bottom: 15px;
          @include fs(12);
        }
      }
      &_txt {
        @media #{$querySp} {
          line-height: 1.58;
          margin-bottom: 30px;
        }
      }
    }
  }
}
