// ----- colors

$clrBase: #000;
$clrLink: #000;
$clrLinkHover: #000;

$clrOak: #909986;
$clrLOak: #BBC7AE;
$clrWht: #fff;
$clrMGreen: #084C3D;
$clrOrg: #E2680E;

$clrBorder: #707070;
$clrBorder02: #3f3f3f;
