.c-kv {
  &_ttl {
    &_head {
      font-family: $fontEn;
      @include fs(10);
    }

    &_center {
      display: block;
      line-height: 1;
      font-family: $fontEn;
      font-weight: 700;
      @include fs(36);
      @media #{$querySp} {
        margin-bottom: 8px;
        @include fs(18);
      }
    }

    &_btm {
      display: block;
      line-height: 1;
      font-family: $fontEn;
      font-weight: 700;
      @include fs(96);
      @media #{$querySp} {
        @include fs(48);
      }
    }

    &_icon {
      position: relative;
      z-index: 0;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: #EC6713;
      &--dotsuki {
        background-color: #bbc7ae;
      }
      @media #{$querySp} {
        width: 48px;
        height: 48px;
      }

      &_txt {
        display: block;
        position: absolute;
        z-index: 0;
        top: 50%;
        left: 50%;
        width: 100%;
        line-height: 1.6;
        font-weight: 700;
        @include fs(12);
        text-align: center;
        transform: translate(-50%,-50%);
        @media #{$querySp} {
          @include fs(10);
        }
      }
    }
  }
}
