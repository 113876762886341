.p-listDetail {
  &_kv {
    background-color: $clrWht;
    @media #{$querySp} {
      position: relative;
      z-index: 0;
    }

    .l-cont_l {
      @media #{$queryPc} {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
      }
      @media #{$querySp} {
        width: 100%;
      }
    }

    &_img {
      @media #{$queryPc} {
        width: 68%;
        max-width: 760px;
        margin-top: -44px;
      }
      @media #{$querySp} {
        padding-top: 150px;
      }

      img {
        width: 100%;
      }

      &_slider {
        margin-bottom: 6px;
        @media #{$querySp} {
          margin-bottom: 30px;
        }

        li {
          position: relative;
          z-index: 0;
          width: 760px;
          height: 580px;
          overflow: hidden;

          @media #{$querySp} {
            width: 100%;
            height: 290px;
          }

          img {
            position: absolute;
            z-index: 0;
            top: 50%;
            left: 50%;
            width: 100%;
            transform: translate(-50%, -50%);
          }
        }
      }

      &_thumb {
        z-index: 0;
        @media #{$queryPc} {
          padding: 0 70px;
        }
        @media #{$querySp} {
          width: 100%;
          padding-bottom: 30px;
        }

        &::after {
          @media #{$querySp} {
            position: absolute;
            z-index: 0;
            bottom: 10px;
            left: 50%;
            width: 80px;
            height: 1px;
            background-color: $clrBase;
            transform: translateX(-50%);
            content: "";
          }
        }

        li {
          position: relative;
          z-index: 0;
          width: 120px;
          height: 69px;
          margin: 0 0 0 5px;
          overflow: hidden;
          cursor: pointer;
          @media #{$querySp} {
            width: 90px;
            margin: 0 0 0 5px;
          }

          img {
            position: absolute;
            z-index: 0;
            top: 50%;
            left: 50%;
            width: 100%;
            transform: translate(-50%, -50%);
          }

          &.slick-current {
            border: 2px solid $clrBorder02;
            box-sizing: border-box;
          }
        }

        .slick-arrow {
          position: absolute;
          z-index: 1;
          width: 20px;
          height: 18px;
          background-position: left top;
          background-size: contain;
          background-repeat: no-repeat;
          transform: translateY(-50%);
          cursor: pointer;
          @media #{$queryPc} {
            top: 55%;
          }
          @media #{$querySp} {
            bottom: 0;
            width: 11px;
            height: 10px;
          }

          &:hover {
            opacity: .8;
          }

          &.img {
            &_prev {
              left: 18px;
              background-image: url(../img/ico_arw_prev_blk.svg);
              @media #{$querySp} {
                left: 30%;
              }
            }
            &_next {
              right: 18px;
              background-image: url(../img/ico_arw_next_blk.svg);
              @media #{$querySp} {
                right: 30%;
              }
            }
          }
        }
      }
    }

    &_cont {
      padding-top: 40px;
      @media #{$queryPc} {
        width: 29%;
        max-width: 320px;
        margin-right: 40px;
      }
      @media #{$querySp} {
        width: 90%;
        margin: 0 auto;
        padding-top: 35px;
      }
    }

    &_ttl {
      @media #{$queryPc} {
        margin-bottom: 45px;
      }
      @media #{$querySp} {
        position: absolute;
        z-index: 0;
        top: 40px;
        left: 5%;
      }

      .c-kv_ttl_head {
        margin-bottom: 10px;
        @media #{$querySp} {
          margin-bottom: 3px;
        }
      }

      &_main {
        display: flex;
        align-items: flex-start;

        .c-kv_ttl_icon {
          margin: 18px 0 0 15px;
          @media #{$querySp} {
            margin: 5px 0 0 8px;
          }
        }
      }
    }

    &_product,
    &_price {
      font-family: $fontEn;
      line-height: 1.8;
      @media #{$querySp} {
        width: 90%;
      }

      dt {
        width: 30%;
        padding-top: 8px;
        @include fs(10);
        @media #{$querySp} {
          padding-top: 6px;
        }
      }

      dd {
        width: 70%;
        font-weight: 700;
        @include fs(18);
        @media #{$querySp} {
          @include fs(16);
        }
      }
    }

    &_product {
      padding-bottom: 20px;
      border-bottom: 2px solid $clrBase;
      @media #{$queryPc} {
        margin-bottom: 25px;
      }
      @media #{$querySp} {
        margin: 0 auto 10px;
        padding-bottom: 15px;
      }
      &:last-child {
        margin-bottom: 30px;
        padding-bottom: 0;
        border-bottom: 0;
      }

      &_cont {
        display: flex;
      }
    }

    &_price {
      display: flex;
      margin-bottom: 25px;
      @media #{$querySp} {
        margin: 0 auto 35px;
      }

      &_number {
        line-height: 1.6;
        font-weight: 700;
        font-family: $fontEn;
        @include fs(18);
        @media #{$querySp} {
          @include fs(16);
        }
      }

      &_tax {
        font-family: $fontEn;
        @media #{$queryPc} {
          display: none;
        }
        @media #{$querySp} {
          font-weight: 500;
          @include fs(10);
          font-feature-settings: "palt";
        }
      }

      &_txt {
        font-family: $fontFamily;
        font-weight: 500;
        @include fs(14);
        @media #{$querySp} {
          @include fs(11);
          font-feature-settings: "palt";
        }
      }
    }
  }

  &_top {
    padding: 80px 0;
    background-color: $clrWht;
    @media #{$querySp} {
      padding: 40px 0;
    }
  }

  &_consult {
    padding: 15px 0 10px;
    border-top: 2px solid $clrBase;
    border-bottom: 2px solid $clrBase;
    text-align: center;
    @media #{$querySp} {
      padding: 20px 0;
      border-top: 1px solid $clrBase;
      border-bottom: 1px solid $clrBase;
    }
  }
}
