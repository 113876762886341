.p-shopDetail {
  &_kv {
    background-color: $clrWht;
    @media #{$querySp} {
      .c-breadcrumb {
        position: relative;
        z-index: 0;
      }
    }

    .c-shop {
      padding: 60px 0 100px;
      @media #{$querySp} {
        margin-top: -30px;
        padding: 0 0 40px;
      }
    }
  }

  &_top {
    padding: 80px 0;
    @media #{$querySp} {
      padding: 40px 0 70px;
    }
    .c-txt_border {
      margin: 0;
      border-top: none;
      border-bottom: none;
      @media #{$querySp} {
        margin: 0;
        padding: 0;
        border-top: none;
        border-bottom: none;
      }
      .pcNone {
        display: none;
        @media #{$querySp} {
          display: block;
        }
      }
      .spNone {
        display: block;
        @media #{$querySp} {
          display: none;
        }
      }
      .uq-SwEmergencyPanel__main,
      .caution {
        margin: 0 auto 75px;
        padding: 40px 20px;
        text-align: center;
        background-color: $clrLOak;
        .uq-SwEmergencyPanel__label,
        .caution__title {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 10px;
          line-height: 1.5;
        }
      }
      .c-txt_border_txt {
        margin: 0 auto 75px;
        padding: 15px 0;
        border-top: 2px solid $clrBase;
        border-bottom: 2px solid $clrBase;
        @media #{$querySp} {
          margin: 0 auto 60px;
          line-height: 1.58;
          padding: 15px 0;
          border-top: 1px solid $clrBase;
          border-bottom: 1px solid $clrBase;
        }
      }
      .uq-SwEmergencyPanel__sub {
        margin: 0 auto 75px;
        padding: 15px 0;
        text-align: center;
        border-top: 2px solid $clrBase;
        border-bottom: 2px solid $clrBase;
        @media #{$querySp} {
          margin: 0 auto 60px;
          line-height: 1.58;
          padding: 15px 0;
          border-top: 1px solid $clrBase;
          border-bottom: 1px solid $clrBase;
        }
        .uq-SwEmergencyPanel__sub__label {
          font-size: 24px;
          font-weight: bold;
        }
        .uq-SwEmergencyPanel__sub__body {
          margin-top: 20px;
          font-size: 20px;
          font-weight: bold;
          .largeText {
            font-size: 14px;
            font-weight: normal;
          }
        }
      }
    }
  }

  &_info {
    margin-bottom: 80px;
    @media #{$querySp} {
      margin-bottom: 40px;
    }

    li {
      margin-bottom: 40px;
      padding: 35px;
      background-color: $clrLOak;
      @media #{$querySp} {
        margin-bottom: 20px;
        padding: 15px 5%;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    &_ttl {
      margin-bottom: 15px;
      font-weight: 700;
      @include fs(24);
      text-align: center;
      @media #{$querySp} {
        margin-bottom: 8px;
        @include fs(12);
      }
    }

    &_center {
      text-align: center;
      @media #{$querySp} {
        line-height: 1.58;
      }
    }

    &_left {
      @media #{$queryPc} {
        text-align: center;
      }
      @media #{$querySp} {
        line-height: 1.58;
      }
    }
  }

  &_consult {
    &_ttl {
      margin-bottom: 15px;
      line-height: 1.58;
      font-weight: 700;
      @include fs(24);
      text-align: center;
      @media #{$querySp} {
        @include fs(12);
      }
    }

    &_txt {
      margin-bottom: 35px;
      @media #{$queryPc} {
        text-align: center;
      }
      @media #{$querySp} {
        margin-bottom: 20px;
      }
    }

    &_contact {
      text-align: center;

      &_txt {
        margin-bottom: 5px;
        @media #{$querySp} {
          margin-bottom: 15px;
        }
      }

      &_add {
        font-weight: 700;
        @include fs(18);
        @media #{$querySp} {
          @include fs(12);
        }
      }
    }
  }

  &_access {
    padding-bottom: 100px;
    @media #{$querySp} {
      padding-bottom: 50px;
    }

    &_add {
      margin-bottom: 35px;
      @include fs(16);
      text-align: center;
      @media #{$querySp} {
        margin-bottom: 25px;
        @include fs(12);
      }
    }

    &_cont {
      margin-bottom: 105px;
      @media #{$querySp} {
        line-height: 1.58;
        margin-bottom: 40px;
      }
    }

    &_info {
      margin-bottom: 30px;
      @media #{$querySp} {
        margin-bottom: 20px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    &_map {
      height: 480px;
      margin-bottom: 100px;
      @media #{$querySp} {
        height: 320px;
        margin-bottom: 50px;
      }

      iframe {
        border: 0;
      }
    }
  }
}
