@charset "UTF-8";

@import "foundation/variable/variable";
@import "foundation/variable/color";

@import "foundation/mixin/mixin";

@import "foundation/reset";
@import "foundation/base";

@import "layout/layout";
@import "layout/header";
@import "layout/footer";
@import "layout/nav";

@import "object/component/slick-theme";
@import "object/component/slick";
@import "object/component/jquery-bxslider-min";
@import "object/component/ttl";
@import "object/component/arw";
@import "object/component/items";
@import "object/component/btn";
@import "object/component/styling";
@import "object/component/breadcrumblist";
@import "object/component/txt";
@import "object/component/kv";
@import "object/component/shop_common";

@import "object/project/index";
@import "object/project/list";
@import "object/project/list_detail";
@import "object/project/shop";
@import "object/project/shop_detail";

@import "utility";

@media print {
  @import "layout/print";
}
