.c-breadcrumb {
  display: inline-block;
  background-color: $clrLOak;
  padding: 10px 25px 10px 0;
  @media #{$queryPc} {
    padding-left: calc(50% - (1280px / 3) - 135px);
  }
  @media #{$queryPcTab} {
    padding-left: calc(50% - (1060px / 3) - 160px);
  }
  @media #{$querySp} {
    padding: 5px 10px 5px 5%;
  }

  &_list {
    display: flex;
    flex-wrap: wrap;
    font-family: $fontEn;

    li {
      position: relative;
      z-index: 0;
      margin-right: 4px;
      @include fs(12);
      @media #{$querySp} {
        @include fs(10);
      }

      &:first-child {
        &::before {
          display: none;
          margin-right: 0;
        }
      }

      &:last-child {
        font-weight: 700;
      }

      &::before {
        display: inline-block;
        margin-right: 2px;
        @include fs(12);
        content: ">";
        @media #{$querySp} {
          @include fs(10);
        }
      }

      a {
        display: inline-block;
        color: $clrBase;
      }
    }
  }
}
