.c-shop {
  .l-cont_l {
    @media #{$queryPc} {
      display: flex;
      flex-direction: row-reverse;
    }
    @media #{$querySp} {
      width: 100%;
    }
  }

  &_img {
    display: block;
    @media #{$queryPc} {
      width: 58%;
      max-width: 640px;
    }
    @media #{$querySp} {
      margin-bottom: 25px;
    }

    img {
      width: 100%;
    }
  }

  &_cont {
    @media #{$queryPc} {
      position: relative;
      z-index: 0;
      width: 36%;
      max-width: 400px;
      margin-right: 80px;
    }
    @media #{$querySp} {
      width: 90%;
      margin: 0 auto;
    }

    .c-shop_hc {
      &_icon {
        margin-right: 20px;
        @media #{$queryPc} {
          width: 48px;
          height: 48px;
        }
        @media #{$querySp} {
          margin-right: 10px;
        }

        &_txt {
          @include fs(15);
          @media #{$querySp} {
            @include fs(11);
          }
        }
      }

      &_txt {
        @media #{$queryPc} {
          @include fs(12);
        }
      }
    }
  }

  &_ttl {
    margin-bottom: 15px;
    padding-bottom: 25px;
    border-bottom: 2px solid $clrBase;
    @media #{$querySp} {
      margin-bottom: 10px;
      padding-bottom: 15px;
      border-bottom: 1px solid $clrBase;
    }

    &_detail {
      span {
        display: block;
        font-family: $fontFamilyEn;
      }
    }

    &_head {
      line-height: 2.25;
      @include fs(16);
      @media #{$querySp} {
        @include fs(10);
      }
    }

    &_name {
      line-height: 1.28;
      font-weight: 700;
      @include fs(36);
      @media #{$querySp} {
        @include fs(18);
      }

      &_sub {
        font-family: $fontFamilyEn;
        @include fs(24);
        @media #{$querySp} {
          @include fs(12);
        }
      }
    }

    &_detail {
      margin-bottom: 25px;
      padding-bottom: 20px;
      border-bottom: 2px solid $clrBase;
      @media #{$querySp} {
        margin-bottom: 10px;
        padding-bottom: 15px;
        border-bottom: 1px solid $clrBase;
      }
    }
  }

  &_address {
    margin-bottom: 40px;
    @media #{$querySp} {
      margin-bottom: 20px;
    }
  }

  &_hc {
    &_cont {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 30px;

        @media #{$querySp} {
          margin-bottom: 20px;
        }
      }
    }

    &_icon {
      position: relative;
      z-index: 0;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      line-height: 1;
      background-color: $clrLOak;

      &_txt {
        position: absolute;
        z-index: 0;
        top: 50%;
        left: 50%;
        width: 100%;
        font-family: $fontEn;
        font-weight: 700;
        @include fs(11);
        text-align: center;
        transform: translate(-50%,-50%);
      }
    }
  }

  &_btn {
    &.row {
      display: flex;

      .c-btn_pagelink,
      .c-btn_arw {
        width: 48%;
        margin-right: 4%;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
