// ----- fonts

$fontFamily: ryo-gothic-plusn, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", YuGothic, '游ゴシック', "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS P Gothic", Helvetica, Verdana, sans-serif;
$fontEn: aktiv-grotesk, sans-serif;
$fontFamilyEn: aktiv-grotesk, ryo-gothic-plusn, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", YuGothic, '游ゴシック', "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS P Gothic", Helvetica, Verdana, sans-serif;

// ----- path

$src: "/assets/";

// ----- line-height

$lhBase: 2;
$lhBaseSp: 2;

// ----- margin

$mgSmall: 20;
$mgNormal: 30;
$mgLarge: 60;
$mgContainer: 40;

// ----- layout

$widthBase: 1080px;

$queryPc: "print, screen and (min-width: 768px)"; // PC
$queryPcIE: "_:-ms-lang(x)::-ms-backdrop,"; // IE11
$queryPcTab: "print, screen and (max-width: 1240px) and (min-width: 768px)"; // PC&TAB
$queryTab: "print, screen and (max-width: 1059px)"; // TAB
$querySp: "screen and (max-width: 767px)"; // SP
$querySpSE: "screen and (max-width: 320px)"; // iPhone SE
