.c-txt {
  &_border {
    margin: 0 auto 75px;
    padding: 15px 0;
    border-top: 2px solid $clrBase;
    border-bottom: 2px solid $clrBase;
    @media #{$querySp} {
      margin: 0 auto 80px;
      line-height: 1.58;
      padding: 15px 0;
      border-top: 1px solid $clrBase;
      border-bottom: 1px solid $clrBase;
    }
  }
}
