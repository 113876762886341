.c-arw {
  &_blk,
  &_wh {
    position: relative;
    z-index: 0;
    width: 28px;
    height: 28px;
    background-position: left top;
    background-size: contain;
    background-repeat: no-repeat;
    transition: .4s;

    @media #{$querySp} {
      width: 24px;
      height: 24px;
    }
  }
}

a {
  &:hover {
    .c-arw {
      &_blk,
      &_wh {
        background-image: url(../img/ico_arw_right_org.svg);

        img {
          display: none;
        }
      }
    }
  }
}
