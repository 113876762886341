.c-btn {
  &_arw {
    width: 100%;
    a,
    span {
      display: block;
      padding: 9px 0 10px;
      background-color: $clrBase;
      background-image: url(../img/ico_arw_next_wh.svg);
      background-position: 90% center;
      background-size: 15px 14px;
      background-repeat: no-repeat;
      color: $clrWht;
      font-family: $fontEn;
      font-weight: 700;
      @include fs(18);
      text-align: center;
      transition: .4s;
      @media #{$querySp} {
        padding: 8px 0 9px;
        background-position: 90% 49%;
        background-size: 11px auto;
        @include fs(12);
      }
      &:hover {
        background-color: $clrOrg;
      }
    }
  }

  &_back {
    width: 480px;
    margin: 100px auto 0;
    border: 2px solid $clrBase;
    @media #{$querySp} {
      width: 70%;
      margin: 50px auto 0;
      border: 1px solid $clrBase;
    }

    a {
      display: block;
      padding: 15px 0;
      background-image: url(../img/ico_arw_prev_blk.svg);
      background-position: 5% center;
      background-size: 15px 14px;
      background-repeat: no-repeat;
      font-family: $fontEn;
      font-weight: 700;
      @include fs(18);
      text-align: center;
      transition: .4s;
      @media #{$querySp} {
        background-size: 11px auto;
        @include fs(12);
      }
    }
  }

  &_buy {
    width: 320px;
    margin: 0 auto;
    @media #{$querySp} {
      width: 98%;
    }


    a {
      display: block;
      padding: 18px 0;
      background-color: $clrBase;
      background-image: url(../img/common/logo_oznis-mark.png);
      background-position: 8% center;
      background-size: 28px 28px;
      background-repeat: no-repeat;
      color: $clrWht;
      font-family: $fontEn;
      font-weight: 700;
      @include fs(18);
      text-align: center;
      transition: .4s;
      @media #{$querySp} {
        padding: 8px 0;
        background-size: 18px auto;
      }
      &:hover {
        background-color: $clrOrg;
      }
    }
  }

  &_pagelink {
    a {
      display: block;
      position: relative;
      z-index: 0;
      padding: 9px 0 10px;
      background-color: $clrBase;
      color: $clrWht;
      font-family: $fontEn;
      font-weight: 700;
      @include fs(18);
      text-align: center;
      transition: .4s;
      @media #{$querySp} {
        padding: 8px 0 9px;
        @include fs(12);
      }
      &:hover {
        background-color: $clrOrg;
      }

      &::before {
        position: absolute;
        z-index: 0;
        top: 40%;
        right: 10%;
        width: 15px;
        height: 14px;
        background-image: url(../img/ico_arw_next_wh.svg);
        background-position: top left;
        background-size: contain;
        background-repeat: no-repeat;
        transform: rotate(90deg);
        content: "";
        @media #{$querySp} {
          width: 11px;
          height: 12px;
          transform: rotate(0);
        }
      }
    }
  }
}

a {
  &:hover {
    .c-btn_arw {
      span {
        background-color: $clrOrg;
      }
    }
  }
}
