@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

input[type="button"], input[type="submit"] {
  -webkit-appearance: none;
}

html {
  font-size: 62.5%;
  width: 100%;
  height: 100%;
  -webkit-text-size-adjust: none;
  -webkit-print-color-adjust: exact;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
  min-width: 1080px;
  font-size: 14px;
  font-size: 1.4rem;
  font-family: ryo-gothic-plusn, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", YuGothic, "游ゴシック", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS P Gothic", Helvetica, Verdana, sans-serif;
  font-weight: 500;
  line-height: 2;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #909986;
}

@media screen and (max-width: 767px) {
  body {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 2;
    min-width: inherit;
  }
}

img {
  border-style: none;
  display: block;
  height: auto;
  vertical-align: bottom;
}

@media screen and (max-width: 767px) {
  img {
    max-width: 100%;
  }
}

a:link {
  color: #000;
  text-decoration: none;
}

a:visited {
  color: #000;
}

a:hover {
  color: #000;
}

a:active {
  color: #000;
}

html {
  opacity: 0;
}

html.wf-active {
  animation: fadeIn 1.5s ease-in 0s 1 forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

body.no-scroll {
  overflow-y: hidden;
}

@media screen and (max-width: 767px) {
  body.no-scroll .l-header_head {
    z-index: 10001;
  }
  body.no-scroll .l-header_head_logo, body.no-scroll .l-header_head_txt {
    visibility: hidden;
    pointer-events: none;
  }
}

.l-main {
  margin-top: 160px;
}

@media screen and (max-width: 767px) {
  .l-main {
    margin-top: 140px;
  }
}

.l-cont_w {
  width: 90%;
  margin: 0 auto;
}

@media print, screen and (min-width: 768px) {
  .l-cont_w {
    max-width: 1160px;
  }
}

@media print, screen and (max-width: 1240px) and (min-width: 768px) {
  .l-cont_w {
    width: 95%;
    max-width: 95%;
  }
}

.l-cont_l {
  width: 90%;
  margin: 0 auto;
}

@media print, screen and (min-width: 768px) {
  .l-cont_l {
    max-width: 1120px;
  }
}

@media print, screen and (max-width: 1240px) and (min-width: 768px) {
  .l-cont_l {
    width: 95%;
    max-width: 95%;
  }
}

.l-cont_m {
  width: 90%;
  margin: 0 auto;
}

@media print, screen and (min-width: 768px) {
  .l-cont_m {
    max-width: 1040px;
  }
}

@media print, screen and (max-width: 1240px) and (min-width: 768px) {
  .l-cont_m {
    width: 95%;
    max-width: 95%;
  }
}

.l-cont_s {
  width: 90%;
  margin: 0 auto;
}

@media print, screen and (min-width: 768px) {
  .l-cont_s {
    max-width: 800px;
  }
}

@media print, screen and (max-width: 1240px) and (min-width: 768px) {
  .l-cont_s {
    width: 95%;
    max-width: 95%;
  }
}

.l-block_oak {
  padding: 80px 0 100px;
}

@media screen and (max-width: 767px) {
  .l-block_oak {
    padding: 80px 0 50px;
  }
}

_:-ms-lang(x)::-ms-backdrop, body {
  overflow-x: hidden;
}

.l-header {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 160px;
  padding-top: 60px;
  background-color: #909986;
  font-family: aktiv-grotesk, sans-serif;
}

@media screen and (max-width: 767px) {
  .l-header {
    height: 140px;
    padding-top: 25px;
  }
}

@media print, screen and (min-width: 768px) {
  .l-header_cont {
    position: relative;
    z-index: 0;
    height: 100%;
  }
}

.l-header_head {
  display: flex;
}

@media print, screen and (min-width: 768px) {
  .l-header_head {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 767px) {
  .l-header_head {
    position: relative;
    z-index: 0;
    flex-direction: column;
  }
}

.l-header_head_logo {
  width: 200px;
}

@media print, screen and (min-width: 768px) {
  .l-header_head_logo {
    position: absolute;
    z-index: 0;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media print, screen and (max-width: 1240px) and (min-width: 768px) {
  .l-header_head_logo {
    width: 180px;
  }
}

@media screen and (max-width: 767px) {
  .l-header_head_logo {
    width: 150px;
    margin: 0 auto 10px;
  }
}

.l-header_head_logo a img {
  width: 100%;
}

.l-header_head_txt {
  line-height: 1.4;
  font-size: 10px;
  font-size: 1rem;
}

@media screen and (max-width: 767px) {
  .l-header_head_txt {
    line-height: 1.2;
    text-align: center;
  }
  .l-header_head_txt img {
    margin-top: 15px;
  }
}

@media screen and (max-width: 767px) {
  .l-header_menu {
    position: absolute;
    z-index: 1000;
    top: -25px;
    left: -6%;
    width: 48px;
    height: 48px;
    cursor: pointer;
  }
  .l-header_menu_open {
    display: block;
  }
  .l-header_menu_close {
    display: none;
  }
  .l-header_menu.active .l-header_menu_open {
    display: none;
  }
  .l-header_menu.active .l-header_menu_close {
    display: block;
  }
}

.l-footer {
  padding: 40px 0 30px;
  background-color: #fff;
  font-family: aktiv-grotesk, sans-serif;
}

@media screen and (max-width: 767px) {
  .l-footer {
    padding: 105px 0 20px;
  }
}

.l-footer_head {
  position: relative;
  z-index: 0;
  margin-bottom: 44px;
}

@media screen and (max-width: 767px) {
  .l-footer_head {
    margin-bottom: 25px;
  }
}

.l-footer_head .l-cont_w {
  display: flex;
}

@media print, screen and (min-width: 768px) {
  .l-footer_head .l-cont_w {
    position: relative;
    z-index: 0;
    align-items: flex-end;
  }
}

@media screen and (max-width: 767px) {
  .l-footer_head .l-cont_w {
    justify-content: space-between;
  }
}

.l-footer_head_logo {
  width: 160px;
}

@media print, screen and (min-width: 768px) {
  .l-footer_head_logo {
    margin-right: 40px;
  }
}

@media screen and (max-width: 767px) {
  .l-footer_head_logo {
    width: 100px;
  }
}

.l-footer_head_sns {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.l-footer_head_sns li {
  width: 20px;
  margin-right: 12px;
}

.l-footer_head_sns li:last-child {
  margin-right: 0;
}

.l-footer_head_sns li img {
  width: 100%;
}

.l-footer_head_pageTop {
  position: absolute;
  z-index: 0;
  top: 0;
  width: 36px;
  cursor: pointer;
  transition: .4s;
}

@media print, screen and (min-width: 768px) {
  .l-footer_head_pageTop {
    right: 0;
  }
}

@media screen and (max-width: 767px) {
  .l-footer_head_pageTop {
    top: -85px;
    left: 50%;
    width: 32px;
    transform: translateX(-50%);
  }
}

.l-footer_head_pageTop img {
  width: 100%;
}

.l-footer_head_pageTop:hover {
  opacity: .8;
}

.l-footer_nav {
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  .l-footer_nav {
    margin-bottom: 35px;
  }
}

.l-footer_nav_cont {
  display: flex;
}

@media screen and (max-width: 767px) {
  .l-footer_nav_cont {
    flex-wrap: wrap;
  }
  .l-footer_nav_cont:last-child {
    width: 100%;
  }
}

@media print, screen and (min-width: 768px) {
  .l-footer_nav_wrap, .l-footer_nav_wide {
    width: 25%;
  }
}

@media screen and (max-width: 767px) {
  .l-footer_nav_wrap {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .l-footer_nav_wide {
    width: 100%;
    margin-top: 35px;
  }
}

.l-footer_nav_list li {
  margin-bottom: 5px;
  font-size: 12px;
  font-size: 1.2rem;
}

@media screen and (max-width: 767px) {
  .l-footer_nav_list li {
    margin-bottom: 3px;
  }
}

.l-footer_nav_list li:last-child {
  margin-bottom: 0;
}

@media print, screen and (min-width: 768px) {
  .l-footer_nav_other {
    width: 25%;
  }
}

@media screen and (max-width: 767px) {
  .l-footer_nav_other {
    width: 100%;
    margin-top: 25px;
    padding-top: 25px;
    border-top: 1px solid #000;
  }
}

@media screen and (max-width: 767px) {
  .l-footer_nav_other_en, .l-footer_nav_other_ja {
    display: inline-block;
    vertical-align: top;
    width: 49%;
  }
}

@media print, screen and (min-width: 768px) {
  .l-footer_nav_other_en li, .l-footer_nav_other_ja li {
    margin-bottom: 15px;
  }
}

.l-footer_nav_other_en li:last-child, .l-footer_nav_other_ja li:last-child {
  margin-bottom: 0;
}

@media print, screen and (min-width: 768px) {
  .l-footer_nav_other_en {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  .l-footer_nav_other_en li {
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 767px) {
  .l-footer_nav_other_ja li {
    margin-bottom: 10px;
  }
}

.l-footer_nav_ttl {
  margin-bottom: 15px;
}

@media screen and (max-width: 767px) {
  .l-footer_nav_ttl {
    margin-bottom: 20px;
  }
}

.l-footer_nav_ttl_en {
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 767px) {
  .l-footer_nav_ttl_en {
    font-size: 14px;
    font-size: 1.4rem;
    margin-bottom: -5px;
  }
}

.l-footer_nav_ttl_en strong {
  font-weight: 700;
}

.l-footer_nav_ttl_ja {
  font-family: ryo-gothic-plusn, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", YuGothic, "游ゴシック", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS P Gothic", Helvetica, Verdana, sans-serif;
}

.l-footer_copyright {
  font-size: 10px;
  font-size: 1rem;
  text-align: center;
}

.l-gnav {
  position: absolute;
  z-index: 10000;
  top: 30px;
  right: 0;
}

@media print, screen and (min-width: 768px) {
  .l-gnav {
    padding-right: 94px;
  }
}

@media screen and (max-width: 767px) {
  .l-gnav {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100vh;
    padding: 30px 5% 120px;
    background-color: #E2680E;
    transition: 0.4s ease;
    opacity: 0;
    overflow-y: scroll;
  }
  .l-gnav.open {
    left: 0;
    opacity: 1;
  }
}

.l-gnav a {
  display: block;
}

.l-gnav a:hover {
  opacity: 0.8;
}

@media screen and (max-width: 767px) {
  .l-gnav_head {
    margin-bottom: 45px;
  }
  .l-gnav_head_logo {
    width: 120px;
    margin: 0 auto 30px;
  }
  .l-gnav_head_logo img {
    width: 100%;
  }
  .l-gnav_head_sns {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .l-gnav_head_sns li {
    width: 20px;
    margin-right: 12px;
  }
  .l-gnav_head_sns li:last-child {
    margin-right: 0;
  }
  .l-gnav_head_sns li img {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .l-gnav_sub {
    display: none;
  }
}

@media print, screen and (min-width: 768px) {
  .l-gnav_sub {
    display: flex;
    justify-content: flex-end;
    position: relative;
    z-index: 99;
    margin-bottom: 60px;
  }
  .l-gnav_sub_cont:first-child {
    margin-right: 35px;
  }
  .l-gnav_sub_cont a {
    display: flex;
  }
  .l-gnav_sub_cont a img {
    margin-right: 10px;
  }
  .l-gnav_sub_cont_ttl {
    font-weight: 700;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media print, screen and (min-width: 768px) and (min-width: 768px) {
  .l-gnav_sub_shop {
    position: absolute;
    z-index: 99;
    top: -30px;
    right: -94px;
    width: 64px;
    height: 64px;
    padding-top: 30px;
    background-color: #000;
    transition: 0.1s;
  }
  .l-gnav_sub_shop:hover {
    background-color: #E2680E;
  }
  .l-gnav_sub_shop a {
    display: flex;
    align-items: center;
  }
  .l-gnav_sub_shop a img {
    width: 20px;
    margin: 0 auto;
    transition: 0.2s;
  }
  .l-gnav_sub_shop_txt {
    display: none;
  }
  .l-gnav_sub_shop_ico {
    display: block;
  }
}

.l-gnav_list {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
}

@media print, screen and (min-width: 768px) {
  .l-gnav_list {
    display: flex !important;
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .l-gnav_list {
    position: relative;
    bottom: auto;
    left: auto;
    flex-wrap: wrap;
  }
}

@media print, screen and (min-width: 768px) {
  .l-gnav_cont {
    padding-bottom: 10px;
  }
  .l-gnav_cont:nth-child(1) {
    padding-right: 15px;
  }
  .l-gnav_cont:nth-child(2) {
    padding-left: 15px;
  }
}

@media screen and (max-width: 767px) {
  .l-gnav_cont {
    width: 50%;
  }
}

@media print, screen and (min-width: 768px) {
  .l-gnav_cont:last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: 767px) {
  .l-gnav_cont:last-child {
    width: 100%;
    margin-top: 35px;
  }
}

@media print, screen and (min-width: 768px) {
  .l-gnav_toggle {
    cursor: pointer;
  }
}

@media screen and (max-width: 767px) {
  .l-gnav_toggle {
    margin-bottom: 20px;
  }
}

.l-gnav_toggle a {
  display: flex;
  align-items: center;
}

.l-gnav_toggle a img {
  margin-right: 10px;
}

.l-gnav_toggle span {
  font-weight: 700;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 767px) {
  .l-gnav_toggle span {
    font-size: 100%;
  }
}

@media print, screen and (min-width: 768px) {
  .l-gnav_inner {
    position: fixed;
    z-index: 98;
    top: 160px;
    left: 0;
    width: 100%;
    height: 80px;
    opacity: 0;
    background-color: #fff;
    transform: scaleY(0);
    transform-origin: top left;
    transition: 0.4s;
    pointer-events: none;
  }
  .l-gnav_inner.open {
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
    pointer-events: auto;
  }
}

@media print, screen and (min-width: 768px) {
  .l-gnav_inner .l-cont_l {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

@media screen and (max-width: 767px) {
  .l-gnav_inner .l-cont_l {
    width: 100%;
  }
}

.l-gnav_inner_ttl {
  line-height: 1;
  font-size: 18px;
  font-size: 1.8rem;
  letter-spacing: 0.02em;
}

@media print, screen and (min-width: 768px) {
  .l-gnav_inner_ttl {
    padding-top: 30px;
    padding-left: 20px;
  }
}

.l-gnav_inner_ttl span {
  font-weight: 700;
}

@media print, screen and (min-width: 768px) {
  .l-gnav_inner_nav {
    display: flex;
    align-items: flex-start;
    padding-top: 2px;
  }
}

@media print, screen and (min-width: 768px) {
  .l-gnav_inner_cont {
    line-height: 1;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 30px;
    cursor: pointer;
  }
  .l-gnav_inner_cont:first-child {
    padding-left: 100px;
  }
  .l-gnav_inner_cont:last-child {
    padding-right: 0;
  }
}

@media print, screen and (min-width: 768px) {
  .l-gnav_inner_toggle {
    padding-bottom: 90px;
  }
}

@media screen and (max-width: 767px) {
  .l-gnav_inner_category {
    display: none;
  }
}

@media print, screen and (min-width: 768px) {
  .l-gnav_inner_category {
    position: fixed;
    z-index: 98;
    top: 78px;
    left: 0;
    width: 100%;
    height: 60px;
    opacity: 0;
    padding: 20px 0;
    background-color: #000;
    transform: scaleY(0);
    transform-origin: top left;
    transition: 0.4s;
    pointer-events: none;
  }
  .l-gnav_inner_category a {
    color: #fff;
    white-space: nowrap;
  }
  .l-gnav_inner_category.inner-open {
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
    pointer-events: auto;
  }
  .l-gnav_inner_category .l-cont_l {
    display: flex;
    align-items: flex-start;
  }
  .l-gnav_inner_category_ttl {
    display: flex;
    align-items: center;
    width: 19%;
    max-width: 208px;
    line-height: 1;
    padding-left: 20px;
    font-weight: 700;
    font-size: 18px;
    font-size: 1.8rem;
    letter-spacing: 0.02em;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) {
  .l-gnav_inner_category_ttl {
    display: block;
  }
}

@media print, screen and (min-width: 768px) {
  .l-gnav_inner_category_cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    max-width: 870px;
  }
  .l-gnav_inner_category_head {
    display: flex;
    align-items: center;
    line-height: 1;
    margin-right: 25px;
    font-size: 18px;
    font-size: 1.8rem;
    letter-spacing: 0.02em;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) {
  .l-gnav_inner_category_head {
    display: block;
  }
}

@media print, screen and (min-width: 768px) {
  .l-gnav_inner_category_list {
    display: flex;
    align-items: flex-start;
    margin-right: 30px;
  }
  .l-gnav_inner_category_list:last-child {
    margin-right: 0;
  }
  .l-gnav_inner_category_list li {
    margin-right: 20px;
  }
  .l-gnav_inner_category_list li:last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: 767px) {
  .l-gnav_sp {
    margin-top: 25px;
    padding-top: 25px;
    border-top: 1px solid #000;
  }
  .l-gnav_sp li {
    margin-bottom: 5px;
  }
  .l-gnav_sp li:last-child {
    margin-bottom: 0;
  }
  .l-gnav_sp li a {
    font-size: 14px;
    font-size: 1.4rem;
  }
  .l-gnav_sp li a span {
    font-weight: 700;
  }
}

/* Slider */
/* .slick-list {
    .slick-loading & {
        background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
    }
} */
/* Icons */
/* @if $slick-font-family == "slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
} */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.bx-wrapper {
  position: relative;
  margin-bottom: 60px;
  padding: 0;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -moz-box-shadow: 0 0 5px #ccc;
  -webkit-box-shadow: 0 0 5px #ccc;
  box-shadow: 0 0 5px #ccc;
  border: 5px solid #fff;
  background: #fff;
}

.bx-wrapper img {
  max-width: 100%;
  display: block;
}

.bxslider {
  margin: 0;
  padding: 0;
  -webkit-perspective: 1000;
}

ul.bxslider {
  list-style: none;
}

.bx-viewport {
  -webkit-transform: translateZ(0);
}

.bx-wrapper .bx-controls-auto, .bx-wrapper .bx-pager {
  position: absolute;
  bottom: -30px;
  width: 100%;
}

.bx-wrapper .bx-loading {
  min-height: 50px;
  /*background:url(images/bx_loader.gif) center center no-repeat #fff;*/
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
}

.bx-wrapper .bx-pager {
  text-align: center;
  font-size: .85em;
  font-family: Arial;
  font-weight: 700;
  color: #666;
  padding-top: 20px;
}

.bx-wrapper .bx-pager.bx-default-pager a {
  background: #666;
  text-indent: -9999px;
  display: block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  outline: 0;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.bx-wrapper .bx-pager.bx-default-pager a.active, .bx-wrapper .bx-pager.bx-default-pager a:focus, .bx-wrapper .bx-pager.bx-default-pager a:hover {
  background: #000;
}

.bx-wrapper .bx-controls-auto .bx-controls-auto-item, .bx-wrapper .bx-pager-item {
  display: inline-block;
  vertical-align: bottom;
}

.bx-wrapper .bx-pager-item {
  font-size: 0;
  line-height: 0;
}

.bx-wrapper .bx-prev {
  left: 10px;
  /*background:url(images/controls.png) 0 -32px no-repeat*/
}

/*.bx-wrapper .bx-prev:focus,.bx-wrapper .bx-prev:hover{background-position:0 0}*/
.bx-wrapper .bx-next {
  right: 10px;
  /*background:url(images/controls.png) -43px -32px no-repeat*/
}

/*.bx-wrapper .bx-next:focus,.bx-wrapper .bx-next:hover{background-position:-43px 0}*/
.bx-wrapper .bx-controls-direction a {
  position: absolute;
  top: 50%;
  margin-top: -16px;
  outline: 0;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  z-index: 9999;
}

.bx-wrapper .bx-controls-direction a.disabled {
  display: none;
}

.bx-wrapper .bx-controls-auto {
  text-align: center;
}

.bx-wrapper .bx-controls-auto .bx-start {
  display: block;
  text-indent: -9999px;
  width: 10px;
  height: 11px;
  outline: 0;
  /*background:url(images/controls.png) -86px -11px no-repeat;*/
  margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-start.active, .bx-wrapper .bx-controls-auto .bx-start:focus, .bx-wrapper .bx-controls-auto .bx-start:hover {
  background-position: -86px 0;
}

.bx-wrapper .bx-controls-auto .bx-stop {
  display: block;
  text-indent: -9999px;
  width: 9px;
  height: 11px;
  outline: 0;
  /*background:url(images/controls.png) -86px -44px no-repeat;*/
  margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-stop.active, .bx-wrapper .bx-controls-auto .bx-stop:focus, .bx-wrapper .bx-controls-auto .bx-stop:hover {
  background-position: -86px -33px;
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
  text-align: left;
  width: 80%;
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
  right: 0;
  width: 35px;
}

.bx-wrapper .bx-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #666;
  background: rgba(80, 80, 80, 0.75);
  width: 100%;
}

.bx-wrapper .bx-caption span {
  color: #fff;
  font-family: Arial;
  display: block;
  font-size: .85em;
  padding: 10px;
}

.series {
  padding-top: 60px;
}

@media screen and (max-width: 767px) {
  .series {
    padding-top: 30px;
  }
}

.series h2.c-ttl_list {
  display: block;
  margin-bottom: 20px;
  width: 100%;
  font-size: 36px;
  font-size: 3.6rem;
  text-align: left;
  font-weight: bold;
  line-height: 1;
  font-family: aktiv-grotesk, sans-serif;
}

@media screen and (max-width: 767px) {
  .series h2.c-ttl_list {
    font-size: 18px;
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
}

.series h3.c-ttl_list {
  display: block;
  font-size: 24px;
  font-size: 2.4rem;
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
  font-weight: bold;
  font-family: aktiv-grotesk, sans-serif;
  line-height: 1;
}

@media screen and (max-width: 767px) {
  .series h3.c-ttl_list {
    font-size: 12px;
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
}

.series .c-items_list li:nth-child(4n) {
  margin-right: 2%;
}

@media screen and (max-width: 767px) {
  .series .c-items_list li:nth-child(4n) {
    margin-right: 2.5%;
  }
}

.series .c-items_list li.fourth {
  margin-right: 0;
}

@media screen and (max-width: 767px) {
  .series .c-items_list li.fourth {
    margin-right: 2.5%;
  }
}

@media screen and (max-width: 767px) {
  .series .c-items_list li:nth-child(2n) {
    margin-right: 2.5%;
  }
  .series .c-items_list li.second {
    margin-right: 0;
  }
}

.series .c-items_img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.series .c-items_img .c-items_img_item {
  position: relative;
}

.c-ttl_base {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .c-ttl_base {
    margin-bottom: 20px;
  }
}

.c-ttl_base span {
  display: block;
}

.c-ttl_base_en {
  margin-right: 30px;
  font-family: aktiv-grotesk, sans-serif;
  font-size: 32px;
  font-size: 3.2rem;
}

@media screen and (max-width: 767px) {
  .c-ttl_base_en {
    margin-right: 10px;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.c-ttl_base_en strong {
  font-weight: 700;
}

.c-ttl_base_ja {
  font-size: 24px;
  font-size: 2.4rem;
}

@media screen and (max-width: 767px) {
  .c-ttl_base_ja {
    margin-top: 3px;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.c-ttl_list {
  margin-bottom: 40px;
  text-align: center;
}

@media print, screen and (min-width: 768px) {
  .c-ttl_list {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .c-ttl_list {
    margin-bottom: 20px;
  }
}

.c-ttl_list span {
  display: block;
  font-family: aktiv-grotesk, sans-serif;
}

.c-ttl_list_sub {
  font-size: 14px;
  font-size: 1.4rem;
}

@media print, screen and (min-width: 768px) {
  .c-ttl_list_sub {
    margin-top: 3px;
    margin-right: 30px;
  }
}

@media screen and (max-width: 767px) {
  .c-ttl_list_sub {
    font-size: 10px;
    font-size: 1rem;
  }
}

.c-ttl_list_main {
  font-weight: 700;
  font-size: 24px;
  font-size: 2.4rem;
}

@media screen and (max-width: 767px) {
  .c-ttl_list_main {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.c-ttl_list_main strong {
  font-weight: 700;
}

.c-ttl_en {
  margin-bottom: 40px;
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 700;
  font-size: 24px;
  font-size: 2.4rem;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .c-ttl_en {
    margin-bottom: 20px;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.c-ttl_center {
  margin-bottom: 35px;
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 700;
  font-size: 36px;
  font-size: 3.6rem;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .c-ttl_center {
    margin-bottom: 15px;
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.section-text {
  margin: 0 auto 100px auto;
  width: 800px;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 2;
}

@media screen and (max-width: 767px) {
  .section-text {
    width: 335px;
    margin-bottom: 30px;
  }
}

.c-arw_blk, .c-arw_wh {
  position: relative;
  z-index: 0;
  width: 28px;
  height: 28px;
  background-position: left top;
  background-size: contain;
  background-repeat: no-repeat;
  transition: .4s;
}

@media screen and (max-width: 767px) {
  .c-arw_blk, .c-arw_wh {
    width: 24px;
    height: 24px;
  }
}

a:hover .c-arw_blk, a:hover .c-arw_wh {
  background-image: url(../img/ico_arw_right_org.svg);
}

a:hover .c-arw_blk img, a:hover .c-arw_wh img {
  display: none;
}

.c-items {
  padding-bottom: 20px;
}

.c-items:last-child {
  padding-bottom: 0;
}

.c-items_list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.c-items_list li {
  position: relative;
  width: 23.5%;
  margin-bottom: 60px;
  margin-right: 2%;
}

@media print, screen and (min-width: 768px) {
  .c-items_list li:nth-child(4n) {
    margin-right: 0;
  }
}

@media screen and (max-width: 767px) {
  .c-items_list li {
    width: 48.5%;
    margin-bottom: 30px;
    margin-right: 2.5%;
  }
  .c-items_list li:nth-child(2n) {
    margin-right: 0;
  }
}

.c-items_list li.is-items .c-items_img {
  opacity: 0.6;
}

.c-items_list li a {
  display: block;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.c-items_list li a:hover .c-items_img,
.c-items_list li a:hover .c-items_detail_cont {
  opacity: 0.7;
}

.c-items_list li a:hover .c-btn_arw span {
  background-color: #E2680E;
}

.c-items_list li .c-btn_arw span:hover {
  background-color: #000;
}

.c-items_ranking {
  position: relative;
  z-index: 0;
}

.c-items_ranking_no {
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 10px;
  line-height: 1;
  font-family: aktiv-grotesk, sans-serif;
  font-size: 10px;
  font-size: 1rem;
  letter-spacing: 0.02em;
}

.c-items_ranking_no span {
  margin-left: 5px;
  font-weight: 700;
  font-size: 24px;
  font-size: 2.4rem;
  letter-spacing: normal;
}

@media screen and (max-width: 767px) {
  .c-items_ranking_no span {
    font-size: 20px;
    font-size: 2rem;
  }
}

.c-items_img {
  position: relative;
  z-index: 0;
  height: 240px;
  margin-bottom: 10px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .c-items_img {
    height: 160px;
    margin-bottom: 10px;
  }
}

.c-items_img_item, .c-items_img_frame, .c-items_img_lens {
  position: absolute;
}

.c-items_img_item img, .c-items_img_frame img, .c-items_img_lens img {
  width: 100%;
}

.c-items_img_item {
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
}

.c-items_img_frame, .c-items_img_lens {
  z-index: 0;
}

.c-items_img_frame {
  top: 165px;
  left: 30px;
  width: 80px;
}

@media screen and (max-width: 767px) {
  .c-items_img_frame {
    top: 111px;
    left: 10%;
    width: 47px;
  }
}

.c-items_img_lens {
  top: 170px;
  right: 20px;
  width: 98px;
}

@media screen and (max-width: 767px) {
  .c-items_img_lens {
    top: 113px;
    right: 5%;
    width: 54px;
  }
}

.c-items_detail {
  font-family: aktiv-grotesk, sans-serif;
  display: flex;
  flex-direction: column;
}

.c-items_detail .c-btn_arw {
  margin-top: 25px;
}

@media screen and (max-width: 767px) {
  .c-items_detail .c-btn_arw {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .c-items_detail_ttl, .c-items_detail_price {
    font-size: 10px;
    font-size: 1rem;
  }
}

.c-items_detail_name {
  line-height: 1.75;
  font-weight: 700;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .c-items_detail_name {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.c-btn_arw {
  width: 100%;
}

.c-btn_arw a,
.c-btn_arw span {
  display: block;
  padding: 9px 0 10px;
  background-color: #000;
  background-image: url(../img/ico_arw_next_wh.svg);
  background-position: 90% center;
  background-size: 15px 14px;
  background-repeat: no-repeat;
  color: #fff;
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.8rem;
  text-align: center;
  transition: .4s;
}

@media screen and (max-width: 767px) {
  .c-btn_arw a,
  .c-btn_arw span {
    padding: 8px 0 9px;
    background-position: 90% 49%;
    background-size: 11px auto;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.c-btn_arw a:hover,
.c-btn_arw span:hover {
  background-color: #E2680E;
}

.c-btn_back {
  width: 480px;
  margin: 100px auto 0;
  border: 2px solid #000;
}

@media screen and (max-width: 767px) {
  .c-btn_back {
    width: 70%;
    margin: 50px auto 0;
    border: 1px solid #000;
  }
}

.c-btn_back a {
  display: block;
  padding: 15px 0;
  background-image: url(../img/ico_arw_prev_blk.svg);
  background-position: 5% center;
  background-size: 15px 14px;
  background-repeat: no-repeat;
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.8rem;
  text-align: center;
  transition: .4s;
}

@media screen and (max-width: 767px) {
  .c-btn_back a {
    background-size: 11px auto;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.c-btn_buy {
  width: 320px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .c-btn_buy {
    width: 98%;
  }
}

.c-btn_buy a {
  display: block;
  padding: 18px 0;
  background-color: #000;
  background-image: url(../img/common/logo_oznis-mark.png);
  background-position: 8% center;
  background-size: 28px 28px;
  background-repeat: no-repeat;
  color: #fff;
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.8rem;
  text-align: center;
  transition: .4s;
}

@media screen and (max-width: 767px) {
  .c-btn_buy a {
    padding: 8px 0;
    background-size: 18px auto;
  }
}

.c-btn_buy a:hover {
  background-color: #E2680E;
}

.c-btn_pagelink a {
  display: block;
  position: relative;
  z-index: 0;
  padding: 9px 0 10px;
  background-color: #000;
  color: #fff;
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.8rem;
  text-align: center;
  transition: .4s;
}

@media screen and (max-width: 767px) {
  .c-btn_pagelink a {
    padding: 8px 0 9px;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.c-btn_pagelink a:hover {
  background-color: #E2680E;
}

.c-btn_pagelink a::before {
  position: absolute;
  z-index: 0;
  top: 40%;
  right: 10%;
  width: 15px;
  height: 14px;
  background-image: url(../img/ico_arw_next_wh.svg);
  background-position: top left;
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(90deg);
  content: "";
}

@media screen and (max-width: 767px) {
  .c-btn_pagelink a::before {
    width: 11px;
    height: 12px;
    transform: rotate(0);
  }
}

a:hover .c-btn_arw span {
  background-color: #E2680E;
}

@media screen and (max-width: 767px) {
  .c-styling {
    position: relative;
    z-index: 0;
    padding-bottom: 40px;
  }
  .c-styling::after {
    position: absolute;
    z-index: 0;
    bottom: 8px;
    left: 50%;
    width: 80px;
    height: 1px;
    background-color: #000;
    transform: translateX(-50%);
    content: "";
  }
}

.c-styling_list {
  display: flex;
}

@media screen and (max-width: 767px) {
  .c-styling_list {
    z-index: 0;
  }
  .c-styling_list .slick-arrow {
    position: absolute;
    z-index: 0;
    bottom: -36px;
    width: 11px;
    height: 10px;
    background-position: left top;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  .c-styling_list .slick-arrow:hover {
    opacity: .8;
  }
  .c-styling_list .slick-arrow.slider_prev {
    left: 32%;
    background-image: url(../img/ico_arw_prev_blk.svg);
  }
  .c-styling_list .slick-arrow.slider_next {
    right: 32%;
    background-image: url(../img/ico_arw_next_blk.svg);
  }
}

.c-styling_list li {
  width: 25%;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .c-styling_list li {
    width: 50%;
  }
}

.c-styling_list li a {
  display: block;
}

.c-styling_img {
  margin-bottom: 10px;
}

.c-styling_img img {
  width: 100%;
}

.c-styling_detail {
  width: 90%;
  margin: 0 auto;
  line-height: 2;
  font-family: aktiv-grotesk, sans-serif;
}

.c-styling_detail_ttl {
  display: block;
}

.c-styling_modal {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.c-styling_modal.is-modal {
  display: block;
  pointer-events: all;
}

.c-styling_modal_bg {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #909986;
}

.c-styling_modal_box {
  position: relative;
  z-index: 1001;
  width: 960px;
  height: 760px;
  margin: 50px auto 0;
}

@media screen and (max-width: 767px) {
  .c-styling_modal_box {
    width: 345px;
    height: 95%;
    margin: 15px auto 0;
  }
}

.c-styling_modal_btn {
  position: absolute;
  z-index: 10000;
  top: 0;
  right: 0;
  width: 48px;
}

@media screen and (max-width: 767px) {
  .c-styling_modal_btn {
    top: -15px;
    right: -5%;
  }
}

.c-styling_modal_btn img {
  width: 100%;
}

.c-styling_modal .modal_arw {
  position: absolute;
  z-index: 1;
  top: 50%;
  width: 48px;
  height: 48px;
  background-position: left top;
  background-size: contain;
  background-repeat: no-repeat;
  transform: translateY(-50%);
  cursor: pointer;
}

.c-styling_modal .modal_arw.arw_prev {
  left: 0;
  background-image: url(../img/ico_arw_prev_bg_blk.svg);
}

.c-styling_modal .modal_arw.arw_next {
  right: 0;
  background-image: url(../img/ico_arw_next_bg_blk.svg);
}

.c-styling_modal_area {
  position: relative;
  z-index: 0;
  width: 960px;
  height: 760px;
  margin: 0 auto;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .c-styling_modal_area {
    width: 345px;
    height: 90%;
  }
}

.c-styling_modal_slider {
  display: flex;
  position: absolute;
  z-index: 0;
  height: 100%;
}

.c-styling_modal_cont {
  width: 960px;
  height: 100%;
  overflow-y: scroll;
  transition: all 1s;
}

@media screen and (max-width: 767px) {
  .c-styling_modal_cont {
    width: 345px;
  }
}

.c-styling_modal_inner {
  padding: 43px 60px;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .c-styling_modal_inner {
    padding: 25px 2.5% 30px;
  }
}

.c-styling_modal_close {
  cursor: pointer;
}

@media print, screen and (min-width: 768px) {
  .c-styling_modal_block {
    display: flex;
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 767px) {
  .c-styling_modal_block {
    margin: 0 auto 30px;
  }
}

.c-styling_modal_list {
  width: 480px !important;
}

@media screen and (max-width: 767px) {
  .c-styling_modal_list {
    width: 290px !important;
    margin-right: auto;
    margin-bottom: 20px !important;
    margin-left: auto;
  }
}

.c-styling_modal_list li {
  width: 480px;
}

@media screen and (max-width: 767px) {
  .c-styling_modal_list li {
    width: 290px;
    overflow: hidden;
  }
}

.c-styling_modal_list li img {
  width: 100%;
}

.c-styling_modal_list .slick-dots {
  z-index: 0;
  bottom: 20px;
}

@media screen and (max-width: 767px) {
  .c-styling_modal_list .slick-dots {
    bottom: 15px;
  }
}

.c-styling_modal_list .slick-dots li {
  width: 12px !important;
  height: 12px !important;
  border-radius: 50%;
  margin: 0 10px 0 0;
}

@media screen and (max-width: 767px) {
  .c-styling_modal_list .slick-dots li {
    width: 10px !important;
    height: 10px !important;
    margin: 0 5px 0 0;
  }
}

.c-styling_modal_list .slick-dots li:last-child {
  margin: 0;
}

.c-styling_modal_list .slick-dots li::before {
  display: none;
}

.c-styling_modal_list .slick-dots li button {
  display: block;
  width: 12px;
  height: 12px;
  padding: 0;
  border-radius: 50%;
  background-color: #fff;
  opacity: .6;
}

@media screen and (max-width: 767px) {
  .c-styling_modal_list .slick-dots li button {
    width: 10px;
    height: 10px;
  }
}

.c-styling_modal_list .slick-dots li.slick-active button {
  opacity: 1;
}

.c-styling_modal_main {
  width: 272px;
  margin: 10px 0 0 72px;
}

@media screen and (max-width: 767px) {
  .c-styling_modal_main {
    width: 60%;
    margin: 0 auto;
  }
}

.c-styling_modal_ttl {
  margin-bottom: 35px;
  font-size: 16px;
  font-size: 1.6rem;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .c-styling_modal_ttl {
    margin-bottom: 20px;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 767px) {
  .c-styling_modal_txt {
    width: 90%;
    line-height: 1.58;
    margin: 0 auto;
  }
}

.c-breadcrumb {
  display: inline-block;
  background-color: #BBC7AE;
  padding: 10px 25px 10px 0;
}

@media print, screen and (min-width: 768px) {
  .c-breadcrumb {
    padding-left: calc(50% - (1280px / 3) - 135px);
  }
}

@media print, screen and (max-width: 1240px) and (min-width: 768px) {
  .c-breadcrumb {
    padding-left: calc(50% - (1060px / 3) - 160px);
  }
}

@media screen and (max-width: 767px) {
  .c-breadcrumb {
    padding: 5px 10px 5px 5%;
  }
}

.c-breadcrumb_list {
  display: flex;
  flex-wrap: wrap;
  font-family: aktiv-grotesk, sans-serif;
}

.c-breadcrumb_list li {
  position: relative;
  z-index: 0;
  margin-right: 4px;
  font-size: 12px;
  font-size: 1.2rem;
}

@media screen and (max-width: 767px) {
  .c-breadcrumb_list li {
    font-size: 10px;
    font-size: 1rem;
  }
}

.c-breadcrumb_list li:first-child::before {
  display: none;
  margin-right: 0;
}

.c-breadcrumb_list li:last-child {
  font-weight: 700;
}

.c-breadcrumb_list li::before {
  display: inline-block;
  margin-right: 2px;
  font-size: 12px;
  font-size: 1.2rem;
  content: ">";
}

@media screen and (max-width: 767px) {
  .c-breadcrumb_list li::before {
    font-size: 10px;
    font-size: 1rem;
  }
}

.c-breadcrumb_list li a {
  display: inline-block;
  color: #000;
}

.c-txt_border {
  margin: 0 auto 75px;
  padding: 15px 0;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
}

@media screen and (max-width: 767px) {
  .c-txt_border {
    margin: 0 auto 80px;
    line-height: 1.58;
    padding: 15px 0;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
  }
}

.c-kv_ttl_head {
  font-family: aktiv-grotesk, sans-serif;
  font-size: 10px;
  font-size: 1rem;
}

.c-kv_ttl_center {
  display: block;
  line-height: 1;
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 700;
  font-size: 36px;
  font-size: 3.6rem;
}

@media screen and (max-width: 767px) {
  .c-kv_ttl_center {
    margin-bottom: 8px;
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.c-kv_ttl_btm {
  display: block;
  line-height: 1;
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 700;
  font-size: 96px;
  font-size: 9.6rem;
}

@media screen and (max-width: 767px) {
  .c-kv_ttl_btm {
    font-size: 48px;
    font-size: 4.8rem;
  }
}

.c-kv_ttl_icon {
  position: relative;
  z-index: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #EC6713;
}

.c-kv_ttl_icon--dotsuki {
  background-color: #bbc7ae;
}

@media screen and (max-width: 767px) {
  .c-kv_ttl_icon {
    width: 48px;
    height: 48px;
  }
}

.c-kv_ttl_icon_txt {
  display: block;
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  width: 100%;
  line-height: 1.6;
  font-weight: 700;
  font-size: 12px;
  font-size: 1.2rem;
  text-align: center;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 767px) {
  .c-kv_ttl_icon_txt {
    font-size: 10px;
    font-size: 1rem;
  }
}

@media print, screen and (min-width: 768px) {
  .c-shop .l-cont_l {
    display: flex;
    flex-direction: row-reverse;
  }
}

@media screen and (max-width: 767px) {
  .c-shop .l-cont_l {
    width: 100%;
  }
}

.c-shop_img {
  display: block;
}

@media print, screen and (min-width: 768px) {
  .c-shop_img {
    width: 58%;
    max-width: 640px;
  }
}

@media screen and (max-width: 767px) {
  .c-shop_img {
    margin-bottom: 25px;
  }
}

.c-shop_img img {
  width: 100%;
}

@media print, screen and (min-width: 768px) {
  .c-shop_cont {
    position: relative;
    z-index: 0;
    width: 36%;
    max-width: 400px;
    margin-right: 80px;
  }
}

@media screen and (max-width: 767px) {
  .c-shop_cont {
    width: 90%;
    margin: 0 auto;
  }
}

.c-shop_cont .c-shop_hc_icon {
  margin-right: 20px;
}

@media print, screen and (min-width: 768px) {
  .c-shop_cont .c-shop_hc_icon {
    width: 48px;
    height: 48px;
  }
}

@media screen and (max-width: 767px) {
  .c-shop_cont .c-shop_hc_icon {
    margin-right: 10px;
  }
}

.c-shop_cont .c-shop_hc_icon_txt {
  font-size: 15px;
  font-size: 1.5rem;
}

@media screen and (max-width: 767px) {
  .c-shop_cont .c-shop_hc_icon_txt {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

@media print, screen and (min-width: 768px) {
  .c-shop_cont .c-shop_hc_txt {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.c-shop_ttl {
  margin-bottom: 15px;
  padding-bottom: 25px;
  border-bottom: 2px solid #000;
}

@media screen and (max-width: 767px) {
  .c-shop_ttl {
    margin-bottom: 10px;
    padding-bottom: 15px;
    border-bottom: 1px solid #000;
  }
}

.c-shop_ttl_detail span {
  display: block;
  font-family: aktiv-grotesk, ryo-gothic-plusn, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", YuGothic, "游ゴシック", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS P Gothic", Helvetica, Verdana, sans-serif;
}

.c-shop_ttl_head {
  line-height: 2.25;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .c-shop_ttl_head {
    font-size: 10px;
    font-size: 1rem;
  }
}

.c-shop_ttl_name {
  line-height: 1.28;
  font-weight: 700;
  font-size: 36px;
  font-size: 3.6rem;
}

@media screen and (max-width: 767px) {
  .c-shop_ttl_name {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.c-shop_ttl_name_sub {
  font-family: aktiv-grotesk, ryo-gothic-plusn, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", YuGothic, "游ゴシック", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS P Gothic", Helvetica, Verdana, sans-serif;
  font-size: 24px;
  font-size: 2.4rem;
}

@media screen and (max-width: 767px) {
  .c-shop_ttl_name_sub {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.c-shop_ttl_detail {
  margin-bottom: 25px;
  padding-bottom: 20px;
  border-bottom: 2px solid #000;
}

@media screen and (max-width: 767px) {
  .c-shop_ttl_detail {
    margin-bottom: 10px;
    padding-bottom: 15px;
    border-bottom: 1px solid #000;
  }
}

.c-shop_address {
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .c-shop_address {
    margin-bottom: 20px;
  }
}

.c-shop_hc_cont {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.c-shop_hc_cont:last-child {
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .c-shop_hc_cont:last-child {
    margin-bottom: 20px;
  }
}

.c-shop_hc_icon {
  position: relative;
  z-index: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  line-height: 1;
  background-color: #BBC7AE;
}

.c-shop_hc_icon_txt {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  width: 100%;
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 700;
  font-size: 11px;
  font-size: 1.1rem;
  text-align: center;
  transform: translate(-50%, -50%);
}

.c-shop_btn.row {
  display: flex;
}

.c-shop_btn.row .c-btn_pagelink,
.c-shop_btn.row .c-btn_arw {
  width: 48%;
  margin-right: 4%;
}

.c-shop_btn.row .c-btn_pagelink:last-child,
.c-shop_btn.row .c-btn_arw:last-child {
  margin-right: 0;
}

.home .video-modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #909986;
  opacity: 0;
  visibility: hidden;
  z-index: 1001;
  transition: opacity 0.4s, visibility 0s 0.4s;
}

.home .video-modal.active {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.4s, visibility 0s;
}

.home .video-modal .inner {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-height: 80vh;
  max-width: 1920px;
}

.home .video-modal .inner::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}

@media screen and (max-width: 767px) {
  .home .video-modal .inner {
    max-width: initial;
  }
}

.home .video-modal .inner .video-close-btn {
  position: absolute;
  top: -24px;
  right: 30px;
  height: 48px;
  width: 48px;
  cursor: pointer;
  z-index: 1;
  transition: 0.4s;
}

.home .video-modal .inner .video-close-btn:hover {
  opacity: 0.7;
}

.home .video-modal .inner iframe {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .home .video-modal {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #909986;
    opacity: 0;
    visibility: hidden;
    z-index: 1001;
  }
  .home .video-modal.active {
    opacity: 1;
    visibility: visible;
  }
  .home .video-modal .inner {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 1920px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .home .video-modal .inner {
    max-width: initial;
  }
}

@media screen and (max-width: 767px) {
  .home .video-modal .inner iframe {
    width: 100%;
  }
  .home .l-gnav_sub_shop {
    height: 240px;
    padding-top: 24px;
  }
  .home .l-gnav_sub_shop_txt {
    display: block;
  }
  .home .l-gnav_sub_shop_ico {
    display: none;
  }
  .home .l-header.is-scroll .l-gnav_sub_shop {
    height: 64px;
  }
  .home .l-header.is-scroll .l-gnav_sub_shop_txt {
    display: none;
  }
  .home .l-header.is-scroll .l-gnav_sub_shop_ico {
    display: block;
  }
}

.p-index_kv {
  cursor: pointer;
}

.p-index_kv_copy {
  line-height: 1;
  padding-top: 20px;
  padding-bottom: 24px;
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 700;
  font-size: 36px;
  font-size: 3.6rem;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .p-index_kv_copy {
    padding-bottom: 10px;
    font-size: 24px;
    font-size: 2.4rem;
  }
}

.p-index_kv_slider {
  margin-bottom: 0 !important;
}

.p-index_kv_slider li {
  position: relative;
  z-index: 0;
  height: 740px;
  border: 1px solid #707070;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .p-index_kv_slider li {
    height: 122.93333333333334vw;
  }
}

.p-index_kv_slider li::before {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 740px;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.8;
  content: "";
  display: none;
}

@media screen and (max-width: 767px) {
  .p-index_kv_slider li::before {
    height: 480px;
  }
}

.p-index_kv_slider li:nth-child(1)::before {
  background-image: url(../img/index/bg_kv_01_pc.png);
}

@media screen and (max-width: 767px) {
  .p-index_kv_slider li:nth-child(1)::before {
    background-image: url(../img/index/bg_kv_01_sp.png);
  }
}

.p-index_kv_slider li:nth-child(2)::before {
  background-image: url(https://placehold.jp/30/c7c7c7/ffffff/2560x1400.png?text=slider+02);
}

@media screen and (max-width: 767px) {
  .p-index_kv_slider li:nth-child(2)::before {
    background-image: url(https://placehold.jp/30/c7c7c7/ffffff/750x960.png?text=slider+02);
  }
}

.p-index_kv_slider li:nth-child(3)::before {
  background-image: url(https://placehold.jp/30/c7c7c7/ffffff/2560x1400.png?text=slider+03);
}

@media screen and (max-width: 767px) {
  .p-index_kv_slider li:nth-child(3)::before {
    background-image: url(https://placehold.jp/30/c7c7c7/ffffff/750x960.png?text=slider+03);
  }
}

.p-index_kv_slider li video {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
}

.p-index_kv_slider li .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 120px;
  width: 120px;
  transform: translate3d(-50%, -50%, 0);
  z-index: 1;
  transition: 0.4s;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .p-index_kv_slider li .play-btn {
    height: 90px;
    width: 90px;
  }
}

.p-index_kv_slider li .play-btn:hover {
  opacity: 0.7;
}

.p-index_kv_slider li .play-btn img {
  width: 100%;
}

.p-index_kv_slider li a,
.p-index_kv_slider li .block {
  display: block;
  height: 100%;
}

.p-index_kv_slider li a .l-cont_l,
.p-index_kv_slider li .block .l-cont_l {
  position: relative;
  z-index: 0;
  height: 100%;
}

.p-index_kv_slider .slick-dots {
  z-index: 0;
  bottom: 30px;
}

@media screen and (max-width: 767px) {
  .p-index_kv_slider .slick-dots {
    bottom: 15px;
  }
}

.p-index_kv_slider .slick-dots li {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: none;
  margin: 0 10px 0 0;
}

@media screen and (max-width: 767px) {
  .p-index_kv_slider .slick-dots li {
    width: 10px;
    height: 10px;
    margin: 0 5px 0 0;
  }
}

.p-index_kv_slider .slick-dots li:last-child {
  margin: 0;
}

.p-index_kv_slider .slick-dots li::before {
  display: none;
}

.p-index_kv_slider .slick-dots li button {
  display: block;
  width: 12px;
  height: 12px;
  padding: 0;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.6;
}

@media screen and (max-width: 767px) {
  .p-index_kv_slider .slick-dots li button {
    width: 10px;
    height: 10px;
  }
}

.p-index_kv_slider .slick-dots li.slick-active button {
  opacity: 1;
}

.p-index_kv_ttl {
  position: absolute;
  z-index: 0;
  bottom: 80px;
  left: 0;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .p-index_kv_ttl {
    bottom: 40px;
  }
}

.p-index_kv_ttl_en {
  color: #fff;
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 700;
  font-size: 96px;
  font-size: 9.6rem;
}

@media screen and (max-width: 767px) {
  .p-index_kv_ttl_en {
    font-size: 40px;
    font-size: 4rem;
  }
}

.p-index_kv_ttl_en.blk {
  color: #000;
}

@media print, screen and (min-width: 768px) {
  .p-index_kv_ttl_inner {
    display: flex;
  }
}

@media screen and (max-width: 767px) {
  .p-index_kv_ttl_inner_ja {
    display: inline;
  }
  .p-index_kv_ttl_inner_ja .u-only_sp {
    display: inline;
  }
}

.p-index_kv_ttl_inner_ja strong {
  display: inline-block;
  padding: 0 32px 0 13px;
  background-color: #fff;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.8rem;
}

@media print, screen and (min-width: 768px) {
  .p-index_kv_ttl_inner_ja strong {
    height: 36px;
  }
}

@media screen and (max-width: 767px) {
  .p-index_kv_ttl_inner_ja strong {
    height: 24px;
    margin-top: 5px;
    padding: 0 5px;
    font-size: 12px;
    font-size: 1.2rem;
  }
  .p-index_kv_ttl_inner_ja strong:first-child {
    margin-top: 0;
  }
}

.p-index_kv_ttl_inner_ja.bg_blk strong {
  background-color: #000;
  color: #fff;
}

.p-index_kv_ttl_inner .c-arw_blk {
  width: 36px;
  height: 36px;
}

@media screen and (max-width: 767px) {
  .p-index_kv_ttl_inner .c-arw_blk {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin: 0 0 -8px -3px;
  }
}

.p-index_kv_ttl_inner .c-arw_blk img {
  width: 100%;
}

.p-index_catch {
  padding: 60px 0;
  background-color: black;
  color: white;
  text-align: center;
}

.p-index_catch h2 {
  margin-bottom: 60px;
  font-family: aktiv-grotesk, sans-serif;
  font-size: 36px;
  font-size: 3.6rem;
  font-weight: bold;
}

.p-index_catch p {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2;
}

@media screen and (max-width: 767px) {
  .p-index_catch {
    padding: 30px 0;
  }
  .p-index_catch h2 {
    margin-bottom: 30px;
    font-size: 24px;
    font-size: 2.4rem;
  }
  .p-index_catch p {
    margin: 0 auto;
    width: 335px;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.58333;
    text-align: left;
  }
}

.p-index_feature {
  padding-top: 80px;
  padding-bottom: 114px;
}

@media screen and (max-width: 767px) {
  .p-index_feature {
    padding-top: 40px;
    padding-bottom: 100px;
  }
  .p-index_feature .l-cont_m {
    width: 100%;
  }
}

.p-index_feature .slick-slider::after {
  position: absolute;
  z-index: 0;
  bottom: -59px;
  left: 50%;
  width: 160px;
  height: 2px;
  background-color: #000;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  content: "";
}

@media screen and (max-width: 767px) {
  .p-index_feature .slick-slider::after {
    bottom: -31px;
    width: 80px;
    height: 1px;
  }
}

.p-index_feature .slick-list {
  padding: 0 !important;
}

@media screen and (max-width: 767px) {
  .p-index_feature .slick-list {
    padding: 0 50px !important;
  }
}

.p-index_feature .slick-prev,
.p-index_feature .slick-next {
  top: auto;
  bottom: -75px;
  width: 20px;
  height: 18px;
  background-position: left top;
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (max-width: 767px) {
  .p-index_feature .slick-prev,
  .p-index_feature .slick-next {
    bottom: -40px;
    width: 11px;
    height: 10px;
  }
}

.p-index_feature .slick-prev::before,
.p-index_feature .slick-next::before {
  display: none;
}

.p-index_feature .slick-prev {
  right: auto;
  left: 36%;
  background-image: url(../img/ico_arw_prev_blk.svg);
}

@media screen and (max-width: 767px) {
  .p-index_feature .slick-prev {
    left: 31%;
  }
}

.p-index_feature .slick-next {
  right: 36%;
  left: auto;
  background-image: url(../img/ico_arw_next_blk.svg);
}

@media screen and (max-width: 767px) {
  .p-index_feature .slick-next {
    right: 31%;
  }
}

.p-index_feature_cont .bx-wrapper {
  z-index: 0;
  border: none;
  background: transparent;
  box-shadow: none;
  margin-bottom: 0;
  padding-bottom: 70px;
}

@media screen and (max-width: 767px) {
  .p-index_feature_cont .bx-wrapper {
    padding-bottom: 40px;
  }
  .p-index_feature_cont .bx-wrapper .bx-viewport {
    margin: 0 auto;
  }
}

.p-index_feature_cont .bx-wrapper .bx-controls {
  position: relative;
  z-index: 0;
  height: 18px;
}

@media screen and (max-width: 767px) {
  .p-index_feature_cont .bx-wrapper .bx-controls {
    height: 10px;
  }
}

.p-index_feature_cont .bx-wrapper .bx-controls::after {
  position: absolute;
  z-index: 0;
  bottom: -59px;
  left: 50%;
  width: 160px;
  height: 2px;
  background-color: #000;
  transform: translateX(-50%);
  content: "";
}

@media screen and (max-width: 767px) {
  .p-index_feature_cont .bx-wrapper .bx-controls::after {
    bottom: -27px;
    width: 80px;
    height: 1px;
  }
}

.p-index_feature_cont .bx-wrapper .bx-controls-direction {
  height: 18px;
}

@media screen and (max-width: 767px) {
  .p-index_feature_cont .bx-wrapper .bx-controls-direction {
    height: 10px;
  }
}

.p-index_feature_cont .bx-wrapper .bx-controls-direction a {
  display: block;
  z-index: 0;
  top: 0;
  width: 20px;
  height: 18px;
  margin-top: 67px;
  background-position: left top;
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (max-width: 767px) {
  .p-index_feature_cont .bx-wrapper .bx-controls-direction a {
    width: 11px;
    height: 10px;
    margin-top: 32px;
  }
}

.p-index_feature_cont .bx-wrapper .bx-controls-direction a:hover {
  opacity: 0.8;
}

.p-index_feature_cont .bx-wrapper .bx-controls-direction a.bx-prev {
  left: 380px;
  background-image: url(../img/ico_arw_prev_blk.svg);
}

@media print, screen and (max-width: 1240px) and (min-width: 768px) {
  .p-index_feature_cont .bx-wrapper .bx-controls-direction a.bx-prev {
    left: 36%;
  }
}

@media screen and (max-width: 767px) {
  .p-index_feature_cont .bx-wrapper .bx-controls-direction a.bx-prev {
    left: 31%;
  }
}

.p-index_feature_cont .bx-wrapper .bx-controls-direction a.bx-next {
  right: 380px;
  background-image: url(../img/ico_arw_next_blk.svg);
}

@media print, screen and (max-width: 1240px) and (min-width: 768px) {
  .p-index_feature_cont .bx-wrapper .bx-controls-direction a.bx-next {
    right: 36%;
  }
}

@media screen and (max-width: 767px) {
  .p-index_feature_cont .bx-wrapper .bx-controls-direction a.bx-next {
    right: 31%;
  }
}

.p-index_feature_slider li {
  margin: 0 20px;
}

@media screen and (max-width: 767px) {
  .p-index_feature_slider li {
    margin: 0 20px;
  }
}

.p-index_feature_slider li a {
  display: block;
  padding: 20px 0;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  transition: 0.4s;
}

@media screen and (max-width: 767px) {
  .p-index_feature_slider li a {
    padding: 15px 0;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
  }
}

.p-index_feature_slider li a:hover img,
.p-index_feature_slider li a:hover .p-index_feature_inner {
  opacity: 0.7;
}

.p-index_feature_img {
  position: relative;
  z-index: 0;
  margin-bottom: 15px;
}

.p-index_feature_img img {
  width: 100%;
}

.p-index_feature_img .c-arw_blk {
  position: absolute;
  z-index: 0;
  right: 0;
  bottom: 0;
}

.p-index_feature_category {
  margin-bottom: 10px;
  font-family: aktiv-grotesk, sans-serif;
  font-size: 12px;
  font-size: 1.2rem;
  letter-spacing: 0.02em;
}

@media screen and (max-width: 767px) {
  .p-index_feature_category {
    margin-bottom: 6px;
    font-size: 10px;
    font-size: 1rem;
  }
}

.p-index_feature_ttl {
  margin-bottom: 10px;
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 700;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .p-index_feature_ttl {
    margin-bottom: 15px;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.p-index_feature_txt {
  height: 56px;
}

@media screen and (max-width: 767px) {
  .p-index_feature_txt {
    height: 48px;
  }
}

.p-index_scene {
  padding-bottom: 120px;
}

@media screen and (max-width: 767px) {
  .p-index_scene {
    padding-bottom: 80px;
  }
}

.p-index_scene_list {
  display: flex;
  flex-wrap: wrap;
}

.p-index_scene_list li {
  width: 25%;
  height: 420px;
}

@media screen and (max-width: 767px) {
  .p-index_scene_list li {
    width: 50%;
    height: 210px;
  }
}

.p-index_scene_list li.list_01 a::before {
  background-image: url(../img/index/blt_scene_01_pc.jpg);
}

@media screen and (max-width: 767px) {
  .p-index_scene_list li.list_01 a::before {
    background-image: url(../img/index/blt_scene_01_sp.jpg);
  }
}

.p-index_scene_list li.list_02 a::before {
  background-image: url(../img/index/blt_scene_02_pc.jpg);
}

@media screen and (max-width: 767px) {
  .p-index_scene_list li.list_02 a::before {
    background-image: url(../img/index/blt_scene_02_sp.jpg);
  }
}

.p-index_scene_list li.list_03 a::before {
  background-image: url(../img/index/blt_scene_03_pc.jpg);
}

@media screen and (max-width: 767px) {
  .p-index_scene_list li.list_03 a::before {
    background-image: url(../img/index/blt_scene_03_sp.jpg);
  }
}

.p-index_scene_list li.list_04 a::before {
  background-image: url(../img/index/blt_scene_04_pc.jpg);
}

@media screen and (max-width: 767px) {
  .p-index_scene_list li.list_04 a::before {
    background-image: url(../img/index/blt_scene_04_sp.jpg);
  }
}

.p-index_scene_list li.list_05 a::before {
  background-image: url(../img/index/blt_scene_05_pc.jpg);
}

@media screen and (max-width: 767px) {
  .p-index_scene_list li.list_05 a::before {
    background-image: url(../img/index/blt_scene_05_sp.jpg);
  }
}

.p-index_scene_list li.list_06 a::before {
  background-image: url(../img/index/blt_scene_06_pc.jpg);
}

@media screen and (max-width: 767px) {
  .p-index_scene_list li.list_06 a::before {
    background-image: url(../img/index/blt_scene_06_sp.jpg);
  }
}

.p-index_scene_list li.list_07 a::before {
  background-image: url(../img/index/blt_scene_07_pc.jpg);
}

@media screen and (max-width: 767px) {
  .p-index_scene_list li.list_07 a::before {
    background-image: url(../img/index/blt_scene_07_sp.jpg);
  }
}

.p-index_scene_list li.list_08 a::before {
  background-image: url(../img/index/blt_scene_08_pc.jpg);
}

@media screen and (max-width: 767px) {
  .p-index_scene_list li.list_08 a::before {
    background-image: url(../img/index/blt_scene_08_sp.jpg);
  }
}

.p-index_scene_list li a {
  display: block;
  position: relative;
  z-index: 0;
  height: 100%;
  transition: 0.4s;
}

.p-index_scene_list li a::before {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  content: "";
}

.p-index_scene_list li a:hover::before,
.p-index_scene_list li a:hover .p-index_scene_list_cont {
  opacity: 0.7;
}

.p-index_scene_list li a .c-arw_wh {
  position: absolute;
  z-index: 0;
  right: 0;
  bottom: 0;
}

.p-index_scene_list_cont {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  padding: 0 30px 25px 30px;
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 700;
}

@media screen and (max-width: 767px) {
  .p-index_scene_list_cont {
    padding: 0 5% 20px 5%;
  }
}

.p-index_scene_list_ttl {
  font-size: 24px;
  font-size: 2.4rem;
}

.p-index_scene_list_txt {
  line-height: 1.4;
  font-size: 10px;
  font-size: 1rem;
}

.p-index_series {
  padding-bottom: 120px;
}

@media screen and (max-width: 767px) {
  .p-index_series {
    padding-bottom: 80px;
  }
  .p-index_series .l-cont_l {
    width: 100%;
  }
}

@media print, screen and (min-width: 768px) {
  .p-index_series_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.p-index_series_list.oak {
  background-color: #909986;
}

.p-index_series_list.moss {
  background-color: #084C3D;
}

.p-index_series_list.moss .p-index_series_list_caption {
  color: #fff;
}

.p-index_series_list li {
  padding-bottom: 40px;
}

@media print, screen and (min-width: 768px) {
  .p-index_series_list li {
    width: 49.9%;
  }
}

@media screen and (max-width: 767px) {
  .p-index_series_list li {
    padding-bottom: 25px;
  }
}

.p-index_series_list li.oak_01 .p-index_series_list_bg::before {
  background-image: url(../img/series/bg_series_01.png);
}

.p-index_series_list li.oak_02 .p-index_series_list_bg::before {
  background-image: url(../img/series/bg_series_02.png);
}

.p-index_series_list li.oak_03 .p-index_series_list_bg::before {
  background-image: url(../img/series/bg_series_03.png);
}

.p-index_series_list li.oak_04 .p-index_series_list_bg::before {
  background-image: url(../img/series/bg_series_04.png);
}

.p-index_series_list li.oak_05 .p-index_series_list_bg::before {
  background-image: url(../img/series/bg_series_05.png);
}

.p-index_series_list li.oak_06 .p-index_series_list_bg::before {
  background-image: url(../img/series/bg_series_06.png);
}

.p-index_series_list li.moss_01 .p-index_series_list_bg::before {
  background-image: url(../img/series/logo_proshop.png);
  background-position: 90% 32px;
  background-size: 140px 176px;
}

@media screen and (max-width: 767px) {
  .p-index_series_list li.moss_01 .p-index_series_list_bg::before {
    background-position: 90% 15px;
    background-size: 70px auto;
  }
}

.p-index_series_list li.moss_01 .p-index_series_list_cont {
  color: #fff;
}

@media print, screen and (min-width: 768px) {
  .p-index_series_list li.moss_01 .p-index_series_list_cont {
    padding: 0 0 35px 50px;
  }
}

.p-index_series_list li.moss_02 .p-index_series_list_bg::before {
  background-image: url(../img/series/bg_series_07.png);
}

.p-index_series_list li.moss_03 .p-index_series_list_bg::before {
  background-image: url(../img/series/bg_series_08.png);
}

.p-index_series_list li.moss_04 .p-index_series_list_bg::before {
  background-image: url(../img/series/bg_series_09.png);
}

.p-index_series_list a:hover .p-index_series_list_bg::before,
.p-index_series_list a:hover .p-index_series_list_cont {
  opacity: 0.7;
}

.p-index_series_list a:hover .c-arw_blk img {
  display: block;
}

.p-index_series_list_bg {
  position: relative;
  z-index: 0;
  height: 240px;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .p-index_series_list_bg {
    height: 120px;
    margin-bottom: 10px;
  }
}

.p-index_series_list_bg::before {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  content: "";
}

.p-index_series_list_bg .c-arw_blk {
  position: absolute;
  z-index: 0;
  right: 0;
  bottom: 0;
}

.p-index_series_list_cont {
  position: absolute;
  z-index: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 0 35px 30px;
  font-family: aktiv-grotesk, sans-serif;
}

@media screen and (max-width: 767px) {
  .p-index_series_list_cont {
    padding: 0 0 15px 5%;
  }
}

.p-index_series_list_ttl {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 767px) {
  .p-index_series_list_ttl {
    margin-bottom: 3px;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.p-index_series_list_txt {
  line-height: 1.4;
  font-size: 10px;
  font-size: 1rem;
}

@media screen and (max-width: 767px) {
  .p-index_series_list_txt {
    line-height: 1.2;
  }
}

.p-index_series_list_caption {
  padding-left: 30px;
}

@media screen and (max-width: 767px) {
  .p-index_series_list_caption {
    padding-left: 5%;
  }
}

.p-index_series_list_link {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 0;
  width: 210px;
  padding: 9px 0 9px 15px;
  background-color: #084C3D;
}

@media screen and (max-width: 767px) {
  .p-index_series_list_link {
    width: 45%;
    padding: 6px 0 7px 11px;
  }
  .p-index_series_list_link img {
    width: 12px;
  }
}

.p-index_series_list_link span {
  display: block;
  line-height: 1;
  margin-right: 20px;
  color: #fff;
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 700;
}

@media screen and (max-width: 767px) {
  .p-index_series_list_link span {
    font-size: 10px;
    font-size: 1rem;
  }
}

.p-index_items {
  padding-bottom: 65px;
}

.p-index_items .all-products,
.p-index_items .how-to-choose {
  margin: 0 auto;
  width: 640px;
  transition: 0.4s;
}

.p-index_items .all-products:hover,
.p-index_items .how-to-choose:hover {
  opacity: 0.7;
}

.p-index_items .all-products {
  margin-bottom: 60px;
}

.p-index_items .how-to-choose {
  margin: rem(60) auto 0 auto;
  border: 2px solid #000;
}

.p-index_items .how-to-choose a {
  background-color: transparent;
  color: black;
  background-image: url(../img/ico_arw_next_blk.svg);
}

@media screen and (max-width: 767px) {
  .p-index_items {
    padding-bottom: 50px;
  }
  .p-index_items .all-products,
  .p-index_items .how-to-choose {
    width: 100%;
  }
  .p-index_items .all-products {
    margin-bottom: 30px;
  }
}

.p-index_styling {
  padding-bottom: 106px;
}

@media screen and (max-width: 767px) {
  .p-index_styling {
    padding-bottom: 80px;
  }
}

.p-index_lifestyle {
  padding-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .p-index_lifestyle {
    padding-bottom: 80px;
  }
}

.p-index_lifestyle_list li {
  margin-bottom: 100px;
}

@media print, screen and (min-width: 768px) {
  .p-index_lifestyle_list li {
    display: flex;
    position: relative;
    z-index: 0;
    height: 480px;
    overflow: hidden;
  }
  .p-index_lifestyle_list li:nth-child(odd) .p-index_lifestyle_list_img {
    left: 0;
  }
  .p-index_lifestyle_list li:nth-child(odd) .p-index_lifestyle_list_cont {
    margin-left: auto;
    padding-left: 40px;
  }
  .p-index_lifestyle_list li:nth-child(odd) .p-index_lifestyle_list_cont .c-btn_arw {
    left: 40px;
  }
  .p-index_lifestyle_list li:nth-child(even) {
    flex-direction: row-reverse;
  }
  .p-index_lifestyle_list li:nth-child(even) .p-index_lifestyle_list_img {
    right: 0;
  }
  .p-index_lifestyle_list li:nth-child(even) .p-index_lifestyle_list_cont {
    padding-right: 40px;
  }
  .p-index_lifestyle_list li:nth-child(even) .p-index_lifestyle_list_cont .c-btn_arw {
    left: 0;
  }
}

@media screen and (max-width: 767px) {
  .p-index_lifestyle_list li {
    margin-bottom: 40px;
  }
}

.p-index_lifestyle_list li:last-child {
  margin-bottom: 0;
}

.p-index_lifestyle_list_img {
  display: block;
  width: 50%;
}

@media print, screen and (min-width: 768px) {
  .p-index_lifestyle_list_img {
    position: absolute;
    z-index: 0;
    top: 0;
  }
}

@media screen and (max-width: 767px) {
  .p-index_lifestyle_list_img {
    width: 100%;
    margin-bottom: 15px;
  }
}

.p-index_lifestyle_list_img img {
  width: 100%;
}

@media print, screen and (min-width: 768px) {
  .p-index_lifestyle_list_cont {
    position: relative;
    z-index: 0;
    width: 50%;
    height: 100%;
  }
}

.p-index_lifestyle_list_cont .c-btn_arw {
  width: 320px;
}

@media print, screen and (min-width: 768px) {
  .p-index_lifestyle_list_cont .c-btn_arw {
    position: absolute;
    z-index: 0;
    bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .p-index_lifestyle_list_cont .c-btn_arw {
    width: 75%;
    margin: 0 auto;
  }
}

.p-index_lifestyle_list_head {
  display: block;
  margin-bottom: 10px;
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 700;
}

@media screen and (max-width: 767px) {
  .p-index_lifestyle_list_head {
    margin-bottom: 5px;
  }
}

.p-index_lifestyle_list_name {
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 24px;
  font-size: 2.4rem;
}

@media screen and (max-width: 767px) {
  .p-index_lifestyle_list_name {
    margin-bottom: 20px;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.p-index_lifestyle_list_ttl {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 767px) {
  .p-index_lifestyle_list_ttl {
    line-height: 1.58;
    margin-bottom: 15px;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 767px) {
  .p-index_lifestyle_list_txt {
    line-height: 1.58;
    margin-bottom: 30px;
  }
}

.c-items .c-btn_arw {
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  .c-items .c-btn_arw {
    margin-bottom: 15px;
  }
}

.series.all-products-series .c-items li {
  margin-bottom: 0;
}

.c-items_detail {
  height: 160px;
}

@media screen and (max-width: 767px) {
  .c-items_detail {
    height: 120px;
  }
}

.all-products-top .l-cont_l {
  padding: 40px 0 0 0;
}

@media screen and (max-width: 767px) {
  .all-products-top .l-cont_l {
    padding: 30px 0 0 0;
  }
}

.all-products-bottom {
  padding-top: 120px;
}

@media screen and (max-width: 767px) {
  .all-products-bottom {
    padding-top: 35px;
  }
}

.all-products-top,
.all-products-bottom {
  background-color: white;
}

.all-products-top .l-cont_l,
.all-products-bottom .l-cont_l {
  text-align: center;
  background-color: white;
}

@media screen and (max-width: 767px) {
  .all-products-top .l-cont_l,
  .all-products-bottom .l-cont_l {
    padding: 30px 0 35px 0;
  }
}

.all-products-top h2,
.all-products-bottom h2 {
  font-size: 64px;
  font-size: 6.4rem;
  font-weight: bold;
  font-family: aktiv-grotesk, sans-serif;
  line-height: 1;
}

@media screen and (max-width: 767px) {
  .all-products-top h2,
  .all-products-bottom h2 {
    font-size: 32px;
    font-size: 3.2rem;
  }
}

.all-products-top #all-products-nav-sp,
.all-products-bottom #all-products-nav-sp {
  position: relative;
  margin: 0 auto;
  transition: 0.4s ease;
  z-index: 1;
  width: 90%;
  background-color: white;
}

.all-products-top #all-products-nav-sp::after,
.all-products-bottom #all-products-nav-sp::after {
  content: "";
  background-image: url(../img/ico_arw_right_wh.svg);
  position: absolute;
  top: 3px;
  right: 20px;
  height: 24.848101265822784px;
  width: 27px;
  transform: rotate(90deg);
  z-index: 1;
}

.all-products-top #all-products-nav-sp select,
.all-products-bottom #all-products-nav-sp select {
  padding-left: 15px;
  height: 36px;
  width: 100%;
  border: 1px solid black;
  box-sizing: border-box;
  background-color: white;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  font-size: 12px;
  font-size: 1.2rem;
  font-family: aktiv-grotesk, sans-serif;
  color: black;
}

.all-products-top #all-products-nav-sp select option,
.all-products-bottom #all-products-nav-sp select option {
  color: black;
}

.all-products-top nav,
.all-products-bottom nav {
  height: 54px;
  border: 1px solid black;
  z-index: 1;
  width: 90%;
  margin: 0 auto;
  background-color: white;
  transition: 0.4s ease, 0s visibility;
}

.all-products-top nav.flat-nav,
.all-products-bottom nav.flat-nav {
  position: relative;
  opacity: 0;
  pointer-events: none;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  max-width: initial;
  width: calc(100% + 1px);
  background-color: black;
  transition: 0.4s ease 0.1s;
}

.all-products-top nav.flat-nav.active,
.all-products-bottom nav.flat-nav.active {
  opacity: 1;
  pointer-events: initial;
}

.all-products-top nav.flat-nav li,
.all-products-bottom nav.flat-nav li {
  margin-right: 0;
  padding-right: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.all-products-top nav.flat-nav li *,
.all-products-bottom nav.flat-nav li * {
  color: white;
}

.all-products-top nav.flat-nav li:nth-child(1),
.all-products-bottom nav.flat-nav li:nth-child(1) {
  font-size: 18px;
  font-weight: bold;
}

@media print, screen and (min-width: 768px) {
  .all-products-top nav,
  .all-products-bottom nav {
    max-width: 1120px;
  }
}

@media print, screen and (max-width: 1240px) and (min-width: 768px) {
  .all-products-top nav,
  .all-products-bottom nav {
    width: 95%;
    max-width: 95%;
  }
}

.all-products-top nav ul,
.all-products-bottom nav ul {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .all-products-top nav ul,
  .all-products-bottom nav ul {
    display: block;
    transition: 0.4s ease;
  }
}

.all-products-top nav ul li,
.all-products-bottom nav ul li {
  margin-right: 30px;
  font-size: 14px;
  font-size: 1.4rem;
  font-family: aktiv-grotesk, sans-serif;
  cursor: pointer;
  overflow: hidden;
}

.all-products-top nav ul li#flat-li,
.all-products-bottom nav ul li#flat-li {
  border-left: 30px solid white;
}

.all-products-top nav ul li.open-nav,
.all-products-bottom nav ul li.open-nav {
  margin-right: 0;
  padding-right: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .all-products-top nav ul li,
  .all-products-bottom nav ul li {
    padding-left: 15px;
    height: 36px;
    line-height: 36px;
    margin-right: 0;
    width: 100%;
    box-sizing: border-box;
  }
  .all-products-top nav ul li:last-child,
  .all-products-bottom nav ul li:last-child {
    margin-bottom: 0;
  }
}

.all-products-top nav ul li:hover span::after,
.all-products-bottom nav ul li:hover span::after {
  transform: translate3d(0, 0, 0);
}

.all-products-top nav ul li span,
.all-products-bottom nav ul li span {
  position: relative;
}

@media screen and (max-width: 767px) {
  .all-products-top nav ul li span,
  .all-products-bottom nav ul li span {
    display: inline-block;
    overflow: hidden;
  }
}

.all-products-top nav ul li span::after,
.all-products-bottom nav ul li span::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  height: 1px;
  width: 100%;
  background-color: black;
  transform: translate3d(-100%, 0, 0);
  transition: 0.4s ease;
}

@media screen and (max-width: 767px) {
  .all-products-top nav ul li span::after,
  .all-products-bottom nav ul li span::after {
    display: none;
  }
}

.all-products-bottom {
  position: sticky;
  top: 80px;
  z-index: 10;
}

@media screen and (max-width: 767px) {
  .all-products-bottom {
    top: 90px;
  }
}

.p-list_kv {
  position: relative;
  z-index: 0;
  height: 320px;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .p-list_kv {
    height: 310px;
  }
}

.p-list_kv .l-cont_l {
  position: relative;
  z-index: 0;
  height: 100%;
}

.p-list_kv_ttl {
  position: absolute;
  z-index: 0;
  left: 0;
}

@media print, screen and (min-width: 768px) {
  .p-list_kv_ttl {
    bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  .p-list_kv_ttl {
    top: 15px;
    left: 5%;
  }
}

.p-list_kv_ttl_main {
  display: flex;
  align-items: flex-start;
}

.p-list_kv_ttl_top {
  display: block;
  line-height: 1;
  margin-bottom: 3px;
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 700;
  font-size: 96px;
  font-size: 9.6rem;
}

@media screen and (max-width: 767px) {
  .p-list_kv_ttl_top {
    font-size: 48px;
    font-size: 4.8rem;
  }
}

.p-list_kv_ttl .c-kv_ttl_icon {
  margin: 15px 0 0 30px;
}

@media screen and (max-width: 767px) {
  .p-list_kv_ttl .c-kv_ttl_icon {
    margin: 5px 0 0 10px;
  }
}

.p-list_kv_bg {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  height: 320px;
}

@media screen and (max-width: 767px) {
  .p-list_kv_bg {
    height: 310px;
  }
}

.p-list_kv_bg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  font-family: "object-fit: contain;";
}

.p-list_items {
  padding: 60px 0 20px;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .p-list_items {
    padding: 35px 0 10px;
  }
}

.p-list_link {
  margin-bottom: -45px;
}

@media print, screen and (min-width: 768px) {
  .p-list_link {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

@media screen and (max-width: 767px) {
  .p-list_link {
    margin-bottom: -25px;
  }
}

.p-list_link li {
  position: relative;
  z-index: 0;
  padding-bottom: 45px;
}

@media print, screen and (min-width: 768px) {
  .p-list_link li {
    width: 49.9%;
    margin-right: 0.2%;
  }
  .p-list_link li:nth-child(2n) {
    margin-right: 0;
  }
}

@media screen and (max-width: 767px) {
  .p-list_link li {
    padding-bottom: 25px;
  }
}

.p-list_link li.is-page .p-list_link_head::before {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #909986;
  opacity: 0.6;
  content: "";
}

.p-list_link li a {
  display: block;
}

.p-list_link li a:hover .c-arw_blk img {
  display: block;
}

.p-list_link_head {
  position: relative;
  z-index: 0;
  height: 240px;
}

@media screen and (max-width: 767px) {
  .p-list_link_head {
    height: 120px;
  }
}

.p-list_link_head .c-arw_blk {
  position: absolute;
  z-index: 0;
  right: 0;
  bottom: 0;
}

.p-list_link_bg {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  width: 100%;
  height: 240px;
}

@media screen and (max-width: 767px) {
  .p-list_link_bg {
    height: 120px;
  }
}

.p-list_link_bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 100% 100%;
  font-family: "object-fit: cover; object-position: 100% 100%;";
}

.p-list_link_ttl {
  position: absolute;
  z-index: 0;
  bottom: 10px;
  left: 40px;
  font-family: aktiv-grotesk, sans-serif;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .p-list_link_ttl {
    bottom: 5px;
    left: 5%;
  }
}

.p-list_link_ttl span {
  line-height: 1;
  font-weight: 700;
}

.p-list_link_ttl_category {
  display: inline-block;
  padding-bottom: 5px;
  font-size: 14px;
  font-size: 1.4rem;
  border-bottom: 2px solid #000;
}

@media screen and (max-width: 767px) {
  .p-list_link_ttl_category {
    margin-bottom: 5px;
    border-bottom: 1px solid #000;
    font-size: 10px;
    font-size: 1rem;
  }
}

.p-list_link_ttl_number {
  display: block;
  font-size: 96px;
  font-size: 9.6rem;
}

@media screen and (max-width: 767px) {
  .p-list_link_ttl_number {
    font-size: 48px;
    font-size: 4.8rem;
  }
}

.p-list_link_txt {
  padding: 35px 40px 0;
}

@media screen and (max-width: 767px) {
  .p-list_link_txt {
    line-height: 1.58;
    padding: 15px 5% 0;
  }
}

.p-listDetail_kv {
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .p-listDetail_kv {
    position: relative;
    z-index: 0;
  }
}

@media print, screen and (min-width: 768px) {
  .p-listDetail_kv .l-cont_l {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

@media screen and (max-width: 767px) {
  .p-listDetail_kv .l-cont_l {
    width: 100%;
  }
}

@media print, screen and (min-width: 768px) {
  .p-listDetail_kv_img {
    width: 68%;
    max-width: 760px;
    margin-top: -44px;
  }
}

@media screen and (max-width: 767px) {
  .p-listDetail_kv_img {
    padding-top: 150px;
  }
}

.p-listDetail_kv_img img {
  width: 100%;
}

.p-listDetail_kv_img_slider {
  margin-bottom: 6px;
}

@media screen and (max-width: 767px) {
  .p-listDetail_kv_img_slider {
    margin-bottom: 30px;
  }
}

.p-listDetail_kv_img_slider li {
  position: relative;
  z-index: 0;
  width: 760px;
  height: 580px;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .p-listDetail_kv_img_slider li {
    width: 100%;
    height: 290px;
  }
}

.p-listDetail_kv_img_slider li img {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.p-listDetail_kv_img_thumb {
  z-index: 0;
}

@media print, screen and (min-width: 768px) {
  .p-listDetail_kv_img_thumb {
    padding: 0 70px;
  }
}

@media screen and (max-width: 767px) {
  .p-listDetail_kv_img_thumb {
    width: 100%;
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  .p-listDetail_kv_img_thumb::after {
    position: absolute;
    z-index: 0;
    bottom: 10px;
    left: 50%;
    width: 80px;
    height: 1px;
    background-color: #000;
    transform: translateX(-50%);
    content: "";
  }
}

.p-listDetail_kv_img_thumb li {
  position: relative;
  z-index: 0;
  width: 120px;
  height: 69px;
  margin: 0 0 0 5px;
  overflow: hidden;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .p-listDetail_kv_img_thumb li {
    width: 90px;
    margin: 0 0 0 5px;
  }
}

.p-listDetail_kv_img_thumb li img {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.p-listDetail_kv_img_thumb li.slick-current {
  border: 2px solid #3f3f3f;
  box-sizing: border-box;
}

.p-listDetail_kv_img_thumb .slick-arrow {
  position: absolute;
  z-index: 1;
  width: 20px;
  height: 18px;
  background-position: left top;
  background-size: contain;
  background-repeat: no-repeat;
  transform: translateY(-50%);
  cursor: pointer;
}

@media print, screen and (min-width: 768px) {
  .p-listDetail_kv_img_thumb .slick-arrow {
    top: 55%;
  }
}

@media screen and (max-width: 767px) {
  .p-listDetail_kv_img_thumb .slick-arrow {
    bottom: 0;
    width: 11px;
    height: 10px;
  }
}

.p-listDetail_kv_img_thumb .slick-arrow:hover {
  opacity: .8;
}

.p-listDetail_kv_img_thumb .slick-arrow.img_prev {
  left: 18px;
  background-image: url(../img/ico_arw_prev_blk.svg);
}

@media screen and (max-width: 767px) {
  .p-listDetail_kv_img_thumb .slick-arrow.img_prev {
    left: 30%;
  }
}

.p-listDetail_kv_img_thumb .slick-arrow.img_next {
  right: 18px;
  background-image: url(../img/ico_arw_next_blk.svg);
}

@media screen and (max-width: 767px) {
  .p-listDetail_kv_img_thumb .slick-arrow.img_next {
    right: 30%;
  }
}

.p-listDetail_kv_cont {
  padding-top: 40px;
}

@media print, screen and (min-width: 768px) {
  .p-listDetail_kv_cont {
    width: 29%;
    max-width: 320px;
    margin-right: 40px;
  }
}

@media screen and (max-width: 767px) {
  .p-listDetail_kv_cont {
    width: 90%;
    margin: 0 auto;
    padding-top: 35px;
  }
}

@media print, screen and (min-width: 768px) {
  .p-listDetail_kv_ttl {
    margin-bottom: 45px;
  }
}

@media screen and (max-width: 767px) {
  .p-listDetail_kv_ttl {
    position: absolute;
    z-index: 0;
    top: 40px;
    left: 5%;
  }
}

.p-listDetail_kv_ttl .c-kv_ttl_head {
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .p-listDetail_kv_ttl .c-kv_ttl_head {
    margin-bottom: 3px;
  }
}

.p-listDetail_kv_ttl_main {
  display: flex;
  align-items: flex-start;
}

.p-listDetail_kv_ttl_main .c-kv_ttl_icon {
  margin: 18px 0 0 15px;
}

@media screen and (max-width: 767px) {
  .p-listDetail_kv_ttl_main .c-kv_ttl_icon {
    margin: 5px 0 0 8px;
  }
}

.p-listDetail_kv_product, .p-listDetail_kv_price {
  font-family: aktiv-grotesk, sans-serif;
  line-height: 1.8;
}

@media screen and (max-width: 767px) {
  .p-listDetail_kv_product, .p-listDetail_kv_price {
    width: 90%;
  }
}

.p-listDetail_kv_product dt, .p-listDetail_kv_price dt {
  width: 30%;
  padding-top: 8px;
  font-size: 10px;
  font-size: 1rem;
}

@media screen and (max-width: 767px) {
  .p-listDetail_kv_product dt, .p-listDetail_kv_price dt {
    padding-top: 6px;
  }
}

.p-listDetail_kv_product dd, .p-listDetail_kv_price dd {
  width: 70%;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 767px) {
  .p-listDetail_kv_product dd, .p-listDetail_kv_price dd {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.p-listDetail_kv_product {
  padding-bottom: 20px;
  border-bottom: 2px solid #000;
}

@media print, screen and (min-width: 768px) {
  .p-listDetail_kv_product {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 767px) {
  .p-listDetail_kv_product {
    margin: 0 auto 10px;
    padding-bottom: 15px;
  }
}

.p-listDetail_kv_product:last-child {
  margin-bottom: 30px;
  padding-bottom: 0;
  border-bottom: 0;
}

.p-listDetail_kv_product_cont {
  display: flex;
}

.p-listDetail_kv_price {
  display: flex;
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  .p-listDetail_kv_price {
    margin: 0 auto 35px;
  }
}

.p-listDetail_kv_price_number {
  line-height: 1.6;
  font-weight: 700;
  font-family: aktiv-grotesk, sans-serif;
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 767px) {
  .p-listDetail_kv_price_number {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.p-listDetail_kv_price_tax {
  font-family: aktiv-grotesk, sans-serif;
}

@media print, screen and (min-width: 768px) {
  .p-listDetail_kv_price_tax {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .p-listDetail_kv_price_tax {
    font-weight: 500;
    font-size: 10px;
    font-size: 1rem;
    font-feature-settings: "palt";
  }
}

.p-listDetail_kv_price_txt {
  font-family: ryo-gothic-plusn, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", YuGothic, "游ゴシック", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS P Gothic", Helvetica, Verdana, sans-serif;
  font-weight: 500;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 767px) {
  .p-listDetail_kv_price_txt {
    font-size: 11px;
    font-size: 1.1rem;
    font-feature-settings: "palt";
  }
}

.p-listDetail_top {
  padding: 80px 0;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .p-listDetail_top {
    padding: 40px 0;
  }
}

.p-listDetail_consult {
  padding: 15px 0 10px;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .p-listDetail_consult {
    padding: 20px 0;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
  }
}

.p-shop_kv_ttl {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .p-shop_kv_ttl {
    padding-top: 15px;
    margin-bottom: 5px;
  }
}

.p-shop_kv_ttl img {
  width: 27px;
  margin-right: 20px;
}

@media screen and (max-width: 767px) {
  .p-shop_kv_ttl img {
    width: 13px;
    margin-right: 10px;
  }
}

.p-shop_kv_ttl_en {
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 700;
  font-size: 36px;
  font-size: 3.6rem;
}

@media screen and (max-width: 767px) {
  .p-shop_kv_ttl_en {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.p-shop_kv_cont {
  padding: 15px 0 15px;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
}

@media screen and (max-width: 767px) {
  .p-shop_kv_cont {
    padding: 20px 0;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
  }
}

.p-shop_kv_txt {
  margin-bottom: 15px;
}

@media print, screen and (min-width: 768px) {
  .p-shop_kv_txt {
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .p-shop_kv_txt {
    line-height: 1.58;
    margin-bottom: 20px;
  }
}

.p-shop_kv .c-shop_hcWrap {
  display: flex;
  justify-content: center;
}

.p-shop_kv .c-shop_hc_icon {
  margin-right: 10px;
}

.p-shop_kv .c-shop_hc_cont:last-child {
  margin-bottom: 0;
}

.p-shop_top {
  padding: 80px 0;
}

@media screen and (max-width: 767px) {
  .p-shop_top {
    padding: 40px 0 80px;
  }
}

@media screen and (max-width: 767px) {
  .p-shop_top .c-shop_btn .c-btn_arw {
    width: 70%;
    margin: 0 auto;
  }
  .p-shop_top .c-shop_btn .c-btn_arw a {
    padding: 13px 0;
  }
}

.p-shop_area {
  text-align: center;
}

@media print, screen and (min-width: 768px) {
  .p-shop_area {
    position: relative;
    z-index: 0;
  }
}

@media screen and (max-width: 767px) {
  .p-shop_area_head {
    position: relative;
    z-index: 0;
    padding: 12px 0;
    background-color: #000;
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    font-size: 1.2rem;
    transition: .4s;
  }
  .p-shop_area_head::before {
    position: absolute;
    z-index: 0;
    top: 40%;
    right: 5%;
    width: 11px;
    height: 12px;
    background-image: url(../img/ico_arw_next_wh.svg);
    background-position: top left;
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(90deg);
    content: "";
  }
}

.p-shop_area_list {
  background-color: #000;
  color: #fff;
}

@media print, screen and (min-width: 768px) {
  .p-shop_area_list {
    display: flex;
    justify-content: center;
    height: 80px;
  }
}

@media screen and (max-width: 767px) {
  .p-shop_area_list {
    display: none;
  }
}

.p-shop_area_list li:hover {
  cursor: pointer;
}

.p-shop_area_toggle {
  transition: .4s;
}

@media print, screen and (min-width: 768px) {
  .p-shop_area_toggle {
    width: 92px;
    padding: 26px 0;
  }
  .p-shop_area_toggle:hover {
    background-color: #fff;
    color: #000;
  }
}

@media screen and (max-width: 767px) {
  .p-shop_area_toggle {
    padding: 10px 0;
  }
}

.p-shop_area_toggle.is-area {
  background-color: #fff;
  color: #000;
}

.p-shop_area_pref {
  background-color: #fff;
}

@media print, screen and (min-width: 768px) {
  .p-shop_area_pref {
    position: absolute;
    z-index: 98;
    top: 80px;
    left: 0;
    width: 100%;
    height: 60px;
    transform: scaleY(0);
    transform-origin: top left;
    transition: .4s;
    pointer-events: none;
  }
  .p-shop_area_pref.is-toggle {
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
    pointer-events: auto;
  }
}

@media screen and (max-width: 767px) {
  .p-shop_area_pref {
    display: none;
  }
}

@media print, screen and (min-width: 768px) {
  .p-shop_area_pref_inner {
    position: relative;
    z-index: 0;
    max-width: 1120px;
    margin: 0 auto;
  }
}

@media print, screen and (min-width: 768px) {
  .p-shop_area_pref_inner.tohoku {
    left: 101px;
  }
}

@media print, screen and (max-width: 1240px) and (min-width: 768px) {
  .p-shop_area_pref_inner.tohoku {
    left: 80px;
  }
}

@media print, screen and (min-width: 768px) {
  .p-shop_area_pref_inner.hokuriku {
    left: 193px;
  }
}

@media print, screen and (max-width: 1240px) and (min-width: 768px) {
  .p-shop_area_pref_inner.hokuriku {
    left: 172px;
  }
}

@media print, screen and (min-width: 768px) {
  .p-shop_area_pref_inner.kanto {
    left: 193px;
  }
}

@media print, screen and (max-width: 1240px) and (min-width: 768px) {
  .p-shop_area_pref_inner.kanto {
    left: 80px;
  }
}

@media print, screen and (min-width: 768px) {
  .p-shop_area_pref_inner.shinetsu {
    left: 468px;
  }
}

@media print, screen and (max-width: 1240px) and (min-width: 768px) {
  .p-shop_area_pref_inner.shinetsu {
    left: 448px;
  }
}

@media print, screen and (min-width: 768px) {
  .p-shop_area_pref_inner.tokai {
    left: 468px;
  }
}

@media print, screen and (max-width: 1240px) and (min-width: 768px) {
  .p-shop_area_pref_inner.tokai {
    left: 448px;
  }
}

@media print, screen and (min-width: 768px) {
  .p-shop_area_pref_inner.chugoku {
    left: 468px;
  }
}

@media print, screen and (max-width: 1240px) and (min-width: 768px) {
  .p-shop_area_pref_inner.chugoku {
    left: 448px;
  }
}

@media print, screen and (min-width: 768px) {
  .p-shop_area_pref_inner.kinki {
    left: 470px;
  }
}

@media print, screen and (max-width: 1240px) and (min-width: 768px) {
  .p-shop_area_pref_inner.kinki {
    left: 448px;
  }
}

@media print, screen and (min-width: 768px) {
  .p-shop_area_pref_inner.shikoku {
    left: 650px;
  }
}

@media print, screen and (max-width: 1240px) and (min-width: 768px) {
  .p-shop_area_pref_inner.shikoku {
    left: 539px;
  }
}

@media print, screen and (min-width: 768px) {
  .p-shop_area_pref_inner.kyushu {
    left: 470px;
  }
}

@media print, screen and (max-width: 1240px) and (min-width: 768px) {
  .p-shop_area_pref_inner.kyushu {
    left: 358px;
  }
}

@media print, screen and (min-width: 768px) {
  .p-shop_area_pref_list {
    display: flex;
    position: absolute;
    z-index: 0;
    top: 0;
    width: 100%;
  }
}

.p-shop_area_pref_list li {
  color: #000;
  padding: 16px 0;
  transition: .4s;
}

@media print, screen and (min-width: 768px) {
  .p-shop_area_pref_list li {
    width: 92px;
  }
}

@media screen and (max-width: 767px) {
  .p-shop_area_pref_list li {
    padding: 10px 0;
    border-top: 1px solid #000;
  }
}

.p-shop_area_pref_list li.is-area {
  background-color: #BBC7AE;
}

.p-shop_area_pref_list li:hover {
  background-color: #BBC7AE;
}

.p-shop_list {
  padding: 100px 0;
}

@media screen and (max-width: 767px) {
  .p-shop_list {
    padding: 40px 0 50px;
  }
}

.p-shop_list_cont {
  display: flex;
  flex-wrap: wrap;
}

.p-shop_list_cont li {
  width: 24.2%;
  margin-right: 1%;
  margin-bottom: 80px;
}

@media print, screen and (min-width: 768px) {
  .p-shop_list_cont li:nth-child(4n) {
    margin-right: 0;
  }
}

@media screen and (max-width: 767px) {
  .p-shop_list_cont li {
    width: 47.5%;
    margin-right: 5%;
    margin-bottom: 30px;
  }
  .p-shop_list_cont li:nth-child(2n) {
    margin-right: 0;
  }
}

.p-shop_list_cont li a {
  display: block;
  position: relative;
  z-index: 0;
  transition: .4s;
}

.p-shop_list_cont li a .c-shop_hc {
  position: absolute;
  z-index: 0;
  top: 10px;
  right: 10px;
}

.p-shop_list_cont li a .c-shop_hc_icon {
  margin-bottom: 5px;
}

.p-shop_list_cont li a:hover .p-shop_list_img,
.p-shop_list_cont li a:hover .p-shop_list_inner {
  opacity: .7;
}

.p-shop_list_img {
  display: block;
  margin-bottom: 15px;
  position: relative;
}

.p-shop_list_img::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 73.43%;
  top: 0;
  left: 0;
  right: 0;
}

.p-shop_list_img img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.p-shop_list_ttl {
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .p-shop_list_ttl {
    margin-bottom: 15px;
  }
}

.p-shop_list_ttl span {
  display: block;
  font-family: aktiv-grotesk, ryo-gothic-plusn, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", YuGothic, "游ゴシック", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS P Gothic", Helvetica, Verdana, sans-serif;
}

@media screen and (max-width: 767px) {
  .p-shop_list_ttl span {
    line-height: 1.58;
  }
}

.p-shop_list_ttl_main {
  font-weight: 700;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .p-shop_list_ttl_main {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 767px) {
  .p-shop_list_ttl_sub {
    font-size: 10px;
    font-size: 1rem;
  }
}

.p-shop_list_txt {
  margin-bottom: 25px;
  min-height: 170px;
}

@media screen and (max-width: 767px) {
  .p-shop_list_txt {
    margin-bottom: 15px;
    font-size: 10px;
    font-size: 1rem;
    min-height: 120px;
  }
}

.p-shop_pager {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

@media print, screen and (min-width: 768px) {
  .p-shop_pager {
    margin-top: -10px;
  }
}

@media screen and (max-width: 767px) {
  .p-shop_pager {
    padding-top: 40px;
  }
}

.p-shop_pager a {
  display: block;
}

.p-shop_pager_prev, .p-shop_pager_next {
  width: 20px;
}

@media screen and (max-width: 767px) {
  .p-shop_pager_prev, .p-shop_pager_next {
    width: 11px;
  }
}

.p-shop_pager_prev {
  margin-right: 50px;
}

@media screen and (max-width: 767px) {
  .p-shop_pager_prev {
    margin-right: 15px;
  }
}

.p-shop_pager_next {
  margin-left: 50px;
}

@media screen and (max-width: 767px) {
  .p-shop_pager_next {
    margin-left: 20px;
  }
}

.p-shop_pager_list {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .p-shop_pager_list {
    padding-top: 10px;
  }
}

.p-shop_pager_list li {
  width: 45px;
  margin: 0 4px;
}

@media screen and (max-width: 767px) {
  .p-shop_pager_list li {
    width: 30px;
    margin: 0 2.5px;
    padding-bottom: 10px;
  }
}

.p-shop_pager_list li:first-child {
  margin: 0 4px 0 0;
}

@media screen and (max-width: 767px) {
  .p-shop_pager_list li:first-child {
    margin: 0 2.5px 0 0;
  }
}

.p-shop_pager_list li:last-child {
  margin: 0 0 0 4px;
}

@media screen and (max-width: 767px) {
  .p-shop_pager_list li:last-child {
    margin: 0 0 0 2.5px;
  }
}

.p-shop_pager_list li span,
.p-shop_pager_list li a {
  display: block;
  line-height: 1;
  width: 45px;
  height: 45px;
  padding: 13px 0 8px;
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.8rem;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .p-shop_pager_list li span,
  .p-shop_pager_list li a {
    width: 30px;
    height: 30px;
    padding: 8px 0 5px;
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.p-shop_pager_list li a {
  background-color: #000;
  color: #fff;
}

.p-shopDetail_kv {
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .p-shopDetail_kv .c-breadcrumb {
    position: relative;
    z-index: 0;
  }
}

.p-shopDetail_kv .c-shop {
  padding: 60px 0 100px;
}

@media screen and (max-width: 767px) {
  .p-shopDetail_kv .c-shop {
    margin-top: -30px;
    padding: 0 0 40px;
  }
}

.p-shopDetail_top {
  padding: 80px 0;
}

@media screen and (max-width: 767px) {
  .p-shopDetail_top {
    padding: 40px 0 70px;
  }
}

.p-shopDetail_top .c-txt_border {
  margin: 0;
  border-top: none;
  border-bottom: none;
}

@media screen and (max-width: 767px) {
  .p-shopDetail_top .c-txt_border {
    margin: 0;
    padding: 0;
    border-top: none;
    border-bottom: none;
  }
}

.p-shopDetail_top .c-txt_border .pcNone {
  display: none;
}

@media screen and (max-width: 767px) {
  .p-shopDetail_top .c-txt_border .pcNone {
    display: block;
  }
}

.p-shopDetail_top .c-txt_border .spNone {
  display: block;
}

@media screen and (max-width: 767px) {
  .p-shopDetail_top .c-txt_border .spNone {
    display: none;
  }
}

.p-shopDetail_top .c-txt_border .uq-SwEmergencyPanel__main,
.p-shopDetail_top .c-txt_border .caution {
  margin: 0 auto 75px;
  padding: 40px 20px;
  text-align: center;
  background-color: #BBC7AE;
}

.p-shopDetail_top .c-txt_border .uq-SwEmergencyPanel__main .uq-SwEmergencyPanel__label,
.p-shopDetail_top .c-txt_border .uq-SwEmergencyPanel__main .caution__title,
.p-shopDetail_top .c-txt_border .caution .uq-SwEmergencyPanel__label,
.p-shopDetail_top .c-txt_border .caution .caution__title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 1.5;
}

.p-shopDetail_top .c-txt_border .c-txt_border_txt {
  margin: 0 auto 75px;
  padding: 15px 0;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
}

@media screen and (max-width: 767px) {
  .p-shopDetail_top .c-txt_border .c-txt_border_txt {
    margin: 0 auto 60px;
    line-height: 1.58;
    padding: 15px 0;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
  }
}

.p-shopDetail_top .c-txt_border .uq-SwEmergencyPanel__sub {
  margin: 0 auto 75px;
  padding: 15px 0;
  text-align: center;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
}

@media screen and (max-width: 767px) {
  .p-shopDetail_top .c-txt_border .uq-SwEmergencyPanel__sub {
    margin: 0 auto 60px;
    line-height: 1.58;
    padding: 15px 0;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
  }
}

.p-shopDetail_top .c-txt_border .uq-SwEmergencyPanel__sub .uq-SwEmergencyPanel__sub__label {
  font-size: 24px;
  font-weight: bold;
}

.p-shopDetail_top .c-txt_border .uq-SwEmergencyPanel__sub .uq-SwEmergencyPanel__sub__body {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
}

.p-shopDetail_top .c-txt_border .uq-SwEmergencyPanel__sub .uq-SwEmergencyPanel__sub__body .largeText {
  font-size: 14px;
  font-weight: normal;
}

.p-shopDetail_info {
  margin-bottom: 80px;
}

@media screen and (max-width: 767px) {
  .p-shopDetail_info {
    margin-bottom: 40px;
  }
}

.p-shopDetail_info li {
  margin-bottom: 40px;
  padding: 35px;
  background-color: #BBC7AE;
}

@media screen and (max-width: 767px) {
  .p-shopDetail_info li {
    margin-bottom: 20px;
    padding: 15px 5%;
  }
}

.p-shopDetail_info li:last-child {
  margin-bottom: 0;
}

.p-shopDetail_info_ttl {
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 24px;
  font-size: 2.4rem;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .p-shopDetail_info_ttl {
    margin-bottom: 8px;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.p-shopDetail_info_center {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .p-shopDetail_info_center {
    line-height: 1.58;
  }
}

@media print, screen and (min-width: 768px) {
  .p-shopDetail_info_left {
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .p-shopDetail_info_left {
    line-height: 1.58;
  }
}

.p-shopDetail_consult_ttl {
  margin-bottom: 15px;
  line-height: 1.58;
  font-weight: 700;
  font-size: 24px;
  font-size: 2.4rem;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .p-shopDetail_consult_ttl {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.p-shopDetail_consult_txt {
  margin-bottom: 35px;
}

@media print, screen and (min-width: 768px) {
  .p-shopDetail_consult_txt {
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .p-shopDetail_consult_txt {
    margin-bottom: 20px;
  }
}

.p-shopDetail_consult_contact {
  text-align: center;
}

.p-shopDetail_consult_contact_txt {
  margin-bottom: 5px;
}

@media screen and (max-width: 767px) {
  .p-shopDetail_consult_contact_txt {
    margin-bottom: 15px;
  }
}

.p-shopDetail_consult_contact_add {
  font-weight: 700;
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 767px) {
  .p-shopDetail_consult_contact_add {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.p-shopDetail_access {
  padding-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .p-shopDetail_access {
    padding-bottom: 50px;
  }
}

.p-shopDetail_access_add {
  margin-bottom: 35px;
  font-size: 16px;
  font-size: 1.6rem;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .p-shopDetail_access_add {
    margin-bottom: 25px;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.p-shopDetail_access_cont {
  margin-bottom: 105px;
}

@media screen and (max-width: 767px) {
  .p-shopDetail_access_cont {
    line-height: 1.58;
    margin-bottom: 40px;
  }
}

.p-shopDetail_access_info {
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .p-shopDetail_access_info {
    margin-bottom: 20px;
  }
}

.p-shopDetail_access_info:last-child {
  margin-bottom: 0;
}

.p-shopDetail_access_map {
  height: 480px;
  margin-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .p-shopDetail_access_map {
    height: 320px;
    margin-bottom: 50px;
  }
}

.p-shopDetail_access_map iframe {
  border: 0;
}

@media screen and (max-width: 767px) {
  .u-only_sp {
    display: block;
  }
}

@media print, screen and (min-width: 768px) {
  .u-only_sp {
    display: none !important;
  }
}

@media print, screen and (min-width: 768px) {
  .u-only_pc {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .u-only_pc {
    display: none !important;
  }
}

.u-fl_r {
  float: right;
}

.u-fl_l {
  float: left;
}

.u-clearfix:after {
  content: "";
  clear: both;
  display: block;
}

.u-ta_l {
  text-align: left !important;
}

.u-ta_c {
  text-align: center !important;
}

.u-ta_r {
  text-align: right !important;
}

.u-va_t {
  vertical-align: top !important;
}

.u-va_m {
  vertical-align: middle !important;
}

.u-va_b {
  vertical-align: bottom !important;
}

.u-mt_100 {
  margin-top: 100px !important;
}

.u-mb_100 {
  margin-bottom: 100px !important;
}

.u-mr_100 {
  margin-right: 100px !important;
}

.u-ml_100 {
  margin-left: 100px !important;
}

.u-mt_95 {
  margin-top: 95px !important;
}

.u-mb_95 {
  margin-bottom: 95px !important;
}

.u-mr_95 {
  margin-right: 95px !important;
}

.u-ml_95 {
  margin-left: 95px !important;
}

.u-mt_90 {
  margin-top: 90px !important;
}

.u-mb_90 {
  margin-bottom: 90px !important;
}

.u-mr_90 {
  margin-right: 90px !important;
}

.u-ml_90 {
  margin-left: 90px !important;
}

.u-mt_85 {
  margin-top: 85px !important;
}

.u-mb_85 {
  margin-bottom: 85px !important;
}

.u-mr_85 {
  margin-right: 85px !important;
}

.u-ml_85 {
  margin-left: 85px !important;
}

.u-mt_80 {
  margin-top: 80px !important;
}

.u-mb_80 {
  margin-bottom: 80px !important;
}

.u-mr_80 {
  margin-right: 80px !important;
}

.u-ml_80 {
  margin-left: 80px !important;
}

.u-mt_75 {
  margin-top: 75px !important;
}

.u-mb_75 {
  margin-bottom: 75px !important;
}

.u-mr_75 {
  margin-right: 75px !important;
}

.u-ml_75 {
  margin-left: 75px !important;
}

.u-mt_70 {
  margin-top: 70px !important;
}

.u-mb_70 {
  margin-bottom: 70px !important;
}

.u-mr_70 {
  margin-right: 70px !important;
}

.u-ml_70 {
  margin-left: 70px !important;
}

.u-mt_65 {
  margin-top: 65px !important;
}

.u-mb_65 {
  margin-bottom: 65px !important;
}

.u-mr_65 {
  margin-right: 65px !important;
}

.u-ml_65 {
  margin-left: 65px !important;
}

.u-mt_60 {
  margin-top: 60px !important;
}

.u-mb_60 {
  margin-bottom: 60px !important;
}

.u-mr_60 {
  margin-right: 60px !important;
}

.u-ml_60 {
  margin-left: 60px !important;
}

.u-mt_55 {
  margin-top: 55px !important;
}

.u-mb_55 {
  margin-bottom: 55px !important;
}

.u-mr_55 {
  margin-right: 55px !important;
}

.u-ml_55 {
  margin-left: 55px !important;
}

.u-mt_50 {
  margin-top: 50px !important;
}

.u-mb_50 {
  margin-bottom: 50px !important;
}

.u-mr_50 {
  margin-right: 50px !important;
}

.u-ml_50 {
  margin-left: 50px !important;
}

.u-mt_45 {
  margin-top: 45px !important;
}

.u-mb_45 {
  margin-bottom: 45px !important;
}

.u-mr_45 {
  margin-right: 45px !important;
}

.u-ml_45 {
  margin-left: 45px !important;
}

.u-mt_40 {
  margin-top: 40px !important;
}

.u-mb_40 {
  margin-bottom: 40px !important;
}

.u-mr_40 {
  margin-right: 40px !important;
}

.u-ml_40 {
  margin-left: 40px !important;
}

.u-mt_35 {
  margin-top: 35px !important;
}

.u-mb_35 {
  margin-bottom: 35px !important;
}

.u-mr_35 {
  margin-right: 35px !important;
}

.u-ml_35 {
  margin-left: 35px !important;
}

.u-mt_30 {
  margin-top: 30px !important;
}

.u-mb_30 {
  margin-bottom: 30px !important;
}

.u-mr_30 {
  margin-right: 30px !important;
}

.u-ml_30 {
  margin-left: 30px !important;
}

.u-mt_25 {
  margin-top: 25px !important;
}

.u-mb_25 {
  margin-bottom: 25px !important;
}

.u-mr_25 {
  margin-right: 25px !important;
}

.u-ml_25 {
  margin-left: 25px !important;
}

.u-mt_20 {
  margin-top: 20px !important;
}

.u-mb_20 {
  margin-bottom: 20px !important;
}

.u-mr_20 {
  margin-right: 20px !important;
}

.u-ml_20 {
  margin-left: 20px !important;
}

.u-mt_15 {
  margin-top: 15px !important;
}

.u-mb_15 {
  margin-bottom: 15px !important;
}

.u-mr_15 {
  margin-right: 15px !important;
}

.u-ml_15 {
  margin-left: 15px !important;
}

.u-mt_10 {
  margin-top: 10px !important;
}

.u-mb_10 {
  margin-bottom: 10px !important;
}

.u-mr_10 {
  margin-right: 10px !important;
}

.u-ml_10 {
  margin-left: 10px !important;
}

.u-mt_5 {
  margin-top: 5px !important;
}

.u-mb_5 {
  margin-bottom: 5px !important;
}

.u-mr_5 {
  margin-right: 5px !important;
}

.u-ml_5 {
  margin-left: 5px !important;
}

.u-mt_0 {
  margin-top: 0px !important;
}

.u-mb_0 {
  margin-bottom: 0px !important;
}

.u-mr_0 {
  margin-right: 0px !important;
}

.u-ml_0 {
  margin-left: 0px !important;
}

.u-pt_100 {
  padding-top: 100px !important;
}

.u-pb_100 {
  padding-bottom: 100px !important;
}

.u-pr_100 {
  padding-right: 100px !important;
}

.u-pl_100 {
  padding-left: 100px !important;
}

.u-p_100 {
  padding: 100px !important;
}

.u-pt_95 {
  padding-top: 95px !important;
}

.u-pb_95 {
  padding-bottom: 95px !important;
}

.u-pr_95 {
  padding-right: 95px !important;
}

.u-pl_95 {
  padding-left: 95px !important;
}

.u-p_95 {
  padding: 95px !important;
}

.u-pt_90 {
  padding-top: 90px !important;
}

.u-pb_90 {
  padding-bottom: 90px !important;
}

.u-pr_90 {
  padding-right: 90px !important;
}

.u-pl_90 {
  padding-left: 90px !important;
}

.u-p_90 {
  padding: 90px !important;
}

.u-pt_85 {
  padding-top: 85px !important;
}

.u-pb_85 {
  padding-bottom: 85px !important;
}

.u-pr_85 {
  padding-right: 85px !important;
}

.u-pl_85 {
  padding-left: 85px !important;
}

.u-p_85 {
  padding: 85px !important;
}

.u-pt_80 {
  padding-top: 80px !important;
}

.u-pb_80 {
  padding-bottom: 80px !important;
}

.u-pr_80 {
  padding-right: 80px !important;
}

.u-pl_80 {
  padding-left: 80px !important;
}

.u-p_80 {
  padding: 80px !important;
}

.u-pt_75 {
  padding-top: 75px !important;
}

.u-pb_75 {
  padding-bottom: 75px !important;
}

.u-pr_75 {
  padding-right: 75px !important;
}

.u-pl_75 {
  padding-left: 75px !important;
}

.u-p_75 {
  padding: 75px !important;
}

.u-pt_70 {
  padding-top: 70px !important;
}

.u-pb_70 {
  padding-bottom: 70px !important;
}

.u-pr_70 {
  padding-right: 70px !important;
}

.u-pl_70 {
  padding-left: 70px !important;
}

.u-p_70 {
  padding: 70px !important;
}

.u-pt_65 {
  padding-top: 65px !important;
}

.u-pb_65 {
  padding-bottom: 65px !important;
}

.u-pr_65 {
  padding-right: 65px !important;
}

.u-pl_65 {
  padding-left: 65px !important;
}

.u-p_65 {
  padding: 65px !important;
}

.u-pt_60 {
  padding-top: 60px !important;
}

.u-pb_60 {
  padding-bottom: 60px !important;
}

.u-pr_60 {
  padding-right: 60px !important;
}

.u-pl_60 {
  padding-left: 60px !important;
}

.u-p_60 {
  padding: 60px !important;
}

.u-pt_55 {
  padding-top: 55px !important;
}

.u-pb_55 {
  padding-bottom: 55px !important;
}

.u-pr_55 {
  padding-right: 55px !important;
}

.u-pl_55 {
  padding-left: 55px !important;
}

.u-p_55 {
  padding: 55px !important;
}

.u-pt_50 {
  padding-top: 50px !important;
}

.u-pb_50 {
  padding-bottom: 50px !important;
}

.u-pr_50 {
  padding-right: 50px !important;
}

.u-pl_50 {
  padding-left: 50px !important;
}

.u-p_50 {
  padding: 50px !important;
}

.u-pt_45 {
  padding-top: 45px !important;
}

.u-pb_45 {
  padding-bottom: 45px !important;
}

.u-pr_45 {
  padding-right: 45px !important;
}

.u-pl_45 {
  padding-left: 45px !important;
}

.u-p_45 {
  padding: 45px !important;
}

.u-pt_40 {
  padding-top: 40px !important;
}

.u-pb_40 {
  padding-bottom: 40px !important;
}

.u-pr_40 {
  padding-right: 40px !important;
}

.u-pl_40 {
  padding-left: 40px !important;
}

.u-p_40 {
  padding: 40px !important;
}

.u-pt_35 {
  padding-top: 35px !important;
}

.u-pb_35 {
  padding-bottom: 35px !important;
}

.u-pr_35 {
  padding-right: 35px !important;
}

.u-pl_35 {
  padding-left: 35px !important;
}

.u-p_35 {
  padding: 35px !important;
}

.u-pt_30 {
  padding-top: 30px !important;
}

.u-pb_30 {
  padding-bottom: 30px !important;
}

.u-pr_30 {
  padding-right: 30px !important;
}

.u-pl_30 {
  padding-left: 30px !important;
}

.u-p_30 {
  padding: 30px !important;
}

.u-pt_25 {
  padding-top: 25px !important;
}

.u-pb_25 {
  padding-bottom: 25px !important;
}

.u-pr_25 {
  padding-right: 25px !important;
}

.u-pl_25 {
  padding-left: 25px !important;
}

.u-p_25 {
  padding: 25px !important;
}

.u-pt_20 {
  padding-top: 20px !important;
}

.u-pb_20 {
  padding-bottom: 20px !important;
}

.u-pr_20 {
  padding-right: 20px !important;
}

.u-pl_20 {
  padding-left: 20px !important;
}

.u-p_20 {
  padding: 20px !important;
}

.u-pt_15 {
  padding-top: 15px !important;
}

.u-pb_15 {
  padding-bottom: 15px !important;
}

.u-pr_15 {
  padding-right: 15px !important;
}

.u-pl_15 {
  padding-left: 15px !important;
}

.u-p_15 {
  padding: 15px !important;
}

.u-pt_10 {
  padding-top: 10px !important;
}

.u-pb_10 {
  padding-bottom: 10px !important;
}

.u-pr_10 {
  padding-right: 10px !important;
}

.u-pl_10 {
  padding-left: 10px !important;
}

.u-p_10 {
  padding: 10px !important;
}

.u-pt_5 {
  padding-top: 5px !important;
}

.u-pb_5 {
  padding-bottom: 5px !important;
}

.u-pr_5 {
  padding-right: 5px !important;
}

.u-pl_5 {
  padding-left: 5px !important;
}

.u-p_5 {
  padding: 5px !important;
}

.u-pt_0 {
  padding-top: 0px !important;
}

.u-pb_0 {
  padding-bottom: 0px !important;
}

.u-pr_0 {
  padding-right: 0px !important;
}

.u-pl_0 {
  padding-left: 0px !important;
}

.u-p_0 {
  padding: 0px !important;
}

@media print, screen and (max-width: 1059px) {
  .u-pt_100--tab {
    padding-top: 100px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pb_100--tab {
    padding-bottom: 100px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pr_100--tab {
    padding-right: 100px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pl_100--tab {
    padding-left: 100px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-p_100--tab {
    padding: 100px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pt_95--tab {
    padding-top: 95px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pb_95--tab {
    padding-bottom: 95px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pr_95--tab {
    padding-right: 95px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pl_95--tab {
    padding-left: 95px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-p_95--tab {
    padding: 95px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pt_90--tab {
    padding-top: 90px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pb_90--tab {
    padding-bottom: 90px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pr_90--tab {
    padding-right: 90px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pl_90--tab {
    padding-left: 90px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-p_90--tab {
    padding: 90px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pt_85--tab {
    padding-top: 85px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pb_85--tab {
    padding-bottom: 85px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pr_85--tab {
    padding-right: 85px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pl_85--tab {
    padding-left: 85px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-p_85--tab {
    padding: 85px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pt_80--tab {
    padding-top: 80px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pb_80--tab {
    padding-bottom: 80px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pr_80--tab {
    padding-right: 80px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pl_80--tab {
    padding-left: 80px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-p_80--tab {
    padding: 80px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pt_75--tab {
    padding-top: 75px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pb_75--tab {
    padding-bottom: 75px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pr_75--tab {
    padding-right: 75px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pl_75--tab {
    padding-left: 75px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-p_75--tab {
    padding: 75px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pt_70--tab {
    padding-top: 70px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pb_70--tab {
    padding-bottom: 70px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pr_70--tab {
    padding-right: 70px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pl_70--tab {
    padding-left: 70px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-p_70--tab {
    padding: 70px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pt_65--tab {
    padding-top: 65px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pb_65--tab {
    padding-bottom: 65px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pr_65--tab {
    padding-right: 65px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pl_65--tab {
    padding-left: 65px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-p_65--tab {
    padding: 65px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pt_60--tab {
    padding-top: 60px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pb_60--tab {
    padding-bottom: 60px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pr_60--tab {
    padding-right: 60px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pl_60--tab {
    padding-left: 60px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-p_60--tab {
    padding: 60px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pt_55--tab {
    padding-top: 55px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pb_55--tab {
    padding-bottom: 55px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pr_55--tab {
    padding-right: 55px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pl_55--tab {
    padding-left: 55px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-p_55--tab {
    padding: 55px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pt_50--tab {
    padding-top: 50px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pb_50--tab {
    padding-bottom: 50px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pr_50--tab {
    padding-right: 50px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pl_50--tab {
    padding-left: 50px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-p_50--tab {
    padding: 50px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pt_45--tab {
    padding-top: 45px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pb_45--tab {
    padding-bottom: 45px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pr_45--tab {
    padding-right: 45px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pl_45--tab {
    padding-left: 45px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-p_45--tab {
    padding: 45px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pt_40--tab {
    padding-top: 40px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pb_40--tab {
    padding-bottom: 40px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pr_40--tab {
    padding-right: 40px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pl_40--tab {
    padding-left: 40px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-p_40--tab {
    padding: 40px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pt_35--tab {
    padding-top: 35px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pb_35--tab {
    padding-bottom: 35px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pr_35--tab {
    padding-right: 35px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pl_35--tab {
    padding-left: 35px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-p_35--tab {
    padding: 35px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pt_30--tab {
    padding-top: 30px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pb_30--tab {
    padding-bottom: 30px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pr_30--tab {
    padding-right: 30px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pl_30--tab {
    padding-left: 30px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-p_30--tab {
    padding: 30px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pt_25--tab {
    padding-top: 25px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pb_25--tab {
    padding-bottom: 25px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pr_25--tab {
    padding-right: 25px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pl_25--tab {
    padding-left: 25px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-p_25--tab {
    padding: 25px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pt_20--tab {
    padding-top: 20px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pb_20--tab {
    padding-bottom: 20px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pr_20--tab {
    padding-right: 20px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pl_20--tab {
    padding-left: 20px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-p_20--tab {
    padding: 20px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pt_15--tab {
    padding-top: 15px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pb_15--tab {
    padding-bottom: 15px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pr_15--tab {
    padding-right: 15px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pl_15--tab {
    padding-left: 15px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-p_15--tab {
    padding: 15px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pt_10--tab {
    padding-top: 10px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pb_10--tab {
    padding-bottom: 10px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pr_10--tab {
    padding-right: 10px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pl_10--tab {
    padding-left: 10px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-p_10--tab {
    padding: 10px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pt_5--tab {
    padding-top: 5px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pb_5--tab {
    padding-bottom: 5px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pr_5--tab {
    padding-right: 5px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pl_5--tab {
    padding-left: 5px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-p_5--tab {
    padding: 5px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pt_0--tab {
    padding-top: 0px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pb_0--tab {
    padding-bottom: 0px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pr_0--tab {
    padding-right: 0px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-pl_0--tab {
    padding-left: 0px !important;
  }
}

@media print, screen and (max-width: 1059px) {
  .u-p_0--tab {
    padding: 0px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt_100--sp {
    padding-top: 100px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb_100--sp {
    padding-bottom: 100px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pr_100--sp {
    padding-right: 100px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pl_100--sp {
    padding-left: 100px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-p_100--sp {
    padding: 100px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt_95--sp {
    padding-top: 95px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb_95--sp {
    padding-bottom: 95px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pr_95--sp {
    padding-right: 95px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pl_95--sp {
    padding-left: 95px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-p_95--sp {
    padding: 95px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt_90--sp {
    padding-top: 90px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb_90--sp {
    padding-bottom: 90px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pr_90--sp {
    padding-right: 90px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pl_90--sp {
    padding-left: 90px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-p_90--sp {
    padding: 90px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt_85--sp {
    padding-top: 85px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb_85--sp {
    padding-bottom: 85px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pr_85--sp {
    padding-right: 85px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pl_85--sp {
    padding-left: 85px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-p_85--sp {
    padding: 85px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt_80--sp {
    padding-top: 80px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb_80--sp {
    padding-bottom: 80px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pr_80--sp {
    padding-right: 80px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pl_80--sp {
    padding-left: 80px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-p_80--sp {
    padding: 80px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt_75--sp {
    padding-top: 75px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb_75--sp {
    padding-bottom: 75px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pr_75--sp {
    padding-right: 75px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pl_75--sp {
    padding-left: 75px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-p_75--sp {
    padding: 75px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt_70--sp {
    padding-top: 70px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb_70--sp {
    padding-bottom: 70px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pr_70--sp {
    padding-right: 70px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pl_70--sp {
    padding-left: 70px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-p_70--sp {
    padding: 70px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt_65--sp {
    padding-top: 65px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb_65--sp {
    padding-bottom: 65px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pr_65--sp {
    padding-right: 65px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pl_65--sp {
    padding-left: 65px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-p_65--sp {
    padding: 65px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt_60--sp {
    padding-top: 60px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb_60--sp {
    padding-bottom: 60px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pr_60--sp {
    padding-right: 60px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pl_60--sp {
    padding-left: 60px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-p_60--sp {
    padding: 60px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt_55--sp {
    padding-top: 55px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb_55--sp {
    padding-bottom: 55px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pr_55--sp {
    padding-right: 55px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pl_55--sp {
    padding-left: 55px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-p_55--sp {
    padding: 55px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt_50--sp {
    padding-top: 50px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb_50--sp {
    padding-bottom: 50px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pr_50--sp {
    padding-right: 50px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pl_50--sp {
    padding-left: 50px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-p_50--sp {
    padding: 50px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt_45--sp {
    padding-top: 45px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb_45--sp {
    padding-bottom: 45px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pr_45--sp {
    padding-right: 45px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pl_45--sp {
    padding-left: 45px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-p_45--sp {
    padding: 45px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt_40--sp {
    padding-top: 40px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb_40--sp {
    padding-bottom: 40px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pr_40--sp {
    padding-right: 40px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pl_40--sp {
    padding-left: 40px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-p_40--sp {
    padding: 40px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt_35--sp {
    padding-top: 35px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb_35--sp {
    padding-bottom: 35px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pr_35--sp {
    padding-right: 35px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pl_35--sp {
    padding-left: 35px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-p_35--sp {
    padding: 35px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt_30--sp {
    padding-top: 30px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb_30--sp {
    padding-bottom: 30px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pr_30--sp {
    padding-right: 30px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pl_30--sp {
    padding-left: 30px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-p_30--sp {
    padding: 30px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt_25--sp {
    padding-top: 25px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb_25--sp {
    padding-bottom: 25px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pr_25--sp {
    padding-right: 25px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pl_25--sp {
    padding-left: 25px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-p_25--sp {
    padding: 25px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt_20--sp {
    padding-top: 20px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb_20--sp {
    padding-bottom: 20px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pr_20--sp {
    padding-right: 20px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pl_20--sp {
    padding-left: 20px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-p_20--sp {
    padding: 20px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt_15--sp {
    padding-top: 15px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb_15--sp {
    padding-bottom: 15px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pr_15--sp {
    padding-right: 15px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pl_15--sp {
    padding-left: 15px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-p_15--sp {
    padding: 15px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt_10--sp {
    padding-top: 10px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb_10--sp {
    padding-bottom: 10px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pr_10--sp {
    padding-right: 10px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pl_10--sp {
    padding-left: 10px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-p_10--sp {
    padding: 10px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt_5--sp {
    padding-top: 5px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb_5--sp {
    padding-bottom: 5px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pr_5--sp {
    padding-right: 5px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pl_5--sp {
    padding-left: 5px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-p_5--sp {
    padding: 5px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt_0--sp {
    padding-top: 0px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb_0--sp {
    padding-bottom: 0px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pr_0--sp {
    padding-right: 0px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pl_0--sp {
    padding-left: 0px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-p_0--sp {
    padding: 0px !important;
  }
}

.u-bdr_t {
  border-top: 1px #707070 solid;
}

.u-bdr_b {
  border-bottom: 1px #707070 solid;
}

.u-bdr_l {
  border-left: 1px #707070 solid;
}

.u-bdr_r {
  border-right: 1px #707070 solid;
}

@media print {
  .l-header {
    position: inherit !important;
  }
  .l-main {
    margin-top: 0 !important;
  }
}
