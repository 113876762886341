.c-styling {
  @media #{$querySp} {
    position: relative;
    z-index: 0;
    padding-bottom: 40px;

    &::after {
      position: absolute;
      z-index: 0;
      bottom: 8px;
      left: 50%;
      width: 80px;
      height: 1px;
      background-color: $clrBase;
      transform: translateX(-50%);
      content: "";
    }
  }

  &_list {
    display: flex;

    @media #{$querySp} {
      z-index: 0;

      .slick-arrow {
        position: absolute;
        z-index: 0;
        bottom: -36px;
        width: 11px;
        height: 10px;
        background-position: left top;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;

        &:hover {
          opacity: .8;
        }

        &.slider {
          &_prev {
            left: 32%;
            background-image: url(../img/ico_arw_prev_blk.svg);
          }
          &_next {
            right: 32%;
            background-image: url(../img/ico_arw_next_blk.svg);
          }
        }
      }
    }

    li {
      width: 25%;
      cursor: pointer;
      @media #{$querySp} {
        width: 50%;
      }

      a {
        display: block;
      }
    }
  }

  &_img {
    margin-bottom: 10px;

    img {
      width: 100%;
    }
  }

  &_detail {
    width: 90%;
    margin: 0 auto;
    line-height: 2;
    font-family: $fontEn;

    &_ttl {
      display: block;
    }
  }

  &_modal {
    display: none;
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%,-50%);
    pointer-events: none;

    &.is-modal {
      display: block;
      pointer-events: all;
    }

    &_bg {
      display: none;
      position: fixed;
      z-index: 1000;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: $clrOak;
    }

    &_box {
      position: relative;
      z-index: 1001;
      width: 960px;
      height: 760px;
      margin: 50px auto 0;

      @media #{$querySp} {
        width: 345px;
        height: 95%;
        margin: 15px auto 0;
      }
    }

    &_btn {
      position: absolute;
      z-index: 10000;
      top: 0;
      right: 0;
      width: 48px;

      @media #{$querySp} {
        top: -15px;
        right: -5%;
      }

      img {
        width: 100%;
      }
    }

    .modal_arw {
      position: absolute;
      z-index: 1;
      top: 50%;
      width: 48px;
      height: 48px;
      background-position: left top;
      background-size: contain;
      background-repeat: no-repeat;
      transform: translateY(-50%);
      cursor: pointer;

      &.arw_prev {
        left: 0;
        background-image: url(../img/ico_arw_prev_bg_blk.svg);
      }

      &.arw_next {
        right: 0;
        background-image: url(../img/ico_arw_next_bg_blk.svg);
      }
    }

    &_area {
      position: relative;
      z-index: 0;
      width: 960px;
      height: 760px;
      margin: 0 auto;
      overflow: hidden;

      @media #{$querySp} {
        width: 345px;
        height: 90%;
      }
    }

    &_slider {
      display: flex;
      position: absolute;
      z-index: 0;
      height: 100%;
    }

    &_cont {
      width: 960px;
      height: 100%;
      overflow-y: scroll;
      transition: all 1s;
      @media #{$querySp} {
        width: 345px;
      }
    }

    &_inner {
      padding: 43px 60px;
      background-color: $clrWht;
      @media #{$querySp} {
        padding: 25px 2.5% 30px;
      }
    }

    &_close {
      cursor: pointer;
    }

    &_block {
      @media #{$queryPc} {
        display: flex;
        margin-bottom: 35px;
      }
      @media #{$querySp} {
        margin: 0 auto 30px;
      }
    }

    &_list {
      width: 480px !important;
      @media #{$querySp} {
        width: 290px !important;
        margin-right: auto;
        margin-bottom: 20px !important;
        margin-left: auto;
      }

      li {
        width: 480px;
        @media #{$querySp} {
          width: 290px;
          overflow: hidden;
        }

        img {
          width: 100%;
        }
      }

      .slick-dots {
        z-index: 0;
        bottom: 20px;
        @media #{$querySp} {
          bottom: 15px;
        }

        li {
          width: 12px !important;
          height: 12px !important;
          border-radius: 50%;
          margin: 0 10px 0 0;
          @media #{$querySp} {
            width: 10px !important;
            height: 10px !important;
            margin: 0 5px 0 0;
          }
          &:last-child {
            margin: 0;
          }

          &::before {
            display: none;
          }

          button {
            display: block;
            width: 12px;
            height: 12px;
            padding: 0;
            border-radius: 50%;
            background-color: $clrWht;
            opacity: .6;
            @media #{$querySp} {
              width: 10px;
              height: 10px;
            }
          }

          &.slick-active {
            button {
              opacity: 1;
            }
          }
        }
      }
    }

    &_main {
      width: 272px;
      margin: 10px 0 0 72px;
      @media #{$querySp} {
        width: 60%;
        margin: 0 auto;
      }
    }

    &_ttl {
      margin-bottom: 35px;
      @include fs(16);
      text-align: center;
      @media #{$querySp} {
        margin-bottom: 20px;
        @include fs(12);
      }
    }

    &_txt {
      @media #{$querySp} {
        width: 90%;
        line-height: 1.58;
        margin: 0 auto;
      }
    }
  }
}
