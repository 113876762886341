.l-header {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 160px;
  padding-top: 60px;
  background-color: $clrOak;
  font-family: $fontEn;
  @media #{$querySp} {
    height: 140px;
    padding-top: 25px;
  }
  &_cont {
    @media #{$queryPc} {
      position: relative;
      z-index: 0;
      height: 100%;
    }
  }
  &_head {
    display: flex;
    @media #{$queryPc} {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
    @media #{$querySp} {
      position: relative;
      z-index: 0;
      flex-direction: column;
    }
    &_logo {
      width: 200px;
      @media #{$queryPc} {
        position: absolute;
        z-index: 0;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
      }
      @media #{$queryPcTab} {
        width: 180px;
      }
      @media #{$querySp} {
        width: 150px;
        margin: 0 auto 10px;
      }
      a {
        img {
          width: 100%;
        }
      }
    }
    &_txt {
      line-height: 1.4;
      @include fs(10);
      @media #{$querySp} {
        line-height: 1.2;
        text-align: center;
        img {
          margin-top: 15px;
        }
      }
    }
  }
  &_menu {
    @media #{$querySp} {
      position: absolute;
      z-index: 1000;
      top: -25px;
      left: -6%;
      width: 48px;
      height: 48px;
      cursor: pointer;
      &_open {
        display: block;
      }
      &_close {
        display: none;
      }
      &.active {
        .l-header {
          &_menu {
            &_open {
              display: none;
            }
            &_close {
              display: block;
            }
          }
        }
      }
    }
  }
}
