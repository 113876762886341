.l-gnav {
  position: absolute;
  z-index: 10000;
  top: 30px;
  right: 0;
  @media #{$queryPc} {
    padding-right: 94px;
  }
  @media #{$querySp} {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100vh;
    padding: 30px 5% 120px;
    background-color: $clrOrg;
    transition: 0.4s ease;
    opacity: 0;
    overflow-y: scroll;

    &.open {
      left: 0;
      opacity: 1;
    }
  }

  a {
    display: block;

    &:hover {
      opacity: 0.8;
    }
  }

  &_head {
    @media #{$querySp} {
      margin-bottom: 45px;

      &_logo {
        width: 120px;
        margin: 0 auto 30px;

        img {
          width: 100%;
        }
      }

      &_sns {
        display: flex;
        align-items: center;
        justify-content: center;

        li {
          width: 20px;
          margin-right: 12px;

          &:last-child {
            margin-right: 0;
          }

          img {
            width: 100%;
          }
        }
      }
    }
  }

  &_sub {
    @media #{$querySp} {
      display: none;
    }
    @media #{$queryPc} {
      display: flex;
      justify-content: flex-end;
      position: relative;
      z-index: 99;
      margin-bottom: 60px;

      &_cont {
        &:first-child {
          margin-right: 35px;
        }

        a {
          display: flex;

          img {
            margin-right: 10px;
          }
        }

        &_ttl {
          font-weight: 700;
          @include fs(12);
        }
      }

      &_shop {
        @media #{$queryPc} {
          position: absolute;
          z-index: 99;
          top: -30px;
          right: -94px;
          width: 64px;
          height: 64px;
          padding-top: 30px;
          background-color: $clrBase;
          transition: 0.1s;

          &:hover {
            background-color: $clrOrg;
          }

          a {
            display: flex;
            align-items: center;

            img {
              width: 20px;
              margin: 0 auto;
              transition: 0.2s;
            }
          }

          &_txt {
            display: none;
          }

          &_ico {
            display: block;
          }
        }
      }
    }
  }

  &_list {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    @media #{$queryPc} {
      display: flex !important;
      justify-content: center;
    }
    @media #{$querySp} {
      position: relative;
      bottom: auto;
      left: auto;
      flex-wrap: wrap;
    }
  }

  &_cont {
    @media #{$queryPc} {
      padding-bottom: 10px;
      &:nth-child(1) {
        padding-right: 15px;
      }
      &:nth-child(2) {
        padding-left: 15px;
      }
    }
    @media #{$querySp} {
      width: 50%;
    }
    &:last-child {
      @media #{$queryPc} {
        margin-right: 0;
      }
      @media #{$querySp} {
        width: 100%;
        margin-top: 35px;
      }
    }
  }

  &_toggle {
    @media #{$queryPc} {
      cursor: pointer;
    }
    @media #{$querySp} {
      margin-bottom: 20px;
    }
    a {
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
      }
    }
    span {
      font-weight: 700;
      @include fs(14);
      @media #{$querySp} {
        font-size: 100%;
      }
    }
  }

  &_inner {
    @media #{$queryPc} {
      position: fixed;
      z-index: 98;
      top: 160px;
      left: 0;
      width: 100%;
      height: 80px;
      opacity: 0;
      // padding-top: 30px;
      background-color: $clrWht;
      transform: scaleY(0);
      transform-origin: top left;
      transition: 0.4s;
      pointer-events: none;

      &.open {
        opacity: 1;
        transform: scaleY(1);
        visibility: visible;
        pointer-events: auto;
      }
    }

    .l-cont_l {
      @media #{$queryPc} {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
      @media #{$querySp} {
        width: 100%;
      }
    }

    &_ttl {
      line-height: 1;
      @include fs(18);
      letter-spacing: 0.02em;
      @media #{$queryPc} {
        padding-top: 30px;
        padding-left: 20px;
      }

      span {
        font-weight: 700;
      }
    }

    &_nav {
      @media #{$queryPc} {
        display: flex;
        align-items: flex-start;
        padding-top: 2px;
      }
    }

    &_cont {
      @media #{$queryPc} {
        line-height: 1;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-right: 30px;
        // margin-right: 30px;
        cursor: pointer;
        &:first-child {
          padding-left: 100px;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }

    &_toggle {
      @media #{$queryPc} {
        padding-bottom: 90px;
      }
    }

    &_category {
      @media #{$querySp} {
        display: none;
      }
      @media #{$queryPc} {
        position: fixed;
        z-index: 98;
        top: 78px;
        left: 0;
        width: 100%;
        height: 60px;
        opacity: 0;
        padding: 20px 0;
        background-color: $clrBase;
        transform: scaleY(0);
        transform-origin: top left;
        transition: 0.4s;
        pointer-events: none;

        a {
          color: $clrWht;
          white-space: nowrap;
        }

        &.inner-open {
          opacity: 1;
          transform: scaleY(1);
          visibility: visible;
          pointer-events: auto;
        }

        .l-cont_l {
          display: flex;
          align-items: flex-start;
        }

        &_ttl {
          display: flex;
          align-items: center;
          width: 19%;
          max-width: 208px;
          line-height: 1;
          padding-left: 20px;
          font-weight: 700;
          @include fs(18);
          letter-spacing: 0.02em;
          @media #{$querySp} {
            display: block;
          }
        }

        &_cont {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 80%;
          max-width: 870px;
        }

        &_head {
          display: flex;
          align-items: center;
          line-height: 1;
          margin-right: 25px;
          @include fs(18);
          letter-spacing: 0.02em;
          @media #{$querySp} {
            display: block;
          }
        }

        &_list {
          display: flex;
          align-items: flex-start;
          // margin-top: 2px;
          margin-right: 30px;
          &:last-child {
            margin-right: 0;
          }

          li {
            margin-right: 20px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  &_sp {
    @media #{$querySp} {
      margin-top: 25px;
      padding-top: 25px;
      border-top: 1px solid $clrBase;

      li {
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }

        a {
          @include fs(14);
          span {
            font-weight: 700;
          }
        }
      }
    }
  }
}
