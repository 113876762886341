.p-shop {
  &_kv {
    &_ttl {
      display: flex;
      justify-content: center;
      @media #{$querySp} {
        padding-top: 15px;
        margin-bottom: 5px;
      }
      img {
        width: 27px;
        margin-right: 20px;
        @media #{$querySp} {
          width: 13px;
          margin-right: 10px;
        }
      }
      &_en {
        font-family: $fontEn;
        font-weight: 700;
        @include fs(36);
        @media #{$querySp} {
          @include fs(18);
        }
      }
    }
    &_cont {
      padding: 15px 0 15px;
      border-top: 2px solid $clrBase;
      border-bottom: 2px solid $clrBase;
      @media #{$querySp} {
        padding: 20px 0;
        border-top: 1px solid $clrBase;
        border-bottom: 1px solid $clrBase;
      }
    }
    &_txt {
      margin-bottom: 15px;
      @media #{$queryPc} {
        text-align: center;
      }
      @media #{$querySp} {
        line-height: 1.58;
        margin-bottom: 20px;
      }
    }
    .c-shop_hcWrap {
      display: flex;
      justify-content: center;
    }
    .c-shop_hc {
      &_icon {
        margin-right: 10px;
      }
      &_cont {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &_top {
    padding: 80px 0;
    @media #{$querySp} {
      padding: 40px 0 80px;
    }
    .c-shop_btn {
      .c-btn_arw {
        @media #{$querySp} {
          width: 70%;
          margin: 0 auto;
          a {
            padding: 13px 0;
          }
        }
      }
    }
  }
  &_area {
    text-align: center;
    @media #{$queryPc} {
      position: relative;
      z-index: 0;
    }
    &_head {
      @media #{$querySp} {
        position: relative;
        z-index: 0;
        padding: 12px 0;
        background-color: $clrBase;
        color: $clrWht;
        font-weight: 700;
        @include fs(12);
        transition: .4s;
        &::before {
          position: absolute;
          z-index: 0;
          top: 40%;
          right: 5%;
          width: 11px;
          height: 12px;
          background-image: url(../img/ico_arw_next_wh.svg);
          background-position: top left;
          background-size: contain;
          background-repeat: no-repeat;
          transform: rotate(90deg);
          content: "";
        }
      }
    }
    &_list {
      background-color: $clrBase;
      color: $clrWht;
      @media #{$queryPc} {
        display: flex;
        justify-content: center;
        height: 80px;
      }
      @media #{$querySp} {
        display: none;
      }
      li {
        &:hover {
          cursor: pointer;
        }
      }
    }
    &_toggle {
      transition: .4s;
      @media #{$queryPc} {
        width: 92px;
        padding: 26px 0;
        &:hover {
          background-color: $clrWht;
          color: $clrBase;
        }
      }
      @media #{$querySp} {
        padding: 10px 0;
      }
      &.is-area {
        background-color: $clrWht;
        color: $clrBase;
      }
    }
    &_pref {
      background-color: $clrWht;
      @media #{$queryPc} {
        position: absolute;
        z-index: 98;
        top: 80px;
        left: 0;
        width: 100%;
        height: 60px;
        transform: scaleY(0);
        transform-origin: top left;
        transition: .4s;
        pointer-events: none;
        &.is-toggle {
          opacity: 1;
          transform: scaleY(1);
          visibility: visible;
          pointer-events: auto;
        }
      }
      @media #{$querySp} {
        display: none;
      }
      &_inner {
        @media #{$queryPc} {
          position: relative;
          z-index: 0;
          max-width: 1120px;
          margin: 0 auto;
        }
        &.tohoku {
          @media #{$queryPc} {
            left: 101px;
          }
          @media #{$queryPcTab} {
            left: 80px;
          }
        }
        &.hokuriku {
          @media #{$queryPc} {
            left: 193px;
          }
          @media #{$queryPcTab} {
            left: 172px;
          }
        }
        &.kanto {
          @media #{$queryPc} {
            left: 193px;
          }
          @media #{$queryPcTab} {
            left: 80px;
          }
        }
        &.shinetsu {
          @media #{$queryPc} {
            left: 468px;
          }
          @media #{$queryPcTab} {
            left: 448px;
          }
        }
        &.tokai {
          @media #{$queryPc} {
            left: 468px;
          }
          @media #{$queryPcTab} {
            left: 448px;
          }
        }
        &.chugoku {
          @media #{$queryPc} {
            left: 468px;
          }
          @media #{$queryPcTab} {
            left: 448px;
          }
        }
        &.kinki {
          @media #{$queryPc} {
            left: 470px;
          }
          @media #{$queryPcTab} {
            left: 448px;
          }
        }
        &.shikoku {
          @media #{$queryPc} {
            left: 650px;
          }
          @media #{$queryPcTab} {
            left: 539px;
          }
        }
        &.kyushu {
          @media #{$queryPc} {
            left: 470px;
          }
          @media #{$queryPcTab} {
            left: 358px;
          }
        }
      }
      &_list {
        @media #{$queryPc} {
          display: flex;
          position: absolute;
          z-index: 0;
          top: 0;
          width: 100%;
        }
        li {
          color: $clrBase;
          padding: 16px 0;
          transition: .4s;
          @media #{$queryPc} {
            width: 92px;
          }
          @media #{$querySp} {
            padding: 10px 0;
            border-top: 1px solid $clrBase;
          }
          &.is-area {
            background-color: $clrLOak;
          }
          &:hover {
            background-color: $clrLOak;
          }
        }
      }
    }
  }
  &_list {
    padding: 100px 0;
    @media #{$querySp} {
      padding: 40px 0 50px;
    }
    &_cont {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 24.2%;
        margin-right: 1%;
        margin-bottom: 80px;
        @media #{$queryPc} {
          &:nth-child(4n) {
            margin-right: 0;
          }
        }
        @media #{$querySp} {
          width: 47.5%;
          margin-right: 5%;
          margin-bottom: 30px;
          &:nth-child(2n) {
            margin-right: 0;
          }
        }
        a {
          display: block;
          position: relative;
          z-index: 0;
          transition: .4s;
          .c-shop_hc {
            position: absolute;
            z-index: 0;
            top: 10px;
            right: 10px;
          }
          .c-shop_hc_icon {
            margin-bottom: 5px;
          }
          &:hover {
            .p-shop_list_img,
            .p-shop_list_inner {
              opacity: .7;
            }
          }
        }
      }
    }
    &_img {
      display: block;
      margin-bottom: 15px;
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 100%;
        padding-top: 73.43%;
        top: 0;
        left: 0;
        right: 0;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
      }
    }
    &_ttl {
      margin-bottom: 20px;
      @media #{$querySp} {
        margin-bottom: 15px;
      }
      span {
        display: block;
        font-family: $fontFamilyEn;
        @media #{$querySp} {
          line-height: 1.58;
        }
      }
      &_main {
        font-weight: 700;
        @include fs(16);
        @media #{$querySp} {
          @include fs(12);
        }
      }
      &_sub {
        @media #{$querySp} {
          @include fs(10);
        }
      }
    }
    &_txt {
      margin-bottom: 25px;
      min-height: 170px;
      @media #{$querySp} {
        margin-bottom: 15px;
        @include fs(10);
        min-height: 120px;
      }
    }
  }
  &_pager {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @media #{$queryPc} {
      margin-top: -10px;
    }
    @media #{$querySp} {
      padding-top: 40px;
    }
    a {
      display: block;
    }
    &_prev,
    &_next {
      width: 20px;
      @media #{$querySp} {
        width: 11px;
      }
    }
    &_prev {
      margin-right: 50px;
      @media #{$querySp} {
        margin-right: 15px;
      }
    }
    &_next {
      margin-left: 50px;
      @media #{$querySp} {
        margin-left: 20px;
      }
    }
    &_list {
      display: flex;
      flex-wrap: wrap;
      @media #{$querySp} {
        padding-top: 10px;
      }
      li {
        width: 45px;
        margin: 0 4px;
        @media #{$querySp} {
          width: 30px;
          margin: 0 2.5px;
          padding-bottom: 10px;
        }
        &:first-child {
          margin: 0 4px 0 0;
          @media #{$querySp} {
            margin: 0 2.5px 0 0;
          }
        }
        &:last-child {
          margin: 0 0 0 4px;
          @media #{$querySp} {
            margin: 0 0 0 2.5px;
          }
        }
        span,
        a {
          display: block;
          line-height: 1;
          width: 45px;
          height: 45px;
          padding: 13px 0 8px;
          font-family: $fontEn;
          font-weight: 700;
          @include fs(18);
          text-align: center;
          @media #{$querySp} {
            width: 30px;
            height: 30px;
            padding: 8px 0 5px;
            @include fs(13);
          }
        }
        a {
          background-color: $clrBase;
          color: $clrWht;
        }
      }
    }
  }
}
