.c-items .c-btn_arw {
  // .series.all-products-series .c-items .c-btn_arw {
  margin-bottom: 25px;
  @media #{$querySp} {
    margin-bottom: 15px;
  }
}
.series.all-products-series .c-items li {
  margin-bottom: 0;
}
.c-items_detail {
  // .series.all-products-series .c-items .c-items_detail {
  height: 160px;
  @media #{$querySp} {
    height: 120px;
  }
}
.all-products-top {
  .l-cont_l {
    padding: 40px 0 0 0;
    @media #{$querySp} {
      padding: 30px 0 0 0;
    }
  }
}
.all-products-bottom {
  padding-top: 120px;
  @media #{$querySp} {
    padding-top: 35px;
  }
}
.all-products-top,
.all-products-bottom {
  background-color: white;
  .l-cont_l {
    text-align: center;
    background-color: white;
    @media #{$querySp} {
      padding: 30px 0 35px 0;
    }
  }
  h2 {
    @include fs(64);
    font-weight: bold;
    font-family: $fontEn;
    line-height: 1;
    @media #{$querySp} {
      @include fs(32);
    }
  }
  #all-products-nav-sp {
    //   position: fixed;
    //   top: 200px;
    //   left: 50%;
    //   transform: translate3d(-50%, 0, 0);
    position: relative;
    margin: 0 auto;
    transition: 0.4s ease;
    z-index: 1;
    width: 90%;
    background-color: white;
    &::after {
      content: "";
      background-image: url(../img/ico_arw_right_wh.svg);
      position: absolute;
      top: 3px;
      right: 20px;
      height: 24.848101265822784px;
      width: 27px;
      transform: rotate(90deg);
      z-index: 1;
    }
    select {
      padding-left: 15px;
      height: 36px;
      width: 100%;
      border: 1px solid black;
      box-sizing: border-box;
      background-color: white;
      outline: none;
      -webkit-appearance: none;
      appearance: none;
      @include fs(12);
      font-family: $fontEn;
      color: black;
      option {
        color: black;
      }
    }
    &.hide {
      // opacity: 0;
      // visibility: hidden;
      // transition: 0.4s ease, 0s visibility 0.4s;
    }
  }
  nav {
    // position: fixed;
    // top: 350px;
    // left: 50%;
    height: 54px;
    border: 1px solid black;
    // transform: translate3d(-50%, 0, 0);
    z-index: 1;
    width: 90%;
    margin: 0 auto;
    background-color: white;
    transition: 0.4s ease, 0s visibility;
    &.hide {
      // opacity: 0;
      // visibility: hidden;
      // transition: 0.4s ease, 0s visibility 0.4s;
    }
    &.flat-nav {
      position: relative;
      opacity: 0;
      pointer-events: none;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      max-width: initial;
      width: calc(100% + 1px);
      background-color: black;
      transition: 0.4s ease 0.1s;
      &.active {
        opacity: 1;
        pointer-events: initial;
      }
      li {
        * {
          color: white;
        }
        margin-right: 0;
        padding-right: 30px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &:nth-child(1) {
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
    @media #{$queryPc} {
      max-width: 1120px;
    }
    @media #{$queryPcTab} {
      width: 95%;
      max-width: 95%;
    }
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      text-align: left;
      @media #{$querySp} {
        display: block;
        // height: 0;
        transition: 0.4s ease;
      }
      li {
        margin-right: 30px;
        @include fs(14);
        font-family: $fontEn;
        cursor: pointer;
        overflow: hidden;
        &#flat-li {
          border-left: 30px solid white;
        }
        &.open-nav {
          margin-right: 0;
          padding-right: 30px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        @media #{$querySp} {
          padding-left: 15px;
          height: 36px;
          line-height: 36px;
          margin-right: 0;
          width: 100%;
          box-sizing: border-box;
          &:last-child {
            margin-bottom: 0;
          }
        }
        &:hover {
          span {
            &::after {
              transform: translate3d(0, 0, 0);
            }
          }
        }
        span {
          position: relative;
          @media #{$querySp} {
            display: inline-block;
            overflow: hidden;
          }
          &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: "";
            height: 1px;
            width: 100%;
            background-color: black;
            transform: translate3d(-100%, 0, 0);
            transition: 0.4s ease;
            @media #{$querySp} {
              display: none;
            }
          }
        }
      }
    }
  }
}
.all-products-bottom {
  position: sticky;
  top: 80px;
  z-index: 10;
  @media #{$querySp} {
    top: 90px;
  }
}
.p-list {
  &_kv {
    position: relative;
    z-index: 0;
    height: 320px;
    background-color: $clrWht;
    @media #{$querySp} {
      height: 310px;
    }

    .l-cont_l {
      position: relative;
      z-index: 0;
      height: 100%;
    }

    &_ttl {
      position: absolute;
      z-index: 0;
      left: 0;
      @media #{$queryPc} {
        bottom: 30px;
      }
      @media #{$querySp} {
        top: 15px;
        left: 5%;
      }

      &_main {
        display: flex;
        align-items: flex-start;
      }

      &_top {
        display: block;
        line-height: 1;
        margin-bottom: 3px;
        font-family: $fontEn;
        font-weight: 700;
        @include fs(96);
        @media #{$querySp} {
          @include fs(48);
        }
      }

      .c-kv_ttl_icon {
        margin: 15px 0 0 30px;
        @media #{$querySp} {
          margin: 5px 0 0 10px;
        }
      }
    }

    &_bg {
      position: absolute;
      z-index: -1;
      top: 0;
      right: 0;
      height: 320px;
      @media #{$querySp} {
        height: 310px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        font-family: "object-fit: contain;";
      }
    }
  }
  &_items {
    padding: 60px 0 20px;
    background-color: $clrWht;
    @media #{$querySp} {
      padding: 35px 0 10px;
    }
  }

  &_link {
    margin-bottom: -45px;
    @media #{$queryPc} {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }
    @media #{$querySp} {
      margin-bottom: -25px;
    }

    li {
      position: relative;
      z-index: 0;
      padding-bottom: 45px;
      @media #{$queryPc} {
        width: 49.9%;
        margin-right: 0.2%;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
      @media #{$querySp} {
        padding-bottom: 25px;
      }

      &.is-page {
        .p-list_link_head {
          &::before {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $clrOak;
            opacity: 0.6;
            content: "";
          }
        }
      }

      a {
        display: block;

        &:hover {
          .c-arw_blk {
            img {
              display: block;
            }
          }
        }
      }
    }

    &_head {
      position: relative;
      z-index: 0;
      height: 240px;
      @media #{$querySp} {
        height: 120px;
      }

      .c-arw_blk {
        position: absolute;
        z-index: 0;
        right: 0;
        bottom: 0;
      }
    }

    &_bg {
      position: absolute;
      z-index: -1;
      top: 0;
      right: 0;
      width: 100%;
      height: 240px;
      @media #{$querySp} {
        height: 120px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 100% 100%;
        font-family: "object-fit: cover; object-position: 100% 100%;";
      }
    }

    &_ttl {
      position: absolute;
      z-index: 0;
      bottom: 10px;
      left: 40px;
      font-family: $fontEn;
      text-align: center;
      @media #{$querySp} {
        bottom: 5px;
        left: 5%;
      }

      span {
        line-height: 1;
        font-weight: 700;
      }

      &_category {
        display: inline-block;
        padding-bottom: 5px;
        @include fs(14);
        border-bottom: 2px solid $clrBase;
        @media #{$querySp} {
          margin-bottom: 5px;
          border-bottom: 1px solid $clrBase;
          @include fs(10);
        }
      }
      &_number {
        display: block;
        @include fs(96);
        @media #{$querySp} {
          @include fs(48);
        }
      }
    }

    &_txt {
      padding: 35px 40px 0;
      @media #{$querySp} {
        line-height: 1.58;
        padding: 15px 5% 0;
      }
    }
  }
}
